import {maxOSInt} from '@/lib/util';
import typeDefs from '@/json/types-list.json';
import {brokerDeductibles, perilsDeductibles, stateIdByCode} from '@/lib/fields/field-constants';
const addlFloodConstTypes = ['Elevated Premises/Structures',
  'Non-Elevated Premises/Structures with No Basement',
'Non-Elevated Premises/Structures with Unfinished Basement - Masonry',
'Non-Elevated Premises/Structures with Unfinished Basement - Concrete',
'Non-Elevated Premises/Structures with Finished Basement - Masonry',
'Non-Elevated Premises/Structures with Finished Basement - Concrete'].map((lbl, i) => ({key: lbl, lbl }));

export const propertyCoverages = [
  { key: 'bizIncPremLimit', type: 'currency', defaultVal: 150000,
    lbl: 'Business Income Coverage Limit', labelTip: 'Optionally add a higher coverage beyond included $150,000',
    range: [150000, maxOSInt], tags: 'col1,impactsRateTier', title: 'Business Income'},
  { key: 'moLmtIndem', type: 'select', lbl: 'Monthly Limit of Indemnity', vals: typeDefs.moLmtIndem, tags: 'col1', optional: true },
  { key: 'bizIncAgreedValue', type: 'yn', lbl: 'Agreed Value', group: 'addlCov', tags: 'col1', defaultVal: true, optional: true },
  { key: 'extPeriodDays', type: 'select', lbl: 'Extended Period (days)',  vals: typeDefs.extPeriodDays, tags: 'col1', optional: true},
  { key: 'bizIncMaxDays', type: 'yn', lbl: 'Maximum Period of Indemnity', tags: 'col1', optional: true},
  {
    key: 'bizPersonalProp', title: 'Additional Coverages', lbl: 'Business Personal Property', classList: 'w-75',
    type: 'currency', tags: 'col2', optional: true, min: 1,
    val: {
      $conditional: true,
      runOnce: true,
      $compute: {
        chain: 'quote.blanketCoverages',
        find: {type: '2'},
        prop: 'limit'
      }
    }
  },
  {
    key: 'bppBlanketId', type: 'data', tags: 'col1',
    val: {
      $conditional: true,
      $compute: {
        chain: 'quote.blanketCoverages',
        find: {type: '2'},
        prop: 'blanketId'
      }
    }
  },
  { key: 'assocByLawsCov', type: 'select', lbl: 'Association By-Laws Coverage',
    tags: 'col2,impactsRateTier', vals: typeDefs.assocByLawsCovTypes, classList: 'w-75',
    active: {
      $conditional: true,
      $and: [
        {
          chain: 'quote.programCode',
          eq: 'COA'
        },
        {
          gl: false
        }
      ],
      keepVal: true
    }
  },
  { key: 'waterDamageDeductible', lbl: 'Water Damage Per Unit Deductible', type: 'select',
    canReset: true, emptyVal: '', tags: 'col2,allowUnlisted', lockable: true, classList: 'w-75',
    vals: {
      $conditional: true,
      $switch: [{
        test: {admin: true},
        result: perilsDeductibles
      }],
      defaultVal: brokerDeductibles
    },
    active: {
      $conditional: true,
      $and: [
        { chain: 'quote.programCode', eq: 'COA' },
        { gl: false }
      ],
      optional: true
    }
  },
  {
    key: 'iceDamming', lbl: 'Ice Damming Per Unit', type: 'select',
    tags: 'col2', defaultVal: 5000, vals: perilsDeductibles, classList: 'w-75',
    readOnly: {
      $conditional: true,
      test: {admin: false}
    },
    active: {
      $conditional: true,
      $and: [
        { chain: 'quote.programCode', eq: 'COA' },
        { gl: false }
      ]
    }
  },
  {
    key: 'iceDammingOSVal', type: 'data'
  },
  {
    key: 'aopPerUnit', lbl: 'AOP Per Unit', type: 'yn',
    tags: 'col2', classList: 'w-75',
    active: {
      optional: true,
      $conditional: true,
      $and: [
        { chain: 'quote.programCode', eq: 'COA' },
        {$or: [
          { chain: 'scope.jurisdiction', eq: stateIdByCode.UT },
          { chain: 'scope.jurisdiction', eq: stateIdByCode.SC }
        ]}
      ]
    }
  },
  { key: 'premierPropertyEnhancement', type: 'data', lbl: 'Premier Property Enhancement' },
  {
    key: 'floodCoverageEnabled', type: 'bool', tags: 'col2', lbl: 'Flood Coverage',
    active: {
      $conditional: true,
      $and: [
        {chain: 'scope.jurisdiction', includedIn: [stateIdByCode.PA, stateIdByCode.VA, stateIdByCode.NJ]},
        {itemVal: 'locations.location-1.scores.floodZone.value', ne: null },
        {chain: 'filteredChildren||scores:chainedVal|floodZone.value:notNull', eq: true },
        {chain: 'filteredChildren||scores:chainedVal|floodZone.value:includedIn|A,V|upper|some', eq: false }
      ],
      keepVal: true
    }
  },
  {key: 'floodAggregateLimit', type: 'data' },
  {
    key: 'floodLimit', lbl: 'Flood Limit', tags: 'allowUnlisted,col2',
    canReset: true,  type: 'select', classList: 'w-75', emptyVal: null,
    vals: [{key: '500000', lbl: '500,000'}, {key: '750000', lbl: '750,000'}, {key: '1000000', lbl: '1,000,000'}],
    lockable: {
      $conditional: true,
      $switch: [{
        $and: [{admin: false}, {chain: 'sibling::floodLimitOverride', nothingSelected: false}],
        result: 'auto'
      }],
      defaultVal: null
    },
    labelTip: {
      $conditional: true,
      $switch: [
        {
          test: {admin: true},
          result: 'Flood Limit will lock automatically for brokers if a flood limit override is added.'
        }
      ],
      defaultVal: null
    },
    active: {
      $conditional: true,
      test: {chain: 'sibling::floodCoverageEnabled', eq: true},
      keepVal: true
    }
  },
  {
    key: 'floodLimitOverride', lbl: 'Flood Limit Override', tags: 'col2', type: 'currency',
    classList: 'w-75', emptyVal: null,
    active: {
      $conditional: true,
      $and: [
        {admin: true},
        {chain: 'sibling::floodCoverageEnabled', eq: true}
      ],
      keepVal: true,
      optional: true
    }
  },
  {
    key: 'floodDeduct', lbl: 'Flood Deductible', type: 'select',  tags: 'col2,allowUnlisted',
    canReset: true, emptyVal: null, classList: 'w-75',
    lockable: {
      $conditional: true,
      $switch: [{
        $and: [{admin: false}, {chain: 'sibling::floodUWDeduct', nothingSelected: false}],
        result: 'auto'
      }],
      defaultVal: null
    },
    labelTip: {
      $conditional: true,
      $switch: [{test: {admin: true}, result: 'Flood Deductible will lock automatically for brokers if a deductible override is selected.'}],
      defaultVal: null
    },
    vals: {
      $conditional: true,
      $switch: [{
        test: {chain: 'sibling::floodLimit', eq: '500000', string: true},
        result: [{key: '25000', lbl: '25,000'}]
      }, {
        test: {chain: 'sibling::floodLimit', eq: '1000000', string: true},
        result: [{key: '50000', lbl: '50,000'}]
      }],
      defaultVal: [{key: '25000', lbl: '25,000'}, {key: '50000', lbl: '50,000'}]
    },
    defaultVal: {
      $conditional: true,
      $switch: [{
        test: {chain: 'sibling::floodLimit', eq: '500000', string: true},
        result: '25000'
      }, {
        test: {chain: 'sibling::floodLimit', eq: '750000', string: true},
        result: '25000'
      }, {
        test: {chain: 'sibling::floodLimit', eq: '1000000', string: true},
        result: '50000'
      }],
      defaultVal: null
    },
    active: {
      $conditional: true,
      keepVal: true,
      test: {chain: 'sibling::floodCoverageEnabled', eq: true}
    }
  },
  {
    key: 'floodUWDeduct', lbl: 'Flood Deductible Override', type: 'select', tags: 'col2', canReset: true,
    classList: 'w-75',
    vals: [...perilsDeductibles],
    active: {
      $conditional: true,
      $and: [
        {chain: 'sibling::floodCoverageEnabled', eq: true},
        {admin: true}
      ],
      keepVal: true,
      optional: true
    }

  },
  {
    key: 'floodConstructionType', type: 'select', tags: 'hasExternalSource,col2',
    vals: addlFloodConstTypes, lbl: 'Addl Construction Type', classList: 'w-75',
    active: {
      $conditional: true,
      test: {chain: 'sibling::floodCoverageEnabled', eq: true},
      keepVal: true
    }
  },
  {
    key: 'floodFloorLevel', type: 'text', tags: 'hasExternalSource,col2',
    hide: true, optional: true,
    _val: {
      $conditional: true,
      $switch: [{
        test: {chain: 'sibling::floodConstructionType', eq: null},
        result: null
      }, {
        test: {chain: 'sibling::floodConstructionType', has: 'shed Basement'},
        result: 'Basement'
      }],
      defaultVal: 1
    }
  }
];
