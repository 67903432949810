<template>
  <div class="quote-bind" v-if="isMounted && !cantBind">
    <div class="d-flex">
      <div class=" flex-fill"></div>
      <div class="quote-panel p-4" style="width:1111px">
        <div class="d-flex">
          <div style="width:700px">
            <div class="d-flex pl-3" style="flex-wrap: wrap; width: 500px">
              <div class="d-flex quote-bind-header">
                <div class="quote-bind-header-propertyName">{{ cleanText(itemVal('customer.accountName')) }}</div>
                <div class="quote-bind-header-details">
                  <div class="quote-bind-header-details-estimate">
                    <div class="quote-bind-header-details-estimate-bubble price-bubble d-flex" v-if="isType.snum(itemVal('quote.totalPremium'))">
                      <div class="bg-gray text-center">
                        <strong>ESTIMATED<br>PREMIUM</strong>
                      </div>
                      <div class="flex-fill">
                        <h2 class="text-center">
                          <sup><small>$</small></sup>{{ currency(itemVal('quote.totalPremium')).replace('$', '').split('.')[0] }}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="quote-bind-header-details-info">
                    <div>
                      <span class="quote-bind-header-details-info-key">Quote # </span>{{ itemVal('quote.quoteNumber') }}
                    </div>
                    <div>
                      <span class="quote-bind-header-details-info-key">Policy Effective Date: </span>{{ mmddyyyy(itemVal('policy.effective')) }}
                    </div>
                  </div>
                </div>
                <div v-if="this.quoteData.baseline.terrorism" class="quote-bind-header-details-includes">
                  <h5 class="quote-bind-header-details-includes-heading">Includes</h5>
                  <ul class="quote-bind-header-details-includes-list">
                    <li class="quote-bind-header-details-includes-list-item">Terrorism Premium: {{ ttl('terrorismPremium') }}</li>
                  </ul>
                </div>
              </div>
              <div class="quote-bind-download-button">
                <DownloadProposalButton class="quote-step-button btn-primary"/>
              </div>
              <div :style="{background:lioTheme.lightGray,border:`solid 1px ${lioTheme.gray}`}" class="d-flex ml-2 w-100">
                <div class="w-100 p-3 ">
                  <div style="" class="p-3 mb-4" v-if="loadingDocuments">
                    <progress-bar>Loading Required Documents List</progress-bar>
                  </div>
                  <div v-else>
                    <h3 v-if="noPreBindDocs" style="text-transform: none; font-style: italic">No documents are required.</h3>
                    <div v-else>
                      <h3 style="text-transform: none">Please upload these required documents:</h3>
                      <a v-for="({lbl,key}) in preBindDocList"
                          :key="lbl" class="text-left d-flex" v-b-modal.bindQuoteAttachments @mousedown="uploadModal.type = key;uploadModal.typeLabel = lbl;">
                        <span class="pt-2">
                          <i v-if="uploadedTypes[key]" class="text-center fas fa-check-circle pt-1" :style="{color:lioTheme.success}" style="font-size:17px;"></i>
                          <i v-else class="text-center fas fa-times-circle" :style="{color:lioTheme.danger}" style="font-size:17px;"></i>
                        </span>
                        <span class="flex-fill m-2 ml-1 mr-3">{{ lbl }}</span>
                        <span>
                          <b-button size="sm" variant="primary">Upload</b-button>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-fill"></div>
            </div>
            <div v-if="!loadingDocuments && !noPreBindDocs">
            <h3 class="mt-4 ml-3 pl-2">Document List</h3>
              <ul v-if="Array.isArray(fileDefs) && fileDefs.length" class="w-100 mt-3 mr-5 mb-3 pl-0" style="list-style: none">
              <li class="d-flex pl-0">
                <div style="width:16px" class="mr-2"></div>
                <small style="width:30%"><strong>TYPE</strong></small>
                <small style="width:35%"><strong>NAME</strong></small>
                <small style="width:30%"><strong>DESCRIPTION</strong></small>
                <a class="mr-5 invis">
                  <i class="fas fa-times-circle"></i>
                </a>
              </li>
              <li class="d-flex mb-1 pb-1 pt-1" v-for="(file,i) in fileDefs" :key="`file-${i}`">
                <div class="mr-2" style="width:16px"></div>
                <div class="mr-2" style="width:30%">{{ docType(file.dataTree.contentType) }}</div>
                <a class="mr-2" style="width:35%" @click="downloadFile(file)"
                >{{ file.name }}.{{ file.ext }}</a>
                <div class="mr-2" style="width:30%">{{ file.description || '' }}</div>
                <a class="mr-5" @click="confirmDelete(file)">
                  <i class="fas fa-times-circle"></i>
                </a>
              </li>
            </ul>
            <div class="pl-3 ml-2 mb-3" v-else><em>No documents uploaded</em></div>
            </div>
            <div class="mt-5 ml-3 pl-2">
              <h3 class="field-title">Billing Address</h3>
              <field-column :defs="storeFields('bindQuote').filter(f=>f.hasTag('payer'))" :hide-labels="true"/>
              <!-- <field-column :defs="storeFields('bindQuote').filter(f=>f.hasTag('contact'))" label-w="30%" field-w="45%" class="bindQuote-contactFields"/> -->
              <h3 class="field-title">Bill Plan</h3>
              <field :def="itemFromChain('bindQuote.pmtOption')"/>
            </div>
          </div>
          <div style="width:420px;max-height:inherit;overflow: hidden">
            <div class="d-flex flex-column h-100 mh-100">
              <h3 class="text-center">Declaration</h3>
              <div class="p-4 declaration">
                <p><strong>{{ fraudState.title }}</strong></p>
                <p v-html="fraudState.message"/>
              </div>
              <field-column :defs="[itemFromChain('bindQuote.electronicAgreement')]" label-w="80%" field-w="20%"/>
              <field-column :defs="[itemFromChain('bindQuote.fraudStatement')]" label-w="80%" field-w="20%"/>
              <field-column :defs="[itemFromChain('bindQuote.printedName')]" label-w="30%" field-w="70%"/>
            </div>
          </div>
        </div>
      </div>
      <b-modal
          :title="`Upload ${uploadModal.typeLabel}`" id="bindQuoteAttachments"
          size="lg" v-model="uploadModal.show"
          :cancel-disabled="true" :no-close-on-backdrop="true">
        <progress-bar class="m-4 mb-5" v-if="uploadModal.uploading">Uploading Documents</progress-bar>
        <div v-else>
          <input type="file" @input="fileUpload" multiple class="file-input" id="fileUploader"/>
          <div v-if="uploadedBatch.length">
            <div class="d-flex">
              <div class="mr-2" style="width:32%">
                <strong>Name</strong>
              </div>
              <div class="w-50">
                <strong>Description</strong>
              </div>
              <a class="mr-1 invis">
                <i class="fas fa-times-circle"></i>
              </a>
            </div>
            <div class="d-flex" v-for="(f,i) in uploadedBatch" :key="`fileDef${i}`">
              <div class="mr-2" style="width:33%">
                <field :def="f.nameField" class="input-row"/>
              </div>
              <div style="width:67%">
                <field :def="f.descField" class="input-row"/>
              </div>
            </div>
          </div>
          <div class="drop-zone" @drop="fileDrop" @dragover="fileDrop" v-else>
            <h1 class="text-center mt-3"><i class="fas fa-upload"></i></h1>
            <p class="text-center">Drag + drop your {{ uploadModal.typeLabel }} document(s) here.<br>
              or <label for="fileUploader" class=""><a>click here</a></label> to add manually
            </p>
          </div>
        </div>
        <template #modal-footer>
          <div class="w-100 text-center">
            <b-button
                size="lg" variant="info" class="mr-4"
                @click="cancelUpload()"
            >CANCEL</b-button>
            <b-button
                :disabled="!uploadedBatch.length"
                variant="primary"
                size="lg" style="width:120px;"
                @click="sendToOneShield()"
            >UPLOAD
            </b-button>
          </div>
        </template>
      </b-modal>
      <div class=" flex-fill"></div>
    </div>
    <div class="text-center mt-5">
      <b-button size="lg" class="quote-nav-button btn-secondary mr-4" variant="info" @click="confirmGoBack">Go back and make changes</b-button>
      <span class="bindButton-container">
        <b-button size="lg" class="quote-nav-button" variant="primary" :disabled="!validFormState" @click="bindPolicy" active>Bind and generate policy</b-button>
        <div class="bindButton-tooltip" v-if="awaitingReinsuranceCert">This quote is awaiting Underwriting review due to the total insurable value</div>
      </span>
    </div>
    <div class="exit-link">
      <a @click="$parent.exit">Exit to the main menu</a>
    </div>
  </div>
</template>

<script>
import {quotemix} from '@/lib/quotemix';
import DownloadProposalButton from '@/components/util/download-proposal-button';
import {UploadedFileDef} from '@/lib/fields/field-typedefs';
import {mapMutations} from 'vuex';
import ProgressBar from '@/components/util/progress-bar';
import {guid, removeObjNulls} from '@/lib/util';
import eventbus from '@/lib/eventbus';
import {simpleModal} from '@/lib/modal-service';
import Field from '@/components/fields/field';
import FieldColumn from '@/components/fields/field-column';
import getFraudStateBy from '@/lib/fraud-state';

export default {
  components: {DownloadProposalButton, FieldColumn, Field, ProgressBar},
  data: () => {
    return {
      uploadModal: {
        show: false,
        uploading: false,
        type: null,
        typeLabel: ''
      },
      loadingDocuments: false,
      curFile: null,
      layout: {
        uploadType: {size: 'lg', width: '100%'}
      },
      uploadedBatch: [],
      fraudState: {}
    };
  },
  computed: {
    cantBind(){
      return this.itemVal('quote.status') === 'Referred To Underwriting';
    },
    noPreBindDocs(){
      return this.itemFromChain('bindQuote.uploadType').vals.length === 0;
    },
    bindQuoteUploadTypes(){
      return this.itemFromChain('bindQuote.uploadType').vals;
    },
    preBindDocList(){
      const reinsuranceCertificateDocId = '38956';
      let preBindDocs = this.bindQuoteUploadTypes;
      return this.isAdmin === true ? preBindDocs : preBindDocs.filter(f => f.key !== reinsuranceCertificateDocId);
    },
    awaitingReinsuranceCert(){
      let isReinsuranceCertRequired = this.bindQuoteUploadTypes.some(item => item.key === '38956');
      if (!isReinsuranceCertRequired) {
        return false;
      }
      let isReinsuranceCertUploaded = this.uploadedTypes['38956'] === true;
      return isReinsuranceCertUploaded ? false : true;
    },
    reqFields() {
      return this.storeFields('bindQuote').filter(def => {
        return def.active && def.isRequired &&
          // ['pmtOption', 'printedName', 'payerList', 'billingEmail', 'billingAddress'].includes(def.key);
          ['pmtOption', 'printedName', 'payerList', 'billingAddress'].includes(def.key);
      });
    },
    totals() {
      return this.quoteData.quote?.totals;
    },
    ttl() {
      return (key, sign = true) => {
        let ttl = this.totals[key];
        if (this.isType.nullOrUndef(ttl)) {
          ttl = 0;
        }
        else if (this.isType.snum(ttl)){
          ttl = Number(ttl);
        }
        return `${sign ? '$ ' : ''}${this.currency(ttl, 0, false)}`;
      };
    },
    validFormState() {
      const checkedBoxes = ['electronicAgreement', 'fraudStatement'].every(key => this.itemVal(`bindQuote.${key}`));
      let validatedFields = this.reqFields.every(f => f.isValid);
      return validatedFields && checkedBoxes && (this.noPrebindDocs || this.hasUploadedTypes);
    },
    uploaded() {
      return this.itemVal('bindQuote.files');
    },
    fileDefs() {
      return this.storeFields('bindQuote').filter(def => def.type === 'file' && !def.deleted && def.dataTree.fileName);
    },
    uploadedTypes() {
      let vals = this.itemFromChain('bindQuote.uploadType').vals;
      let types = Object.fromEntries(vals.map(v => [v.key, false]));
      this.fileDefs.forEach(d => {
        types[d.dataTree.contentType] = true;
      });
      return types;
    },
    hasUploadedTypes() {
      return Object.values(this.uploadedTypes).filter(bool => !bool).length === 0;
    },
    docType() {
      return enumId => {
        let types = this.itemFromChain('bindQuote.uploadType').vals;
        let type = types.find(t => t.key == enumId);
        if (type) {
          return type.lbl;
        }
        return enumId;
      };
    },
    hasPropertyManager() {
      return this.itemVal('customer.pmExists');
    }
  },
  methods: {
    ...mapMutations('getQuote', ['addFieldDefs', 'removeDef']),
    setFraudState() {
      this.fraudState = getFraudStateBy(this.jurisdictionId());
    },
    jurisdictionId() {
      return this.itemVal('scope.jurisdiction');
    },
    updateBillingAddress(policyId, quoteId) {
      let differentPayer = this.itemVal('bindQuote.payerList') !== 'namedInsured';
      if (differentPayer) {
        const mainAddress = this.itemVal('customer.address');
        mainAddress.id = mainAddress.addressId;
        const billingAddress = this.itemVal('bindQuote.billingAddress');
        mainAddress.isPrimary = true;
        mainAddress.isBilling = false;
        billingAddress.isBilling = true;
        billingAddress.id = this.itemVal('quote.emptyAddressId');
        const {customerId} = this.quoteData.quote;
        let params = {policyId, quoteId, mainAddress, billingAddress, customerId};
        let options = {progress: ['Saving Quote', 'Updating Billing Address']};
        return this.oneShield('updateCustomerAddress', params, options).then(({response}) => {
          if (!response.hasErrors) {
            const status = response.fields.find(f => f.key === 'status')?.val;
            this.updateField({chain: 'quote.status', val: status});
          }
        });
      }
      return Promise.resolve(true);
    },
    bindPolicy() {
      let {policyId, quoteId} = this.quoteData.quote;
      let paymentId = this.itemVal('bindQuote.pmtOption');
      const canBind = () => {
        if (this.quoteData.quote.status === 'Pending Bind' ||
            (this.quoteData.quote.status === 'Rated' && this.noPreBindDocs)){
          return true;
        }
        return false;
      };
      const bind = () => {
        let progress = ['Binding Policy', 'Please wait while the policy is bound and generated.'];
        let params = {
          quoteId, policyId, paymentId,
          name: this.oktaUser.name,
          payerId: this.itemVal('payerList')
        };
        let options = {progress, log: true};
        this.oneShield('bindPolicy', params, options).then(({response}) => {
          if (!response.hasErrors) {
            const status = response.fields.find(f => f.key === 'status')?.val;
            const policyNumber = response.fields.find(f => f.key === 'policyNumber')?.val;
            this.updateField({chain: 'quote.policyNumber', val: policyNumber});
            this.updateField({chain: 'quote.status', val: status});
            this.$router.push(this.$parent.nextStepRoute);
          }
        });
      };
      const rate = () => {
        this.rateQuote().then(() => {
          this.updateField({chain: 'bindQuote.pmtOption', val: paymentId});
          if (canBind()) {
            bind();
          } else {
            eventbus.$emit('oneShieldError', {hasErrors: true, requestPayload: {operation: 'rateQuote'}, errorId: 'cantbindquote'});
          }
        });
      };
      this.updateBillingAddress(policyId, quoteId).then(() => {
        if (canBind()) {
          bind();
        } else {
          rate();
        }
      });
    },
    handleFiles(files) {
//let startIndex = this.fileDefs.length;
      let contentType = this.uploadModal.type;
      let fields = files.map(file => {
        return UploadedFileDef.create({
          quoteId: this.quoteData.quote.quoteId,
          file, contentType,
          chain: `bindQuote.file-${guid()}`
        });
      });
      this.addFieldDefs({page: 'bindQuote', fields});
      this.uploadedBatch.push(...fields);
    },
    confirmDelete(fileDef) {
      let msg = `Do you want to remove ${fileDef.name} from this list?`;
      simpleModal(`Remove File`, msg).then(ok => {
        if (ok) {
          this.deleteDocument(fileDef);
        }
      });
    },
    confirmGoBack() {
      let msg = 'Making additional changes will invalidate any previously downloaded proposals. Are you sure you want to go back?';
      simpleModal(`Are you sure?`, msg).then(ok => {
        if (ok) {
          this.$router.push('details');
        }
      });
    },
    fileUpload(e) {
      this.handleFiles(Array.from(e.target.files));
    },
    fileDrop(e) {
      e.preventDefault();
      if (e.type === 'drop' && e.dataTransfer.files.length) {
        this.handleFiles(Array.from(e.dataTransfer.files));
      }
    },
    initPM() {
      let hasPM = this.itemVal('customer.hasPropertyManager');
      if (!hasPM) {
        let props = {
          vals: [{key: 'customer', lbl: 'Insured Customer'}],
          val: 'customer'
        };
        this.updateField({chain: 'bindQuote.payer', props});
      }
    },
    cancelUpload() {
      this.uploadModal.show = false;
      this.uploadedBatch.forEach(def => {
        def.deleted = true;
        this.removeDef(def.chain);
      });
      this.uploadedBatch.splice(0, this.uploadedBatch.length);
      this.uploadedBatch = [];
    },
    sendToOneShield() {
      this.uploadModal.uploading = true;
      let batch = this.uploadedBatch.reverse();
      const uploadNext = () => {
        if (batch.length) {
          let file = batch.pop();
          this.oneShield('uploadDocument', file.uploadParams).then(result => {
            let f = result.response.fields[0].val;
            if (Array.isArray(f) && f.length > 1) {
// OS response contains nested 'documentContent' nodes so we get a bonus one with no data, ignore it
              f = f.find(f => f.documentId);
              if (!f) {
                console.error('Upload document response does not contain a document Id');
              }
            }
            f = removeObjNulls(f);
            file.treeVals = f;
            uploadNext();
          });
        } else {
          this.uploadedBatch.splice(0, this.uploadedBatch.length);
          this.uploadModal.show = this.uploadModal.uploading = false;
        }
      };
      uploadNext();
    },
    deleteDocument(def) {
      let quoteId = this.quoteData.quote.quoteId;
      let documentId = def.dataTree.documentId;
      this.oneShield('deleteDocument', {quoteId, documentId}).then(result => {
        console.log({deleteDocument: result});
      });
      this.removeDef(def.chain);
    },
    downloadFile(fileDef) {
      let params = {
        quoteId: this.quoteData.quote.quoteId,
        documentId: fileDef.dataTree.documentId
      };
      let opts = {toast: `Downloading ${fileDef.name}`};
      this.oneShield('getDocument', params, opts).then(({response: {fields}}) => {
        let data = fields[0].val[0];
        fileDef.download(data.fileData);
      });
    }
  },
  mounted() {
    if (this.cantBind){
      this.$router.push('details');
      return;
    }
    this.setFraudState();

    let quoteId = this.quoteData.quote.quoteId;
    let props;
    this.loadingDocuments = true;
    this.oneShield('getPreBindDocuments', {quoteId}).then((result) => {
      let vals = result.response.fields[0].val;
      if (!Array.isArray(vals)) {
        vals = [vals];
      }
      props = {val: vals[0]?.key, vals};
      this.updateField({chain: 'bindQuote.uploadType', props});
    }).finally(() => {
      if (!this.isMounted || !quoteId){
        console.log('component no longer mounted (bind quote)');
        return;
      }
      let status = (this.itemVal('quote.status') ?? '').toLowerCase();
      if (status.includes('underwriting')){
        this.$router.push('details');
        return;
      }
      this.oneShield('getDocumentList', {quoteId}).then(({response}) => {
        console.log({docList: response.fields});

        let list = response.fields[0].val;
        if (!Array.isArray(list)) {
          list = [list];
        }
        if (this.fileDefs.length && list.length) {
          this.fileDefs.forEach(f => {
            console.log(`replacing redundant cached doc: ${f.chain}`);
            this.removeDef(f.chain);
          });
          console.log('replaced cache with updated document list', list);
        }
        let fields = list.filter(d => d.contentType && d.contentType.length && this.docType(d.contentType) !== d.contentType)
            .map((f, i) => {
              return UploadedFileDef.create({...f, chain: `bindQuote.file-${i}`});
            });
        this.addFieldDefs({page: 'bindQuote', fields});
      }).finally(() => this.loadingDocuments = false);
    });
  },
  mixins: [quotemix],
  name: 'quote-bind-quote',
  watch: {
    cantBind(inUW){
      if (inUW){
        this.$router.push('details');
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/get-quote";

.quote-bind {

  .declaration {
    background: $light;
    border: solid 1px #666;
    border-radius: 5px;
    max-height: 500px;
    overflow: auto;
    margin-bottom: 16px;
  }

  &-header {
    display: flex;
    flex-direction: column;

    &-propertyName {
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 5px;
    }

    &-details {
      display: flex;

      &-estimate {
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin-right: 30px;

        &-bubble {
          width: auto;
          height: 48px;
          border: solid 1px $gray;
          border-radius: 2px;
          background-color: white;

          .bg-gray {
            border-right: solid 1px #1c2d40;
            background-color: #1c2d40;
            border-bottom-left-radius: 2px;
            border-top-left-radius: 2px;
            height: 48px;
            padding: 10px;
            margin-top: -1px;

            strong {
              color: white;
              font-size: .7rem;
              line-height: 1.4;
            }
          }

          strong {
            display: block;
            line-height: 1.1;
            font-size: 16px;
          }

          h2 {
            margin: 10px 8px;
            white-space: nowrap;
            min-width: 105px;
          }
        }
      }

      &-info {
        padding-top: 5px;
        font-size: .8rem;
        color: black;

        &-key {
          color: gray;
        }
      }

      &-includes {
        margin-top: 20px;
        font-size: .9rem;

        &-heading {
          font-size: inherit;
          font-weight: 600;
          margin-bottom: 0;
        }

        &-list {
          padding-left: 20px;
          margin: 0;
        }
      }
    }
  }

  &-download-button {
    width: 100%;

    .btn-lg {
      border-radius: 0.5rem;
    }
  }


  .lbls {
    width: 33%;
    text-align: right;
    font-weight: 900;
  }

  .vals {
    width: 67%
  }

  h3.field-title {
    margin: 16px 0 8px;
  }

  .named-insured {
    margin: 10px 0;
  }
  .named-insured-checkbox {
    label {
      padding-top: 2px;
    }
  }
  .bindButton-container {
    position: relative;

    .bindButton-tooltip {
      display: none;
      position: absolute;
      top: -88px;
      left: 50%;
      transform: translateX(-50%);
      background-color: black;
      color: white;
      width: 210px;
      font-size: 13px;
      padding: 5px 15px;
      border-radius: 4px;

      &:after {
        position: absolute;
        top: 63px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 10px;
        height: 10px;
        background-color: black;
        content: '';
      }
    }

    &:hover {

      .bindButton-tooltip {
        display: block;
      }
    }
  }
}
.bindQuote-contactFields {
  width: 75% !important;
  margin-top: 25px;
}
#bindQuoteAttachments {
  #fileUploader {
    position: absolute;
    left: -999px;
  }

  .drop-zone {
    box-shadow: inset 1px 0 1px white;

    background-color: $light;
    border: 1px dashed $gray;

    a {
      text-decoration: underline;
    }
  }

  .custom-file {
    width: 148px;

    label.custom-file-label {
      border: 0;
    }

    .custom-file-input:lang(en) ~ .custom-file-label::after {
      content: 'UPLOAD';
      position: absolute;
      border-radius: 6px;
      background-color: $primary;
      color: $navy;
      width: 150px;
      left: 0;
      text-align: center;
    }
  }
}
</style>
