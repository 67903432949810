import {isType} from '@/lib/mytype';
import {camelCased, clone, dashCased, getChainedVal, guid, maxOSInt, sortByKey} from '@/lib/util';
import {liabilityClasses, liabilityDetails} from '@/lib/fields/field-constants';
import {formatter} from '@/lib/mix';
import {exposuresMultiselect, locationFields, miscOutdoorProp, outdoorPropTypeList} from '@/lib/fields/location-data';
import {buildingFormFields} from '@/lib/fields/building-data';

const typeDefs = require('../../json/types-list.json');
export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
//this is a work in progress - ron using this and constructor block to build out and expand semantic json
//dox. Please do not delete this

/*
export let allKeys = {
  type: 'bool=checkbox, yn=Yes/No dropdown, select=dropdown, currency/int=numeric input, date=datepicker, text=textbox, geocode=address, data=internal (not rendered) val',
  key: 'field identifier - last part of the chain',
  page: 'containing page. e.g., policy, exposures, customer',
  chain: 'page.key or page.parent.key',
  optional: 'Default=false. When set to true, field is no longer required',
  lbl: 'the label to display for a given field',
  title: 'used as a header for groups of fields',
  i: 'index of the field within its parent page',
  group: 'used to group fields within a page into sub-groups via list.filter(f=>f.group===groupName)',
  classList: 'comma-separated list of css classnames to render with the field',
  tags: 'provides additional filtration functionality',
  types: {},
  byAttr: {}
};
*/

const setKey = (key, inst) => {
  inst.key = key;
  let chain = inst.chain.split('.');
  chain[chain.length - 1] = key;
  inst.chain = chain.join('.');
  if (inst.children){
    inst.children.forEach(child => {
      child.chain = `${inst.chain}.${child.key}`;
    });
  }
};

isType.conditional = v => isType.object(v) && v['$conditional'];
isType.field = v => !isType.primitive(v) && v['isField'] === true;
isType.entity = v => isType.field(v) && v['isEntity'] === true;

class ConditionalProp {
  constructor(defInst, {
    test = {}, prop = 'active', whenTrue = true, whenFalse = false,
    defaultVal = null, keepVal, optional, $switch, $and, $or, result,
    runOnce = false, $compute, skipHeadless
  }) {
    /*if (prop==='readOnly'){
      debugger
      console.log({roSwitch:$switch});
    }*/
    this.def = defInst;
    this.result = result;
    this.$switch = $switch;
    this.$and = $and;
    this.$or = $or;
    this.$compute = $compute;
    this.test = $and ? {$and} : $or ? {$or} : test;
    this.prop = prop;
    this.whenTrue = whenTrue;
    this.whenFalse = whenFalse;
    this.keepVal = keepVal;
    this.optional = optional;
    this.defaultVal = defaultVal;

    if (prop === 'active'){
      this.setActive(false);
    }
    else if (isType.nullOrUndef(defInst[prop])) {
      defInst[prop] = Array.isArray(defaultVal) ? [...defaultVal] : defaultVal;
    }
    this.runOnce = runOnce;
    this.runOnceIsComplete = false;
    this.skipHeadless = skipHeadless;
  }
  static create(defInst, args){
    return new ConditionalProp(defInst, args);
  }
  setRunOnceState(){
    if (!this.runOnce){
      return true;
    }
    else if (this.runOnceIsComplete){
      return false;
    }
    else if (this.prop === 'val' && !this.runOnceIsComplete){
      setTimeout(() => {//setting val from conditional skips
        //routine setPristine so need to call this (avoiding infinite loop)
        // which will re-enable the dirty check.
        this.def.setPristine({fromConditional: true});
      }, 10);
    }
    this.runOnceIsComplete = true;
    return true;
  }
  updateFunction(result){
    let canRun = this.setRunOnceState();
    if (!canRun){
      return;
    }
    let { prop, def, defaultVal } = this;
    if (prop === 'active'){
      return this.setActive(result);
    }
    def[prop] = result ?? defaultVal;
    //todo: consider active, default result, etc.
    //console.log(`${this.def.chain}:updateFunction`, {[prop]: result});
    return result;
  }
  updateCondition(v){


    let canRun = this.setRunOnceState();
    if (!canRun){
      return;
    }
    let { prop, whenTrue, whenFalse } = this;

    if (prop === 'active'){
      this.setActive(v);
    }
    else if (v === true){
      this.def[prop] = whenTrue;
    }
    else if (v === false){
      this.def[prop] = whenFalse;
    }else if (this.def[prop] !== v){
      this.def[prop] = v;
    }

    return this.def[prop];
  }
  setActive(active){
    let canRun = this.setRunOnceState();
    if (!canRun){
      return;
    }
    active = Boolean(active);
    this.def.active = active;
    let {optional, keepVal} = this;
    if (!active && !keepVal){
      this.def.val = null;
    }
    if (optional !== undefined){
      this.def.optional = active ? optional : !optional;
    } else {
      this.def.optional = !active;
    }
  }
}
//todo: defaultVal

export class DataField{
  constructor(args) {
    this.isField = true;
    if (!args.val){
      this._val = null;
    } else if (!isType.conditional(args.val)) {
      this._val = this.defaultVal = args.val;
      delete args.val;
    }
    if (args.lockable && args.locked === undefined){
      args.locked = false;
    }

    //todo: cleanup logic here
    const excludedKeys = ['min', 'max', 'conditionals', 'conditional', 'selected', 'name'];
    Object.keys(args).filter(k => k.startsWith('$')).forEach(k => {
      let cp = args[k];
      delete args[k];
      if (!this.conditionals){

        this.conditionals = {};
      }
      cp.prop =  k.substr(1);
      this.conditionals[cp.prop] = ConditionalProp.create(this, cp);
    });
    Object.keys(args).filter(k => !excludedKeys.includes(k)).forEach(k => {
      if (isType.conditional(args[k])){
        this.addConditionalProp(k, args[k]);
        delete args[k];
      } else {
        try {
          this[k] = args[k];
        }catch(ex){
          console.warn({dataFieldKeyError: k, args});
        }
      }
    });

    this.initChain(args);
    this.setPristine();
    this.type = args.type || 'data';
    if (this.conditionals) {

      this.conditionalProps = Object.values(this.conditionals);
      if (this.conditionals.active){
        this.conditionals.testedHeadless = false;
      }
    }
    //return this;
  }
  get val(){
    return isType.nullOrUndef(this._val) && !isType.nullOrUndef(this.defaultVal) ? this.defaultVal : this._val;
  }
  set val(v){
    this._val = v;
    if (this.dataField && this.dataField?.chain !== this.chain){
      this.dataField._val = v;
    }
  }
  get active(){
    return isType.nullOrUndef(this._active) ? true : this._active;
  }

  set active(v){this._active = v;}
  setPristine({revert = false, fromConditional = false} = {}){
    if (this._children && this._children.length){

      this._children.forEach(c => {
        if (!c.setPristine){
          console.warn({setPristine: c});
        }else {
          c.setPristine({revert});
        }
      });
    }

    if (this.type === 'data' && !revert){
      return;
    }
    if (revert){
      this.val = this._originalVal;
    }else {
      this._originalVal = this.val;
      // original val should not be a fallback value
      if (this._val !== this._originalVal && !isType.nullOrUndef(this.defaultVal) && this._originalVal === this.defaultVal){
        this._originalVal = this._val;
      }
    }
    if (!fromConditional && Array.isArray(this.conditionalProps)) {
      this.conditionalProps.forEach(cp => {
        if (cp.runOnce) {
          cp.runOnceIsComplete = false;
        }
      });
    }
    this.touched = false;
    this.blurred = false;
    this._forceDirty = false;
  }
  initChain(args){
    let {chain, key, page} = args;
    if (key === chain){
      page = key;
    }
    else if (this.parent?.chain && key){
      chain = `${this.parent.chain}.${key}`;
      page = this.parent.page ?? this.chain.split('.')[0];
    }
    else {
      if (!chain) {
        chain = `${page}.${key}`;
      }
      if (!key) {
        key = chain.split('.').pop();
      }
      if (!page) {
        let s = chain.split('.');
        s.pop();
        page = s.join('.');
      }
      if (chain.split('.').length === 3) {
        this.itemKey = this.chain.split('.')[1];
      }
    }
    this.chain = chain;
    this.key = key;
    this.page = page;

    if (!chain || chain.includes('undefined')){
      if (args.type === 'file'){
        this.chain = `quote.files.${key}`;
      }else {
        debugger;
      }
    }
    if (!args.tags){
      this.tags = '';
    }else{
      this.tags = args.tags;
    }
    this.tagList = this.tags.split(',').map(s => s.trim().toLowerCase());
    if (this.group && !this.groups){
      this.groups = this.group;
    }
    this.groupList = this.groups ? this.groups.split(',').map(s => s.trim().toLowerCase()) : [];
  }
  get sibling(){
    return key => this.parent?.child(key);
  }
  get isValid(){
    return this.optional !== false;
  }
  setKey(key){
    setKey(key, this);
  }
  addConditionalProp(prop, cp){
    if (!cp){
      debugger;
    }
    if (!this.conditionals){
      this.conditionals = {};
    }
    delete cp.$conditional;
    if (prop === 'active' && ['test', '$switch', '$and', '$or'].every(key => cp[key] === undefined)){
      cp = { test: cp };
    }
    cp.prop = prop;
    this.conditionals[prop] = ConditionalProp.create(this, cp);
  }
  hasTag(tag){
    return this.tagList.includes(tag.toLowerCase());
  }
  reset(hard = false){
    let externallyBound = this.hasTag('hasExternalSource') || this.parent?.hasTag('hasExternalSource');
    if (externallyBound && hard !== true){
      return;
    }
    if (this.type === 'multi'){
      Object.keys(this.val).forEach(k => this.val[k] = false);
    }
    else {
      this.val = null;
      this.valid = null;
    }
    if (this.needsValidation) {
      this.touched = this.blurred = false;
    }
  }
  get root(){
    let parent = this.parent;
    while(parent?.parent){
      parent = parent.parent;
    }
    return parent;
  }
  get vm(){
    return this.root?.runtime?.vm;
  }
  get getters(){
    return this.root?.runtime?.getters;
  }

}

export class FieldDef extends DataField{
  constructor(args) {
    super(args);
    this.testTip = null;
    this.guid = args.guid ?? guid();

    let defaults = {
      lbl: '', type: 'data',
      optional: false
    };
    Object.keys(defaults).forEach(k => {
      if (isType.nullOrUndef(this[k])){
        this[k] = args[k] = defaults[k];
      }
    });

    if (this.needsValidation) {
      this.touched = false;
      this.blurred = false;
    }


    this.clientHeight = 0;

    //Please do not delete. Ron is using to track/doc/refactor semantic json usage
    /*if (!allKeys.types[args.type]){
      allKeys.types[args.type] = {};
    }*/

    return this;
  }
  static create(args){
    if (isType.field(args)){
      return args;
    }
    let {type} = args;
    if (type === 'geocode'){
      args.lblClass = args.lblClass ?? 'geo-lbl';
      return AddressDef.create(args);
    }
    if (type === 'date'){
      return new DateDef(args);
    }
    if (type === 'check'){
      return new CheckDef(args);
    }
    if (type === 'select' || type === 'radio'){
      return new SelectDef(args);
    }
    if (type === 'multi'){
      return new MultiDef(args);
    }
    if (type === 'autocomplete'){
      return new AutocompleteDef(args);
    }
    if (type === 'phone'){
      return new PhoneDef(args);
    }
    if (['yn', 'yn-radio'].includes(type)){
      return new YesOrNoDef(args);
    }
    if (['int', 'currency'].includes(type)){
      return new NumericDef(args);
    }
    if (type === 'percent'){
      return new PercentDef(args);
    }
    if (['txt', 'text'].includes(type)){
      if (type === 'txt'){
        console.warn({legacyType: args});
        args.type = 'text';
      }

      return new TextDef(args);
    }
    if (type === 'data'){
      let dataDef = new DataField(args);
      dataDef._active = false;
      return dataDef;
    }
    try {
      return new FieldDef(args);
    }catch(ex){
      console.warn({ex, args});

    }
  }


  get dirty(){
    return this._forceDirty || (this.type !== 'data' && this.active && this.val !== this._originalVal);
  }


  get needsValidation(){
    return !this.readOnly && !['info', 'data', 'bool'].includes(this.type);
  }
  get isRequired(){
    if (this.readOnly || this._active === false ||
      ['info', 'data', 'bool'].includes(this.type)){
      return false;
    }

    if (!isType.nullOrUndef(this.optional)){
      return this.optional === false;
    }
    return true;
  }
  get isTypeValid(){
    return null;//override function
  }
  get isValid(){

    let {key,  val} = this;//FromChain(def.chain);

    this.validationTip = '';
    this.validationTip = undefined;
    if (key === 'zip'){
      return val && val.replace(/[^0-9]/g, '').length === 5;
    }
    if (!this.active){
      return true;
    }
    if (isType.bool(this.isTypeValid)){
      return this.isTypeValid;
    }
    return false;
  }
  inGroup(group){
    return this.groupList.includes(group.toLowerCase());
  }

  toString(){
    let {chain, key, val, lbl, vals} = this;
    return JSON.stringify({chain, key, val, lbl, vals});
  }
  get dataField(){
    if (this.proxyFor){
      if (this.proxyFor.startsWith('sibling::')){
        return this.sibling(this.proxyFor.split('::')[1]);
      }else if(this.getters){
        return this.getters.itemFromChain(this.proxyFor);
      }
    }
    return null;//this.proxyFor ? this.vm?.itemFromChain(this.proxyFor) : this;
  }
}

export class NumericDef extends FieldDef {
  constructor(args) {
    super(args);
    if (args.range){
      let [_min, _max] = args.range;
      this._min = Number(_min);
      this._max = Number(_max);
    }
    if(args.min){
      this._min = Number(args.min);
    }
    if(args.max){
      this._max = Number(args.max);
    }
    this.isNumeric = true;
  }
  get min(){ return this._min || 0;}
  get max(){ return this._max || maxOSInt;}
  get isRequired(){
    if (this.readOnly || !this.active){
      return false;
    }
    if (this.optional){
      if (!isType.nullOrUndef(this._min) || !isType.nullOrUndef(this._max)){
        return this.val !== null;
      }
      return false;
    }
    return true;
  }
  get dirty(){
    return this._forceDirty || (this.active && `${this._originalVal}` !== `${this._val}`);
  }

  get isValid(){
    /*if (!this.isRequired){
      return null;
    }*/
    this.validationTip = null;
    let {min, max, val} = this;
    if (val === null || val === ''){
      this.validationTip = 'Enter a valid number';
      return false;
    }
    val = Number(val);
    if (isNaN(val)){
      this.validationTip = 'Enter a valid number';
      return false;
    }
    let inBounds = val >= min && val <= max;
    if (!inBounds){
      if (isFinite(max) && isFinite(min) && max !== maxOSInt){
        this.validationTip = `Enter a value between ${min} and ${max}`;
      }else if (val < min){
        this.validationTip = `Enter a value greater than ${min}`;
      }else{
        this.validationTip = `Enter a value less than ${max}`;
      }
    }
    return inBounds;
  }
  get val(){
    return super.val;
  }
  set val(v){
    if (v === ''){
      v = null;
    }
    super.val = v;
  }
}

export class PercentDef extends NumericDef{
  constructor(args) {
    super(args);
    if (!args.range && args.max === undefined){
      this._min = 0;
      this._max = 100;
    }
    if (!args.classList && !args.width){
      args.width = '120px';
    }
  }
}

export class TextDef extends FieldDef{
  constructor(args) {
    super(args);
  }

  get isTypeValid(){
    let valid;
    let val = this.val;
    let maxLength = this.maxLength || 200;
    if (this.key.toLowerCase().includes('email')) {
      if (this.validOverride === false) {
        this.validationTip = this.validationTipOverride;
        return false;
      }
      const re = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;
      valid = re.test(val);
      if (!valid){
        this.validationTip = 'Invalid email address';
      }
      return valid;
    }
    else  if (val){
      return val.length > 0 && val.length <= maxLength;
    }
    return false;

  }
}
export class PhoneDef extends FieldDef{
  constructor(args) {
    super(args);
  }
  get digits(){
    let v = this.val || '';
    let onlyNumbers = v.replace(/[^0-9]/g, '');
    if (onlyNumbers.startsWith('1') || onlyNumbers.startsWith('0')){
      onlyNumbers = onlyNumbers.substr(1);
    }
    return onlyNumbers.substr(0, 10);
  }
  get val(){
    let v = super.val;
    return v === 'null' ? '' : v;
  }
  set val(v){
    if (v === 'null'){
      v = '';
    }
    super.val = v;
  }
  get formatted(){
    if (this.digits.length > 2 && this.digits.length < 17){
      let ext = this.digits.length > 10 ? ` ext. ${this.digits.substr(10)}` : '';
      return `(${this.digits.substr(0, 3)}) ${this.digits.substr(3, 3)}-${this.digits.substr(6, 4)} ${ext}`;
    }

    return this.val;
  }
  get isRequired(){
    let baseReq = super.isRequired;
    if (!baseReq){
      return Boolean(this.digits.length);
    }
    return baseReq;
  }
  get isTypeValid(){
    let digitCount = this.digits ? this.digits.length : 0;
    return digitCount > 9 && digitCount < 16;
  }
}
export class DateDef extends FieldDef {
  constructor(args) {
    super(args);
    let v = this.val;
    if (args.min){
      this._min = args.min;
    }
    if (args.max){
      this._max = args.max;
    }

    if (v && v.length === 14 && isType.snum(v)){
      const numAt = (from, to) => Number(v.substr(from, to));
      this.val = new Date(numAt(0, 4), numAt(4, 2), numAt(6, 2));//`${numAt(6,2)}/${numAt(4,2)}/${numAt(0, 4)}`;
    }
    if (!args.width){
      args.width = '75%';
    }
  }
  get isTypeValid(){
    return isType.date(this.val);
  }
  set val(val){
    this._val = val;
    if (isType.date(val)) {
      this._dpVal = `${val.getFullYear()}-${val.getMonth() + 1}-${val.getDate()}`;
    }else {
      this._dpVal = val;
    }
  }
  get val(){
    if (isType.date(this._val)){
      return this._val;
    }else if (this._val) {
      let dateVal = new Date(this._val);
      return isType.date(dateVal) ? dateVal : null;
    }
    return null;
  }
  set dpVal(val){
    if (isType.string(val) && val.split('-').length === 3) {
      let [yyyy, mm, dd] = val.split('-');//.map(part => Number(part));
      this.val = new Date(`${mm}/${dd}/${yyyy}`);
      //this._dpVal = val;
    }else{
      console.log({setDpVal_else: val});
    }

  }
  get dpVal(){
    return this._dpVal || this._val;
  }
  get apiVal(){
    if (isType.string(this.dpVal) && this.dpVal.split('-').length === 3) {
      let [yyyy, mm, dd] = this.dpVal.split('-');
      return `${yyyy}-${mm.padStart(2, '0')}-${dd.padStart(2, '0')}`;
    }
    return null;
  }
  get dirty(){
    return this._forceDirty || (this.active && `${this.val}` !== `${this._originalVal}`);
  }
}

export class CheckDef extends FieldDef{
  constructor(args) {
    super(args);
    this.inlineLabel = args.inlineLabel ?? false;
  }
  get isTypeValid(){
    return true;
  }
}

export class SelectDef extends FieldDef{
  constructor(args) {
    super(args);
    if (args.vals === undefined){
      args.vals = [];

    }
    if (args.vals.length) {
      if (isType.primitive(args.vals[0]))
        args.vals = args.vals.map(v => {
          return {
            key: isType.snum(v) ? v : camelCased(dashCased(v)),
            lbl: v
          };
        });
    } else {
      args.vals = [];
    }
    if (args.vals.filter(v => isType.snum(v.key) &&
      isType.snum(v.lbl && v.lbl.replace && v.lbl.replace(',', ''))).length > 1){
      args.vals = args.vals.map(v => {
        if(isType.snum(v.key)){
          v.key = Number(v.key);
        }
        return v;
      });
      //console.log({numeric: JSON.stringify(args.vals)});
      args.vals = sortByKey(args.vals, 'key');//buggy sort??
    }
    if (args.sortKey) {
      args.vals = sortByKey(args.vals, args.sortKey);
    }
    this.vals = args.vals;
    if (args.emptyVal === undefined){
      this.emptyVal = 0;
    }
  }
  get empty(){
    return this.vals.length === 0;
  }
  get isTypeValid(){
    return this.val !== null;
  }
  get isUnlistedVal(){
    return !isType.nullOrUndef(this.val) && !this.vals.some(v => `${v.key}` === `${this.val}`);
  }
  get text(){
    return this.selectedText || '';
  }
  get selectedItem(){
    let { val, vals} = this;
    if (Array.isArray(vals)) {
      return vals.find(v => `${val}` === `${v.key}`);
    }
    return null;
  }
  get selectedCode(){
    return this.selectedItem?.code;
  }
  get selectedText(){
    let { val, vals} = this;
    if (Array.isArray(vals)) {
      let item = vals.find(v => `${val}` === `${v.key}`);
      if (isType.object(item)) {
        return item.lbl ?? null;
      }
      else if (!isType.nullOrUndef(val) && this.hasTag('allowUnlisted')){
        if (val === 0 || val === '0') {
          return null;
        }
        if (val === '') {
          return this.placeholder || null;
        }
        return formatter.format(val).substr(1).replace('.00', '');
      }
      if ((!vals || !vals.length) && this.emptyPlaceholder) {
        return this.emptyPlaceholder;
      }
    }

    return this.placeholder || null;
  }
  get val(){
    if(isType.nullOrUndef(this._val)) {
      if (this.vals?.length === 1 && !this.noAutoSelect) {
        return this.vals[0]?.key ?? null;
      }
      else if (!isType.nullOrUndef(this.defaultVal)){
        return this.defaultVal;
      }
    }
    return this._val;
  }
  set val(v){
    this._val = v;
  }
  reset(hard){
    super.reset(hard);
  }

}

export class YesOrNoDef extends FieldDef{
  constructor(args) {
    super(args);
    this.vals = [{key: true, lbl: 'Yes'}, {key: false, lbl: 'No'}];
    if (!isType.bool(this.val)){
      this._val = null;
    }
    if (this.type.includes('radio')){
      this.classList = this.classList ?? 'w-100';
    }
  }
  get isTypeValid(){
    return isType.bool(this.val);
  }
  get selectedText(){
    if (isType.bool(this.val)){
      return this.val ? 'Yes' : 'False';
    }
    return null;
  }
  get osVal(){
    return this.val === true ? 1 : this.val === false ? 2 : '';
  }
  get val(){
    if(this._val === null && !isType.nullOrUndef(this.defaultVal)){
      return this.defaultVal;
    }
    return this._val;
  }
  set val(v){
    if (isType.snum(v)){
      if ( Number(v) === 1){
        this._val = true;
      }else if( Number(v) === 2){
        this._val = false;
      }
      else {
        this._val = Boolean(v);
      }
    }
    else if (isType.nullOrUndef(v)){
      this._val = null;
    }else {
      this._val = Boolean(v);
      console.log({_val: this._val});
    }
  }
  get dirty(){
    return this._forceDirty || (this.val !== this._originalVal);
  }
}

export class MultiDef extends FieldDef{
  constructor(args) {
    super(args);
    if (!isType.object(this.val)){
      args.val = {};
      args.vals.forEach(({key}) => args.val[key] = false);
    }
    if (args.runtimeFilter){
      this.runtimeVals = [];
    }
  }
  get isRequired(){
    return !!this.minSelection;
  }
  get isTypeValid(){
    if (this.minSelection){
      let selection = Object.values(this.val).filter(v => v === true);
      if (selection < this.minSelection){
        const noun = this.minSelection > 1 ? 'items' : 'item';
        this.validationTip = `You must select at least ${this.minSelection} ${noun}`;
        return false;
      }
      //return selection >= this.minSelection;
    }
    return true;
  }


}

export class AutocompleteDef extends FieldDef{
  constructor(args) {
    super(args);
  }
  get isTypeValid(){
    return this.optional || this.val !== null;
  }
}

export class EntityDef extends DataField{
  constructor(args) {
    super(args);
    this.isEntity = true;
    Object.entries(args).filter(([key]) => key !== 'name').forEach(([key, val]) => {
      try {
        this[key] = val;
      }catch(ex){
        console.warn({ex, EntityDefInitError: [key, val], args});
      }
    });
    if (args.children){

      if (!args.children?.map){
        debugger;
      }
      args._children = args.children.map((c, i) => {
        if (!c){
          console.warn({missing: i, kids: args.children});
        }
        c.parent = this;
        return isType.nullOrUndef(c.isRequired) ? FieldDef.create(c) : c;
      });
      delete args.children;
    }else{
      this._children = [];
    }
    return this;
  }
  static create(args){
    if (args.key === 'locations'){
      return new LocationManager(args);
    }
    return new EntityDef(args);
  }
  get dataTree(){

    if (this._children){

      let entries = this._children.map(child => [
        child.key, EntityDef.childTree(child)
      ]);
      return Object.fromEntries(entries);
    }
    return {};
  }
  static get childTree(){
    return (c, group) => {
      let tree = group ? c.groupedTree : c.dataTree;
      let val = c.val;
      if (!isType.nullOrUndef(tree)){
        if (isType.object(val) && Object.keys(val).every(key => tree[key] === val[key])){
          return val;
        }
        return isType.nullOrUndef(val) ? tree : {
          val, dataTree: tree
        };
      }
      return val;
    };
  }
  get groupedTree(){
    if (this._children){
      let entities = {};
      let groups = {};
      let tagGroups = {};
      let ungrouped = [];
      this._children.forEach(c => {
        let entry = [c.key, EntityDef.childTree(c, true)];
        let g = c.hasTag && c.hasTag('exposures') ? 'exposures' : c.group;
        let tags = !c.tags || c.tags === '' ? [] : c.tagList;
        if (c.isEntity) {
          entities[`${c.key}*`] = entry[1];
          return;
        }
        if (tags.length){
          c.tagList.forEach(tag => {
            if (!tagGroups[tag]){
              tagGroups[tag] = [];
            }
            tagGroups[tag].push(entry);
          });
        }
        if (g){
          if (!groups[g]){
            groups[g] = [];
          }
          groups[g].push(entry);
        } else if (isType.object(c.val) || Array.isArray(c.val)){
          ungrouped.splice(0, 0, entry);
        } else if (!tags.length){
          ungrouped.push(entry);
        }
      });

      let entries = Object.entries(groups).map(([group, childEntries]) => [
        `[${group}]`, Object.fromEntries(childEntries)
      ]);
      let tagged = Object.entries(tagGroups).map(([tag, childEntries]) => [
        `tag:${tag}`, Object.fromEntries(childEntries)
      ]);

      return {...entities, ...Object.fromEntries([...entries, ...tagged, ...ungrouped])};
    }
    return {};
  }
  get focusedState(){
    let anyTouched = false;
    let anyInFocus = false;
    this.addressKeys.forEach(key => {
      let f = this.child(key);
      if (f?.touched) {
        anyTouched = true;
        if (!f.blurred) {
          anyInFocus = true;
        }
      }
    });
    return {
      anyTouched, anyInFocus, readyToValidate: !anyInFocus && anyTouched
    };
  }
  get child() {
    return (key) => {
      return this._children?.find(c => c.key === key);
    };
  }
  get childVal(){
    return key => this.child(key)?.val;
  }
  set child(props){
    this.addChild(props);
  }
  addChild(props){
    if (!props || !props.key){
      debugger;
    }
    try {
      if (this.child(props.key)) {
        if (props.isField) {
          this._children.splice(this._children.find(c => c.key === props.key), 1, props);
          return props;
        }
        return;
      }
    }catch(ex){
      const inst = this;
      console.warn({inst});
      debugger;
    }
    props.chain = `${this.chain}.${props.key}`;
    if (this.selectionProxy){
      props.selectionProxy = this.selectionProxy;
    }

    let child = isType.field(props) ? props : FieldDef.create(props);
    child.parent = this;
    this._children.push(child);
    if (isType.object(this._treeQueue)){
      this.treeVals = this._treeQueue;
    }
    return child;
  }
  removeChild(key){
    let childIndex = this.children.findIndex(c => c.key === key);
    this._children.splice(childIndex, 1);
  }
  clearChildren(){
    if (Array.isArray(this._children)) {
      this._children.splice(0, this._children.length);
    }
  }
  pruneNullChildren(){
    if (this.children.some(child => isType.nullOrUndef(child?.dataTree))){
      let nullRefIndexList = this.children.map((child, i) =>
        isType.nullOrUndef(child?.dataTree) ? i : child);
      nullRefIndexList.filter(item => isType.number(item)).reverse()//splice backwards
        .forEach(spliceNullIndex => {
        this._children.splice(spliceNullIndex, 1);
      });
    }
  }
  get children(){
    return this._children || [];
  }
  set children(children){
    this._children = children;
    if (this._treeQueue){
      this.treeVals = this._treeQueue;
      this._treeQueue = null;
    }
  }
  set treeVals(kv){
    if (Array.isArray(this._children)) {
      let existing;
      Object.entries(kv).filter(([, val]) => !isType.conditional(val)).forEach(([key, val]) => {
        let c = this._children.find(child => child.key === key);
        if (c) {
          existing = c;
          c.val = val;
        }
      });
      if (!existing){
        Object.entries(kv).forEach(([key, val]) => {
          this.addChild({key, val, type: 'data'});
        });
      }
    }
    else if (this.type === 'multi'){
      Object.entries(kv).forEach(([key, val]) => this.val[key] = val);
    }
    else{
      this._treeQueue = this._treeQueue ? {...this._treeQueue, ...kv} : kv;
    }
  }
  reset(hard){
    //remove temporary added children
    this.children.filter(c => c.hasTag('added')).reverse()
      .forEach(addedChild => this.removeChild(addedChild.key));

    this.children.forEach(c => {
      if (c && c.reset) {
        c.reset(hard);
      }
    });
  }
  hasTag(tag){
    let tags = isType.string(this.tags) ?
      this.tags.split(',').map(t => t.toLowerCase().trim()) : [];
    return tags.includes(tag.toLowerCase());
  }
  setKey(key){
    setKey(key, this);
  }
  toString(){
    let {key, chain, val, vals, dataTree} = this;
    return JSON.stringify({key, chain, val, vals, ...dataTree});
  }
}

/*export class SelectableEntity extends EntityDef{
  constructor(props) {
    super(props);
    this._selectableChildren = props.selectableChildren ?? false;
    this._selected = props.selected;
  }
  get selected(){
    if (this._selectableChildren){
      let children = this.child(this._selectableChildren).children;
      if (children.length) {
        let firstChildState = children[0].selected;
        if (children.every(c => c.selected === firstChildState)){
          this._selected = firstChildState;
          return firstChildState;
        }
        this._selected = null;
        return null;
      }
    }
    return this._selected;
  }
  set selected(v){
    this._selected = v;
    if (this._selectableChildren){
      this.selectedChildren().forEach(c => c.selected = v);
    }
  }
  get selectedChildren(){
    let select = c => !this._selectableChildren || (c.selected && c.group === this._selectableChildren);
    return filter => {
      if (!this.selected){
        return [];
      }
      if (isType.object(filter)){
        if (filter.group){
          return this.children.filter(c => select(c) && c.group === filter.group);
        }
      }else if (isType.string(filter)){//default to key
        return this.children.filter(c => select(c) && c.key === filter);
      }
      return this.children.filter(select);
    };
  }
}*/

export class UploadedFileDef extends EntityDef{
  constructor(args) {
    super(args);
    this.reader = new FileReader();
    this.resolver = null;
    this.promise = new Promise(res => this.resolver = res);
    if (args.file) {
      this.reader.addEventListener('loadend', e => this.parse(e));
      this.reader.readAsDataURL(this.file);
    }else{
      this.resolver(this);
    }
  }
  static create(args){
    args.type = 'file';
    if (!args.chain){
      args.chain = 'quote.files';
    }

    if (args.file){
      args.fileName = args.file.name;
    }

    if (args.desc){
      if (args.desc.includes('::')){
        let [fileType, description] = args.desc.split('::');
        args.contentType = fileType;
        args.description = description;
      }
      else if (args.desc.includes(`"fileType"`)){
        let {fileType, description} = JSON.parse(args.desc);
        args.contentType = fileType;
        args.description = description;
      }
      else{
        args.description = args.desc;
      }
      delete args.desc;
    }
    let meta = [
      'contentType', 'fileInfo',
      'dmsId', 'externalId', 'status', 'created',
      'contextId', 'genType', 'contextObject', 'documentId',
      'size', 'renderStatus', 'source', 'quoteId'
    ].map(key => {
      let val = args[key];
      if (val !== undefined) {
        delete args[key];
      }
      return {key, type: 'data', val };
    });

    let children = [
      ...meta,
      { key: 'fileName', type: 'text', val: args.fileName },
      { key: 'description', type: 'text', val: args.description},
      { key: 'fileType', readOnly: true, type: 'text', val: args.fileType }

    ];

    args.deleted = false;
    let inst = new UploadedFileDef(args);
    children.forEach(c => inst.addChild(c));
    return inst;
  }
  parse(e) {
    this.child('fileInfo').val = e.target.result.split('base64,')[1];
    this.resolver(this);
  }

  download(fileContent){
    if (!fileContent){
      fileContent = this.child('fileInfo').val;
    }
    //debugger;
    fileContent = atob(fileContent);
    let buffer = new Uint8Array(fileContent.length);
    for (let i = 0; i < fileContent.length; i++){
      buffer[i] = fileContent.charCodeAt(i);
    }

    let mimeType = mimeTypeByExtension[this.ext];
    if (!mimeType) {

      console.error(`Could not find mimetype ${this.ext} for file`, this);
    }
    let blob = new Blob([buffer], { type: mimeType ?? 'text/plain' });
    let a  = document.createElement('a');
    a.download = `${this.name}.${this.ext}`;
    a.href = URL.createObjectURL(blob);
    a.click();
    console.log({a});
  }
  get uploadParams(){
    let entries = ['quoteId', 'fileName', 'description', 'contentType', 'fileData']
      .map(key => [key, this.dataTree[key] ?? this[key]]);
    return Object.fromEntries(entries);
  }


  get name(){
    let raw = this.dataTree.fileName;
    if (!raw){
      console.log({noName: this.dataTree});
      return null;
    }
    let split = raw.includes('.') ? raw.split('.') : null;
    if (split) {
      split.pop();
      return split.join('.');
    }
    return raw;
  }
  set name(val){
    if (!val.includes('.')){
      val = `${val}.${this.ext}`;
    }
    this.nameField.val = val;
  }
  get ext(){
    try {
      let raw = this.dataTree.fileName;
      if (!raw) {
        console.log({noName: this.dataTree});
        return 'pdf';
      }
      if (raw.includes('.')) {
        return raw.split('.').pop().toLowerCase();
      }
      return 'pdf';
    }catch(ex){
      return 'pdf';
    }
  }
  get nameField(){
    let f = this.child('fileName');
    f.width = '100%';
    return f;
  }
  get descField(){
    let f = this.child('description');
    f.width = '100%';
    f.autoFocus = true;
    f.maxlength = 200;
    f.fieldLockOverride = true;
    return f;
  }
  get description(){
    return this.descField.val ?? undefined;
  }
  set description(val){
    this.descField.val = val;
  }
  get fileData(){
    return this.child('fileInfo').val;
  }
  set fileData(fileInfo){
    this.treeVals = {fileInfo};
  }
}

export class AddressDef extends EntityDef {
  constructor(args) {
    super(args);
    this.type = 'geocode';
    this.isAddress = true;
    this.isNotInsured = false;
    this.requireExact = args.requireExact;
    this._children = [];
    let stateList = this.stateList = typeDefs.stateList.map(({key, lbl, code}) => {
      return{//use short names
        key, lbl: code, code, name: lbl
      };
    });
    let childFields = {
      street1: { type: 'text', lbl: 'Address Line 1'},
      street2: { type: 'text', optional: true, lbl: 'Address Line 2'},
      city: { type: 'text', lbl: 'City' },
      state: { type: 'select', vals: stateList, lbl: 'State'},
      stateCode: {
        type: 'data',
        val: {
          $conditional: true,
          $compute: {
            chain: 'sibling::state',
            prop: 'selectedText'
          }
        }
      },
      zipcode: { type: 'text', lbl: 'Zip Code'},
      addressId: { type: 'data' },
      county: {type: 'data'},
      isMailing: {type: 'data'},
      isBilling: {type: 'data'},
      isPrimary: {type: 'data'},
      verified: {type: 'data', val: this.requireExact !== true}
    };
    if (args.street2 === false){
      delete childFields.street2;
    }

    Object.entries(childFields).forEach(([key, def]) => {
      //let val = key==='stateCode' ? childFields
      this.addChild({
        key,
        placeholder: def.lbl ?? undefined,
        val: args[key],
        ...def
      });
    });

    this.treeVals = AddressDef.getStateObj(args.state);

  }
  get isMailingAddress(){
    return this.child('isMailing').val;
  }
  set isMailingAddress(v){
    this.child('isMailing').val = v;
  }

  static getStateObj(s){
    if (!s) {
      return {};
    }
    s = `${s}`;
    let obj = typeDefs.stateList.find(item =>
      `${item.key}` === s || item.code === s || item.value === s
    );
    if (!obj){
      console.warn({noState: s});
      return {};
    }
    return {
      state: obj.key,
      stateCode: obj.code
    };
  }
  static create(args){
    if (!args.key){
      args.key = 'addressDef';

    }
    args.type = 'geocode';
    let entries = ['street1', 'street2', 'city', 'state', 'zipcode', 'addressId']
      .filter(key => args[key] ?? false).map(key => [key, args[key]]);
    //console.log({entries, args});
    return new AddressDef({
      ...args,
      ...Object.fromEntries(entries)
    });
  }
  get addressId(){return this.childVal('addressId');}
  set addressId(addressId){
    this.treeVals = {addressId};
  }
  get isValid(){
    let {street1, city, state, zipcode} = this.addressObj;
    let ok = v => isType.string(v) && v.length > 0;
    return ok(street1) && ok(city) && (ok(`${state}`)) && ok(zipcode);
  }
  get addressFields(){
    return this.addressKeys.map(key => this.child(key));
  }
  get addressKeys(){ return ['street1', 'street2', 'city', 'state', 'zipcode'];}
  get addressObj(){

    return this.val;
  }
  set addressObj(v){
    this.val = v;
  }
  set state(s){
    let obj = AddressDef.getStateObj(s);
    this.treeVals = obj;
  }
  get state(){
    let {state, stateCode} = this.val;
    return {state, stateCode};
  }
  set val(addressObj){
    if (!isType.object(addressObj)){
      if (addressObj === null) {
        this.children.forEach(c => c.reset());
      }
      return;
    }
    let {state, stateCode} = addressObj;
    if (!state || !stateCode) {
      if (isType.string(state) && state.length === 2) {
        addressObj.stateCode = addressObj.state;
        let sObj = this.stateList.find(s => s.code === addressObj.state);
        if (sObj) {
          addressObj.state = sObj.key;
        }
      } else if (!addressObj.stateCode) {
        let sObj = this.stateList.find(s => s.key === addressObj.state);
        if (sObj) {
          addressObj.stateCode = sObj.code;
        }
      }
    }
    Object.entries(addressObj).filter(([k]) => k && !k.startsWith('is'))//don't copy isBilling, etc
      .forEach(([key, val]) => {
      if (this.child(key)) {
        this.child(key).val = val;
      }
    });
  }
  get val(){
    let {state, stateCode} = this.dataTree;
    if (state && !stateCode){
      this.treeVals = {
        stateCode: AddressDef.getStateObj(state)?.stateCode
      };
    }
    return this.dataTree;
  }
  get address(){

    let {street1, street2, city, stateCode, zipcode} = this.addressObj;
    //let stateCode = AddressDef.getStateObj(state).code;
    if (street1 && city) {
      let street = street2 ? `${street1} ${street2},` : street1;
      return `${street} ${city}, ${stateCode} ${zipcode}`;
    }
    return null;
  }
  get isRequired(){
    if (this.readOnly || this._active === false || this.optional === true){
      return false;
    }
    return true;
  }
  get countyState(){
    return `${this.val.county.toUpperCase()}, ${this.val.stateCode}`;
  }
  get isVerified(){
    return this.isValid && this.child('verified') === true;
  }
  toString() {
    return this.address;
  }
}

export class LocationDef extends EntityDef {
  constructor(args) {
    super(args);
    this.isLocation = true;
    if (args.street1){
      debugger;
    }
    this._selected = args.selected ?? null;

    this.isNotInsured = false;
    this.guid = guid();
    const bcArgs = {key: 'buildings', type: 'data', parent: this};
    let buildingContainer = EntityDef.create(bcArgs);
    this.addChild(buildingContainer);
  }
  get selected(){
    if (!this.buildingList.length){
      return this._selected;
    }
    const bsel = b => b.selected;
    return this.buildingList.every(bsel) ? true : this.buildingList.some(bsel) ? null : false;
  }
  set selected(selected){
    this._selected = selected;
    if (isType.bool(selected)) {
      this.buildingList.forEach(b => b.selected = selected);
    }
  }
  static create(args){
    return new LocationDef(args);
  }

  get outdoorPropertyList() {
    let selected = this.outdoorPropList.filter(c =>
      c.val === true || c.propertyId);//either selected or deleted (if propertyId exists)
    let hasSetTotal = false;
    let deleteCount = 0;
    selected = selected.map(c => {
      let {propertyType, propertyClass, propertyId} = c;
      let p = { propertyType, propertyClass, propertyId };
      if (!p.propertyId) {
        const outdoorPropertyByType = this.listOfOutdoorPropertyEntries.find(p => p.propertyClass === propertyType);
        if (outdoorPropertyByType) {
          p.propertyId = outdoorPropertyByType.propertyId;
        }
      }
      const checkValidAmt = () => {
        if (p.propertyId && isNaN(Number(p.amtOfInsurance)) || Number(p.amtOfInsurance) < 1){
          p._delete = true;
        }
      };
      if (!c.val){
        deleteCount++;
        p._delete = true;
      }else if(Number(propertyClass) === 2004){
        p.amtOfInsurance = this.child('treeShrubBlanket').val;
        checkValidAmt();

      }else if(Number(propertyClass) === 2003){
        p.amtOfInsurance = this.child('outdoorBlanketTotal').val;
        checkValidAmt();
      } else if (!hasSetTotal) {
        //set blanket total on first list item and zero out others
        p.amtOfInsurance = this.child('outdoorBlanketTotal').val;
        hasSetTotal = true;
      }

      if (c.hasTag('descriptionRequired')){
        p.description = this.child('describeOther').val;
      }

      return p;
    });
    if (deleteCount === selected.length){
      console.log('remove all outdoor property selections');
      this.child('outdoorBlanketTotal').val = 0;
    }
    return selected;
  }

  static setOutdoorPropTypes(chain, listVals = [], propertyData = {}){
    let typeFields = [];
    let description;

    clone(outdoorPropTypeList).forEach(child => {

      let existingVal = listVals.find(p => p.propertyType === `${child.propertyType}`);

      if (existingVal){
        child.val = true;
        child.propertyId = existingVal.propertyId;
      }
      if (Number(child.propertyType) === 1012){
        child.tags = 'descriptionRequired';
        if (existingVal){
          description = existingVal.description;
        }
      }
      typeFields.push(child);
    });
    let coverageFields = clone(miscOutdoorProp).map(def => {
      let existingVal = propertyData[def.key];
      if (def.key === 'agreedValue'){
        if (existingVal === '1'){
          def.val = true;
        }else if (existingVal === '2'){
          def.val = false;
        }
      }else if (def.key === 'describeOther' && description){
        def.val = description;
      }else{
        def.val = existingVal;
      }
      return def;
    });

    return [...typeFields, ...coverageFields].map(def => {
      def.chain = `${chain}.${def.key}`;
      def.tags = def.tags ? `${def.tags},miscOutdoor` : 'miscOutdoor';
      return FieldDef.create(def);
    });
  }
  get outdoorPropList(){
    return this.children.filter(c => c.group === 'outdoorList');
  }

  static add(li, vals = {}){
    if (vals.isEntity){
      debugger;
    }
    if (li === undefined){
      li = this.parent.children.length;
    }
    let key = `location-${li + 1}`;
    let def = {
      type: 'data', li,
      chain: `locations.${key}`,
      key
    };


    let exposuresMulti = FieldDef.create({
      ...clone(exposuresMultiselect),
      chain: def.chain
    });
    if (vals.liabilityClasses){
      exposuresMulti.val = {...exposuresMulti.val, ...vals.liabilityClasses};
    }

    const liabilityFields = Object.keys(liabilityDetails).flatMap((key, i) => {
      return liabilityDetails[key].map((field, j) => {

        let title = j === 0 ? liabilityClasses[key] : undefined;

        field.chain = `locations.${def.key}.${field.key}`;
        const baseTags = field.tags ? field.tags + ',' : '';
        field.tags = baseTags + 'exposures';
        let has = key == 68500 ? [key, 41670] : key;//TODO: move to declared fields
        let activeCondition = {chain: `locations.${def.key}.liabilityClasses`, has};
        let test = field.extraCondition ? {
          $and: [activeCondition, field.extraCondition]
        } : {test: activeCondition};
        if (!title && ['41670', '68500'].includes(key)){//TODO: move to declared fields
          title = liabilityClasses[68500];
        }
        let fld = {
          ...field, title, optional: true, exposure: true,
          group: key, i,  chain: `locations.${def.key}.${field.key}`,
          active: field.active || {
            $conditional: true,
            ...test
          }
        };
        return FieldDef.create(fld);
      });
    });
    //console.warn(liabilityFields.map(f=>f.key))

    let outdoorPropertyFields =
      LocationDef.setOutdoorPropTypes(def.chain, vals?.outdoorProperties, vals?.locationPropertyCoverage || {});
    def.children = [
      exposuresMulti,
      ...liabilityFields,
      ...outdoorPropertyFields
    ];
    let locFields = clone(locationFields).map(cf => {
      cf.chain = `locations.${def.key}.${cf.key}`;
      return FieldDef.create(cf);
    });
    def.children.push(...locFields);

    def = LocationDef.create(def);
    def.addressDef = vals.address;
    def.listOfOutdoorPropertyEntries = vals.outdoorProperties;
    //debugger;
    def.treeVals = {
      name: def.name || `Location ${li + 1}`,
      scores: vals.scores
    };
    if (isType.object(vals)) {
      if (vals.liabilityClasses){
        //already selectively ingested in exposuresMulti.val
        delete vals.liabilityClasses;
      }
      if (vals.outdoorProperties) {
        //already ingested in children above
        vals.miscFurnCount = vals.outdoorProperties.length;
        delete vals.outdoorProperties;
      }
      def.treeVals = vals;
    }
    return def;
  }
  get name() {
    return `Location ${this.li + 1}`;
  }
  renumberProps(){
    let rows = this.miscProps;
    let count = 0;
    rows.forEach((row, i) => {
      count = i + 1;
      if (row[0]._delete){
        row[0]._delete = null;
      }
      if (!row.every(p => p.extra === i)){
        console.log({renumber: row});
        row.forEach(p => {
          p.setKey(`${p.key.split('_')[0]}_${count}`);
          p.extra = i;
        });
      }
    });
    this.treeVals = {miscFurnCount: count};
  }
  get miscProps(){
    return this.children.filter(c => c.group === 'outdoorList' && c.val);
  }
  removeUnsaved(){
    this.children
      .filter(c => c.hasTag('miscOutdoor'))
      .forEach(c => c.setPristine({revert: true}));
  }
  addBuilding(buildingDef){
    let buildingContainer = this.buildingsDef;
    buildingDef.chain  = `${buildingContainer.chain}.${buildingDef.key}`;
    buildingDef._children.forEach(c => c.chain = `${buildingDef.chain}.${c.key}`);
    buildingDef.tags = 'buildingData';
    if (!buildingContainer?.addChild){
      debugger;
      buildingContainer = EntityDef.create({key: 'buildings', type: 'data'});
      this.addChild(buildingContainer);
    }
    let child = buildingContainer.addChild(buildingDef);
    if (!child){
      debugger;
    }
    let defaults = buildingFormFields.filter(f => !isType.nullOrUndef(f.val)).map(f => [f.key, f.val]);
    let ids = ['locationId', 'cfLocationId', 'addressId'].map(key => [key, this.child(key)?.val ?? this.addressDef.val[key]]);
    const treeVals = Object.fromEntries([...defaults, ...ids]);

    try {
      child.treeVals = treeVals;
    }catch(ex){
      console.warn({ex, child, treeVals});
    }
    //debugger
    return child;
    /*buildingDef.group = 'buildings';
    this.addChild(buildingDef);*/
  }
  get buildings(){
    this.buildingsDef.pruneNullChildren();
    return this.buildingsDef?.dataTree;
  }
  get isOPO(){
    return !!(this.buildingsDef?.children ?? []).find(b => b.dataTree?._isPseudo);
  }
  is1190(){// conditional for locationField
    // chain: 'is1190::'
    return this.isOPO;
  }
  get buildingList(){
    this.buildingsDef.pruneNullChildren();
    let list = this.buildingsDef?.children ?? [];
    return list.filter(b => b?.dataTree && !b.dataTree?._delete);
  }
  get buildingsDef(){
    return this.child('buildings');
  }
  get addressDef(){
    return this.child('addressDef');
  }
  set addressDef(address){
    let def = {
      type: 'geocode',
      key: `addressDef`,
      width: '100%',
      requireExact: true,
      ...address
    };
    this.addChild(def);

  }
  get address(){
    return this.addressDef.address;
  }
  get addressObj(){
    return this.addressDef.val;
  }
  get countyState(){
    return this.addressDef?.countyState;
  }
  set scores(scores){
    this.treeVals = {scores};
  }
  get scores(){
    if (!this.dataTree.scores) {
      let scoredLocations = this.getters.itemVal('quote.scoredLocations') || [];
      let addrString = ({street1, zipcode}) => `${(street1 || '').toUpperCase()}${zipcode}`;
      let scores = scoredLocations.find(sl =>
        addrString(sl) === addrString(this.addressObj)
      );

      if (scores) {
        scores.locationId = this.dataTree.locationId;
        this.scores = scores;
      }
    }
    return this.dataTree.scores;
  }
}


export class BuildingTypeDef extends EntityDef{
  constructor(args) {
    super(args);
    this.isBuilding = true;
    this._selectableChildren = false;
  }

  get locationNum(){
    return this.li + 1;
  }
  get buildingNum(){
    return this.bi + 1;
  }
  get name() {
    return this.dataTree?.name;
  }
  get index(){
    return this.bi;
  }
  get locChain(){
    return `locations.location-${this.locationNum}`;
  }
  get isPseudo(){
    return this.dataTree._isPseudo;
  }
  set isPseudo(flag){
    let _isPseudo = flag;
    if (!_isPseudo){
      this.treeVals = {
        bClass: null, floorCt: null, roofType: null,
        buildingLimit: null, totalBldgSqFt: null, yearBuilt: null,
        _isPseudo
      };
      if (this.index === 0) {
        this.treeVals = {
          name: 'Building Type 1'
        };
      }
    } else {
      this.selected = false;
      this.treeVals = {
        name: 'Outdoor Property Only', _isPseudo
      };
    }
  }
  get isClone(){
    try {
      if (!this._children?.length || isType.string(!this.dataTree.externalId)){
        return false;
      }
      return this.dataTree?.externalId?.startsWith('clone::');
    }catch(ex){
      return false;
    }
  }
  locationItem(chain){
    //debugger;
    let l = this;
    while (!l.isLocation && l['parent']){
      l = l.parent;
    }
    return getChainedVal(chain, l.dataTree);
  }
  static create(args){

    let btd = new BuildingTypeDef(args);
    if (args.name){
      btd.treeVals = {name: args.name};
    }
    if (btd.isClone){
      btd.treeVals = {name: btd.label};
    }
    return btd;
  }
}

export class LocationManager extends EntityDef{
  constructor(args) {
    super(args);
  }
  initChildren(){
    const setChildVals = (c, i) => {
      c.child('name').val = `Location ${i + 1}`;
      c.li = i;
      c.i = i;
      c.num = i + 1;
      c.treeVals = {
        num: c.num
      };
      c.addressDef.treeVals = {isPrimary: i === 0};
      /*c.child('num').val = c.num;

      c.child('isPrimary').val = !i;*/
      c.setKey(`location-${c.num}`);
    };
    this.children.forEach(setChildVals);
  }

  addLocation(loc){

    let li = this.children.filter(c => c.isLocation).length;
    let childLocation = LocationDef.add(li, loc);
    childLocation.parent = this;
    let existing = this.child(childLocation.key);
    if (existing){
      console.log(`existing location guids (${existing.guid}:${childLocation.guid})`, {existing, childLocation});
    }
    this.addChild(childLocation);
    this.initChildren();
    return childLocation;
  }
  removeLocation(key) {

    this.removeChild(key);
    this.initChildren();
  }
  reset(hard){
    if(hard === true){
      //prune all descendants
      this._children.forEach(l => l._children.splice(0, l._children.length));
      this._children.splice(0, this._children.length);
    }
  }
}

const mimeTypeByExtension = {
  apng: 'image/apng',
  avif: 'image/avif',
  gif: 'image/gif',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  svg: 'image/svg+xml',
  webp: 'image/webp',
  pdf: 'application/pdf',
  gz: 'application/gzip',
  zip: 'application/zip',
  rtf: 'application/rtf',
  csv: 'text/csv',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  mp3: 'audio/mpeg',
  mp4: 'video/mp4',
  mpeg: 'video/mpeg',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  tar: 'application/x-tar',
  txt: 'text/plain'
};
export const popChain = chain => {
  if (isType.object(chain) && chain.chain){
    chain = chain.chain;
  }
  if (!isType.string(chain)){
    console.warn({popChain_error: 'chain not a string'});
    return '';
  }
  let splitChain = chain.split('.');
  splitChain.pop();
  return splitChain.join('.');
};
