<template>
  <b-container fluid="true" class="portal-home">
    <b-row class="h-100">
      <b-col cols="4" offset="1" class="pt100">
        <div style="padding-left: 10%">
          <div class="d-flex mb-5">
            <div style="height: 90px; width: 111px">
              <logo />
            </div>
            <div style="height: 90px">
              <align-middle>
                <h1 class="align-middle">BROKER PORTAL</h1>
              </align-middle>
            </div>
          </div>
          <p style="margin-right: 20%">
            Simple. Intuitive. Efficient.
          </p>
          <p style="margin-right: 20%">
            We understand that time is as precious as money.
            That’s why we take steps to protect both at every stage of our innovative, fast, and intuitive insurance buying experience.
            In just minutes, you can generate a bindable policy. Waiting for weeks and stacks of paperwork are officially obsolete!
          </p>
        </div>
      </b-col>
      <b-col cols="7" style="padding: 0px">
        <div class="page-mask">
          <div class="page-corner">

            <ul class="menu">
              <li v-for="action in actions" :key="action.text" :class="{muted:saving}">
                <router-link :to="saving? '/' : action.to" class="d-flex"
                       @mousedown="action.to==='/quote' ? clickStreamEvent(portalEvents.QuoteStart, 'HomeMenu') : ()=>{}">
                  <align-middle class="flex-fill">
                    <div>

                      <h3 class="flex-fill">{{ action.text }}</h3>
                      <p v-if="action.desc" class="small m-0 mt-1">
                        {{ action.desc }}
                      </p>
                    </div>
                  </align-middle>
                  <align-middle>
                    <i class="fas fa-arrow-right"></i>
                  </align-middle>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { lioTheme } from '@/lib/lioTheme';
import Logo from '@/components/svgs/logo';
import AlignMiddle from '@/components/util/align-middle';
import {portalEvents} from '@/lib/mix';
import {mapState} from 'vuex';

export default {
  data: () => {
    return {
      portalEvents,
      actions: [
        {
          text: 'get a quote',
          desc: null,
          to: '/quote'
        },
        {
          text: 'quotes in process',
          desc:
            'Find incomplete quote submissions, open quote Proposals, and any quotes referred to underwriting here!',
          to: `/quotes/`
        },
        {
          text: 'policies',
          desc: 'Find active policies, open binding conditions, and canceled/expired policies here!',
          to: `/policies`
        },
        {
          text: 'submit a claim',
          desc: 'Begin claim workflow',
          to: `/submitclaim`
        }
      ],
      bg: lioTheme.lightGray
    };
  },
  name: 'Home',
  components: { AlignMiddle, Logo},
  computed: {
    ...mapState('getQuote', ['saving'])
  },
  mounted() {
    document.title = `Home - LIO Insurance`;
  }
};
</script>
<style lang="scss">
@use 'sass:color';
@import "../assets/scss/variables";
.portal-home {
  height: 100%;
  .page-mask {
    width: 100%;
    overflow: hidden;
    padding-bottom: 80px;
    position: relative;
    height: 808px;
    .page-corner {
      position: absolute;
      margin-left: 10%;
      background-color: white;
      height: 777px;
      width: 90%;
      border-bottom-left-radius: 96px;
      box-shadow: 0 10px 30px $gray;
      .menu {
        margin: 22% 30% 0% 12%;
        padding: 0;
        list-style: none;
        li {
          height: 88px;
          &.muted{
            a:hover{
              background-color: $gray;
            }
          }
          a {
            display: block;
            height: 100%;
            padding: 0 40px;
            &:hover {
              text-decoration: none;
              h3 {
                text-decoration: underline;
              }
              p {
                display: block;
              }
            }

            &:hover,
            &.active {
              background-color: $teal;
              box-shadow: 0 5px 10px $gray;
            }

            h3 {
              color: color.scale($teal, $lightness: -75%) !important;
              margin: 0;
              font-weight: 900;
            }
            p {
              display: none;
            }
            i {
              margin: 5px -12px 0 22px;
            }
          }
          border-bottom: solid 1px $gray;
        }
      }
    }
  }
}
</style>
