
import {lioTheme} from '@/lib/lioTheme';
import {isType} from '@/lib/mytype';
import {camelCased, chunkArray, cleanseParams, mmddyyyy, titleCased, parseUrl} from '@/lib/util';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import {progressModal, simpleModal} from '@/lib/modal-service';
import eventbus from '@/lib/eventbus';
import fields from '@/lib/fields/fields';
import {getCircularReplacer} from '@/lib/fields/field-typedefs';
import {decompressBuildings} from '@/lib/hydration-io';
import {buildingFields} from '@/lib/fields/building-data';
import {apiMap} from '@/slayer/apimap';
import {lookupError} from '@/lib/error';

export const svgMix = {
  data: () => {
    return {
      defaults: {
        h: 50,
        w: 50,
        color: lioTheme.blue,
        stroke: lioTheme.blue,
        fill: lioTheme.blue
      }

    };
  },
  computed: {
    layout(){
      let h = Number(this.p('h'));
      let w = Number(this.p('w'));
      let css = {
        height: this.px(h),
        width: this.px(w)
      };
      if (this.css){
        css = Object.assign(css, this.css);
      }
      return css;
    }
  },
  props: ['h', 'w', 'color', 'css', 'stroke', 'fill']
};
export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});
export const portalEvents = {
  Login: 'Login', //added
  Logout: 'Logout',
  QuoteClose: 'QuoteClose', //added
  QuoteOpen: 'QuoteOpen',
  QuoteStart: 'QuoteStart',
  QuoteScope: 'QuoteScope', //?
  QuoteEligibility: 'QuoteEligibility', //?
  QuoteInitiate: 'QuoteInitiate', //?
  QuoteUpdate: 'QuoteUpdate',
  QuoteRate: 'QuoteRate',
  QuoteBind: 'QuoteBind',
  QuoteCancel: 'QuoteCancel',
  QuoteMessage: 'QuoteMessage',
  QuoteActivate: 'QuoteActivate',
  PolicyOpen: 'PolicyOpen'
};


export const blanketMix = {
  data: () => {
    return {
      buildingData: null,
      loadedBlanketIdsByType: {
        //bppIndex: [],
        bldIndex: []
      },
      deletedBlanketsIdsByType: {
        /*bppIndex: [],*/
        bldIndex: []
      }
    };
  },
  computed: {
    hasAnyBlanketData(){
      let del = this.deletedBlanketsIdsByType;

      return this.buildingData !== null || /*del.bppIndex.length ||*/ del.bldIndex.length;
    },
    allBuildings(){
      return this.locations.flatMap((loc, li) => {
        return this.buildings.filter(b => !b.isPseudo && b.li === li).map(b => {
          let { identicalCt, name, identicalBuildings } = b.dataTree;
          identicalCt = Number(identicalCt) || 1;
          let item = {
            loc, name, li, identicalCt,
            bi: b.bi,
            bppIndex: null,
            bldIndex: null
          };
          let parentId = identicalBuildings?.[0]?.buildingId;
          return [...Array(identicalCt).keys()].map(ord => {
            let itm = {...item};
            itm.parentName = item.name;
            itm.name = `${item.name} (${ord + 1})`;
            itm.parentId = parentId;
            itm.buildingId = identicalBuildings?.[ord]?.buildingId;
            itm.bppBlanketId = identicalBuildings?.[ord]?.bppBlanketId;
            itm.buildingBlanketId = identicalBuildings?.[ord]?.buildingBlanketId;
            itm.cloneNum = ord;
            return itm;
          });
        });
      }).flat(1); // flatten out cloned building arrays
    },
    availBuildings(){
      return blanketType => this.buildingData?.filter(b => b[blanketType] !== null) || [];
    },
    blanketLength(){
      return blanketType => Math.max(0, this.blanketList(blanketType).length);
    },
    blanketList(){

      return type => {
        const blanketTotal = row => {
          let total = 0;
          let child = type === 'bppIndex' ? 'bizPersonalProp' : 'buildingLimit';
          row.buildingIds.filter(id => !!id).forEach(id => {
            let b = this.buildings.find(b =>
              b.dataTree.buildingId === id ||
              b.dataTree.identicalBuildings.find(({buildingId}) => buildingId === id)
            );
            let amt = this.itemVal(`${b.chain}.${child}`);
            if (amt){
              total += Number(amt);
            }
          });
          return total;
        };
        let rows = {};
        this.availBuildings(type).forEach(b => {
          if (!rows[b[type]]){
            rows[b[type]] = {
              i: b[type],
              buildingIds: [],
              blanket: b[type] + 1,
              locations: [],
              buildings: [],
              merge: []
            };
          }
          let row = rows[b[type]];
          let locs = rows[b[type]].locations;
          row.buildings.push(b.name);
          row.buildingIds.push(b.buildingId);
          row.merge.push(locs[locs.length - 1] === b.loc.name);
          row.locations.push(b.loc.name);
          row.total = blanketTotal(row);
        });
        /*if (Object.keys(rows).length) {
          console.log({rows, type});
        }*/
        return Object.values(rows);
      };
    }
  },
  methods: {
    saveBlankets(showToast = true){
      showToast = true;//always for now
      const blds = this.blanketList('bldIndex');
      const locations = Object.values(this.quoteData.locations);
      const buildings = decompressBuildings(
        this.buildings,
        buildingFields.filter(f => f.group === 'coverages').map(f => f.key),
        locations
      );
      const blanketedBuildingsById = {};
      const bTypeMapper = (bItem, i, bldFieldKey, blanketType, pfx) => {
        const blanketId = this.loadedBlanketIdsByType[`${pfx}Index`][i] ?? `${pfx}Blanket${i}`;
        return {
          blanketId,
          _new: blanketId.includes('Blanket'),
          blanketType,
          buildings: bItem.buildingIds.flatMap((id, index) => {
            const b = buildings.find(bld => bld.buildingId === id);
            if (b._delete) {
              // Skip any buildings that are pending deletion
              return [];
            }
            // store blanketId by building in a deduped map of buildings
            if (!blanketedBuildingsById[id]) {
              blanketedBuildingsById[id] = b;
              // Clear previous references in case of removal
              delete blanketedBuildingsById[id].bppBlanketId;
              delete blanketedBuildingsById[id].buildingBlanketId;
            }
            blanketedBuildingsById[id] = blanketedBuildingsById[id] ?? b;
            blanketedBuildingsById[id][pfx === 'bpp' ? 'bppBlanketId' : 'buildingBlanketId'] = blanketId;
            // return the building details that the blanket entity cares about
            return [{
              locationName: bItem.locations[index],
              buildingName: bItem.buildings[index],
              limit: Number(b ? b[bldFieldKey] : 0)
            }];
          })
        };
      };
      // Gather blanket data for API with side effect of tracking blanketed buildings (blanketedBuildingsById)
      const bldBlankets = blds.map((item, i) => bTypeMapper(item, i, 'buildingLimit', 1, 'bld'));

      // For any building with no blanket, ensure its blanket references are cleared unless deleted
      buildings.forEach(b => {
        if (!b._delete && !blanketedBuildingsById[b.buildingId] && (b.bppBlanketId || b.buildingBlanketId)) {
          blanketedBuildingsById[b.buildingId] = b;
          delete blanketedBuildingsById[b.buildingId].bppBlanketId;
          delete blanketedBuildingsById[b.buildingId].buildingBlanketId;
        }
      });
      const deleted = Object.values(this.deletedBlanketsIdsByType).flatMap(deletedBlanketIdList => {
        return deletedBlanketIdList.map(id => ({
          blanketId: id,
          _delete: true,
          buildings: []
        }));
      });
      // Any loaded blanket with no remaining buildings needs to be explicitly deleted

      this.loadedBlanketIdsByType.bldIndex.forEach(loadedBldBlanketId => {
        if (!bldBlankets.some(selectedBlanket => selectedBlanket.blanketId === loadedBldBlanketId)) {
          deleted.push({
            blanketId: loadedBldBlanketId,
            _delete: true,
            buildings: []
          });
        }
      });
      const blankets = [...bldBlankets, ...deleted];
      if (!blankets.length){
        return Promise.resolve(null);
      }

      let { quoteId, policyId, policyCFId, policyCommercialCFId, jurisdictionCFId } = this.quoteData.quote;
      const params = {
        quoteId, policyId, policyCFId, policyCommercialCFId,
        jurisdictionCFId, blankets,
        blanketedBuildings: Object.values(blanketedBuildingsById)
      };
      return this.oneShield('updateBlanketCoverages', params, {savingFlag: true, toast: showToast ? 'Updating Blanket Coverages' : null});
    },
    rehydrateBlankets(){
      this.buildingData = this.allBuildings;
      let blankets = this.itemVal('quote.blanketCoverages');
      const emptyLists = parent => Object.values(parent)
        .forEach(list => list.splice(0, list.length));
      emptyLists(this.deletedBlanketsIdsByType);
      emptyLists(this.loadedBlanketIdsByType);
      if (blankets && !Array.isArray(blankets)){
        blankets = [blankets];
      }

      blankets = blankets.filter(b => Number(b.type) !== 2);//no bpp (moved to propCov tab)
      const idKeyByType = {1: 'buildingBlanketId', 2: 'bppBlanketId'};

      blankets.forEach(bl => {
        const blanketType = `bldIndex`;
        // Store the blanketId in an array of blankets by type
        this.loadedBlanketIdsByType[blanketType].push(bl.blanketId);
        this.buildingData.forEach(b => {
          if(b[idKeyByType[bl.type]] === bl.blanketId) {
            // reference the index of the blanketId list for each building
            b[blanketType] = this.loadedBlanketIdsByType[blanketType].length - 1;
          }
        });
      });
    }
  },
  mounted() {
    this.rehydrateBlankets();
  },
  watch: {
    allBuildings(newBuildings, oldBuildings) {
      if (newBuildings.length !== oldBuildings.length) {
        // If we have new/deleted buildings ensure we refresh blankets
        this.rehydrateBlankets();
      }
    }
  }
};

export const defaultMix = {
  //directives: {resize},
  data: () => {
    return {
      lioTheme,
      defaults: {
        bg: '#fff'
      },
      bouncedOp: {}
    };
  },

  computed: {
    ...mapState(['local', 'isAuthenticated', 'osUser', 'oktaUser', 'debugOptions', 'storeInit', 'oktaToken']),
    ...mapGetters('getQuote', ['itemVal', 'dataVersion']),
    ...mapGetters(['validSession']),
    cleanText(){
      return text => text.replaceAll('amp;amp;', '');
    },
    AccessToken(){
      return this.oktaToken.accessToken;
    },
    rootPartnerId(){
      return `${this.oktaUser?.partnerId}`;
    },
    apiEnv(){
      return this.debugOptions['apiEnv'];
    },
    validationEnabled(){
      return this.debugOptions['validate'];
    },
    debug(){
      return this.debugOptions['showDebugger'];
    },
    isAdmin(){
      if (this.local && this.isType.bool(this.debugOptions.admin)){
        return this.debugOptions.admin;
      }
      return this.oktaUser?.partnerId === 0;
    },
    sessionId(){return this.osUser.sessionId;},
    ppx(){return s => this.px(this.p(s));},
    pcf(){return v => `${v * 100}%`;},
    px() {
      return v => `${v}px`;
    },
    p() {
      return p => {
        let v = (isType.nullOrUndef(this[camelCased(p)]) ? this.defaults[p] : this[camelCased(p)]);
        return v;
      };
    },
    isType(){
      return isType;
    },
    opt() {
      return p => isType.nullOrUndef(this.options[p]) ? this.defaults[p] : this.options[p];
    },
    currency(){
      return (n, sign, zeros = true) => {
        let s = formatter.format(n);
        s = sign ? s : s.substring(1);
        return zeros ? s : s.replace('.00', '');
      };
    },
    titleCased(){
      return s => titleCased(s).replaceAll('-', ' ');
    },
    clone(){
      return o => JSON.parse(JSON.stringify(o, getCircularReplacer()));
    },
    chunkArray(){
      return (a, chunkSize) => chunkArray(a, chunkSize);
    },
    mmddyyyy(){
      return d => d ? mmddyyyy(d) : '';
    },
    quoteLocked(){
      return !this.isAdmin && this.itemVal('quote.quoteLocked') === true;
    }
  },

  methods: {
    ...mapMutations(['setAny', 'setRoot']),
    ...mapMutations('getQuote', ['setQuoteStoreAny', 'rehydrate']),
    ...mapActions(['init']),

    delayFn(fn, d = 1){
      setTimeout(() => {
        this.$nextTick(fn);
      }, d);
    },
    msg(title, msg){
      return simpleModal(title, msg || title);
    },
    bubble(name, data){
      this.$emit(name, data);
    },

    propStore(quoteId, props){
      let vm = this;
      return new Promise(res => {
        this.init().then(() => {
          const sessionId = this.sessionId;
          let OS_TOKEN;
          const post = () => {
            eventbus.post('propStore', {
              OS_TOKEN,
              sessionId,
              props,
              objType: 'quote',
              quoteId,
              apiEnv: this.apiEnv
            }).then(res);
          };
          if (vm.AccessToken) {
            OS_TOKEN = vm.AccessToken;
            post();
          }else{
            let root = vm.$root;
            root.tokenPromise.then(token => {
              OS_TOKEN = token;
              post();
            });
          }

        });
      });
    },
    apiBouncer(args, delay = 300, callback){
      let {operation, quoteId, props, bubbleErrors} = args;
      if (this.bouncedOp[operation]){
        clearTimeout(this.bouncedOp[operation]);
      }
      this.bouncedOp[operation] = setTimeout(() => {
        this.lioApi(operation, quoteId, props, bubbleErrors).then(callback);
      }, delay);
    },
    lioApi(operation, quoteId, props, bubbleErrors = true){
      let vm = this;
      return new Promise(res => {
        this.init().then(() => {
          let url = parseUrl(apiMap[operation].api, props);
          let options = {method: apiMap[operation].method};
          const sessionId = this.sessionId;
          let OS_TOKEN;
          const post = () => {
            eventbus.post('lioApi', {
              OS_TOKEN,
              sessionId,
              url,
              props,
              options,
              quoteId,
              apiEnv: this.apiEnv
            }).then(result => {
              let {response} = result;
              if (isType.object(response) && response.error){
                response.requestPayload = {operation, url, params: props};

                if (response.error.message){
                  response.errorText = response.error.message;
                  response.hasErrors = true;
                }
                response.error = lookupError(response);
                if (bubbleErrors === true) {
                  eventbus.$emit('apiError', response);
                }
              }
              else{
                result.requestPayload = {operation, url, params: props};
              }
              if (operation !== 'listSystemAlerts') {
                console.log({[operation]: result});
              }
              res(result);
            });
          };
          if (vm.AccessToken) {
            OS_TOKEN = vm.AccessToken;
            post();
          }else{
            let root = vm.$root;
            root.tokenPromise.then(token => {
              OS_TOKEN = token;
              post();
            });
          }
        });
      });
    },
    oneShield(operation, params = {},
      { dontDie = false, savingFlag = false, toast = null, progress = null, log = false, parseList = false, rehydrate = false } = {}){
      if (operation.startsWith('update')){
        let idParam = apiMap[operation]?.gate;
        let id = idParam ? params[idParam] : null;
        if (this.itemVal('quote.otherFlag')){
          params.otherFlag = this.itemVal('quote.otherFlag');
        }
        this.clickStreamEvent(portalEvents.QuoteUpdate, operation, id);
      }
      let operationEventMap = {
        rateQuote: portalEvents.QuoteRate,
        bindPolicy: portalEvents.QuoteBind,
        cancelQuote: portalEvents.QuoteCancel,
        sendUnderwriterMessage: portalEvents.QuoteMessage,
        reviveQuote: portalEvents.QuoteActivate
      };
      if(operationEventMap[operation]){
        let content = operation === 'cancelQuote' ? params.reason : undefined;
        this.clickStreamEvent(operationEventMap[operation], null, params.quoteId, content);
      }
      let propPromise;
      if (params.propertyBag && params.quoteId){
        propPromise = this.propStore(params.quoteId, params.propertyBag);
      }else if (operation === 'getQuoteDetails'){
        propPromise = this.propStore(params.id);
      }
      return new Promise(resolve => {
        let busPromiseResolved = result => {
          this.setRoot({sessionStart: Date.now()});

          if (log && result.response){
            console.log({[operation]: result.response});
          }
          if (savingFlag){
            this.setQuoteStoreAny({saving: false});
          }
          if (toast) {
            eventbus.$emit('progressToast', false);
          }
          else if (progress){
            progressModal(false);
          }
          if (parseList){
            result.response.list = result.response?.fields?.map(f => f.val)[0];

          }
          if (rehydrate && result?.response?.hasErrors === false){

            if (rehydrate === 'force'){
              let {fields} = result.response;
              this.rehydrate({fields, force: true});
            }
            else {
              this.rehydrate(result.response);
            }
          }
          if (propPromise && result.response){
            propPromise.then(props => {
              result.response.propertyBag = props.response;
              console.log({propertyBagFullResponse: result.response});
              resolve(result);
            });
          }else {
            resolve(result);
          }
        };
        let beforeCall = () => {
          if (savingFlag){
            this.setQuoteStoreAny({saving: true});
          }
          if (toast) {
            eventbus.$emit('progressToast', toast);
          }
          else if (progress){
            progressModal(...progress);
          }

        };
        const makePost = () => {
          params = cleanseParams(params);
          let args = {
            operation,
            sessionId: this.sessionId,
            OS_TOKEN: this.AccessToken,
            apiEnv: this.apiEnv,
            ...params
          };
          beforeCall();
          eventbus.post('oneShield', args).then(busPromiseResolved);
        };
        if (dontDie || this.validSession()) {
          makePost();
        } else {
          this.$root.tokenPromise.then(() => {
            makePost();
          });
        }
      });
    },
    clickStreamEvent(type, subType, contextId, content){
      let vm = this;

      this.init().then(() => {
        const timestamp = Date.now();
        const sessionId = this.sessionId;
        let OS_TOKEN;
        const post = () => {
          eventbus.post('clickStream', {
            OS_TOKEN,
            type, subType,
            timestamp, sessionId,
            contextId, content,
            apiEnv: this.apiEnv
          });
        };
        if (vm.AccessToken) {
          OS_TOKEN = vm.AccessToken;
          post();
        }else{
          let root = vm.$root;
          root.tokenPromise.then(token => {
            OS_TOKEN = token;
            post();
          });
        }

      });
    }
  },
  mounted() {
    if(this.bg) {
      this.setRoot({appBg: this.bg});
    }
  }
};

export const buildingsMix = {
  data: () => {
    return {

      selectionFields: []
    };
  },
  computed: {
    ...mapGetters('getQuote', ['itemVal', 'storeFields', 'locations', 'buildings']),
    ...mapState('getQuote', ['buildingFilter']),
    selectedLocations() {
      return this.locations.filter(l => l._selected);
    },
    vals() {
      let vals = this.bFields.map(f => f ? f.val : null);
      return vals.length ? vals : null;
    },
    bDef(){
      return key => this.bFields.find(f => f && f.key === key);
    },
    bFields() {
      let stored = this.storeFields('buildingFields');
      let flds = fields.buildingFields.map(fld => stored.find(s => s.key === fld.key)).filter(f => !!f);
      //console.log({stored, flds});
      if (this.buildingFilter){
        return flds.filter(f => f.group === this.buildingFilter);
      }

      return flds;
    },
    selection() {
      return this.buildings.filter(b => !b.isPseudo && b.selected === true);
    },
    bDefs(){
      return this.bFields;
    }
  }
};

export const buildingForm = {
  computed: {
    ...mapState('getQuote', ['locationIndex', 'buildingTypeIndex', 'buildingFieldsValid', 'buildingFilter']),
    ...mapGetters('getQuote', ['locations', 'buildings', 'itemVal']),
    lComplete(){
      return i => {
        let {valid, total} = this.aggregateFieldState(i);
        return valid === total;
      };
    },
    allComplete(){
      return this.locations.every((l, li) => this.lComplete(li));
    },
    bComplete(){
      return (bldg) => {
        let group = this.buildingFilter;
        let all = this.bFields(bldg, group);
        let val = this.validFields(bldg, group);
        return all ? all.length === val : false;
      };
    },
    formState() {
      return (bldg, asString = true) => {
        let valid = this.validFields(bldg);
        let total = this.bFields(bldg).length;

        return asString ? `${valid}/${total}` :
          {valid, total, variant: valid < total ? 'gray' : 'success'};
      };
    },
    aggregateFieldState() {
      return (lInd = false, grouped = false) => {
        let group = grouped ? this.buildingFilter : null;
        let valid = 0;
        let total = 0;

        let locs = lInd === false ? this.locations : [this.locations[lInd]];
        locs.forEach(({buildingList}) => {
          if (!buildingList || !buildingList.length){
            total++;
          }
          buildingList.forEach(b => {
            valid += this.validFields(b, group);
            total += this.bFields(b, group).length;
          });
        });
        const variant = valid === total ? 'success' : total === 1 ? 'danger' : 'gray';
        return {valid, total, variant};
      };
    },
    bCount() {
      return li => {
        //if (!this.selection.length) return 0;
        return this.locations && this.locations.length ?
          this.locations[li].buildingList.length :
          null;
      };
    },
    bFields(){
      return (bldg, group) => {
        return bldg.children?.filter(c => c && !!c.isRequired && (!group || c.group === group));
      };
    },

    validFields(){
      return (bldg, group) => {
        let valFields = this.bFields(bldg, group)?.filter(f => f.isRequired && f.isValid);
        return valFields.length;
      };
    }
  },
  watch: {

    allComplete(v){
      this.setAny({buildingFieldsValid: v});
      this.setAny({[`${this.buildingFilter}Valid`]: v});
    }
  }
};

