<template>
  <div class="d-flex w-100">
    <div class="flex-fill mr-2">
      <v-autocomplete ref="autocomplete"
                      type="search" @input="selectItem" :autofocus="true"
                      :items="filteredList" :hide-no-data="true" v-model="selectedValue" :no-filter="false"
                      @update:search-input="textUpdate" :validate-on-blur="false"
      >
        <template v-slot:item="{ item }" v-if="subTitle">
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
            <v-list-item-subtitle v-text="item[subTitle] || '(Create New...)'"></v-list-item-subtitle>
          </v-list-item-content>

        </template>
      </v-autocomplete>

    </div>
    <b-button v-if="clearButton"
              size="md" @click="clearInput" style="height:36px;width:42px" v-b-tooltip.hover="`Clear Selection`">
      <i class="fas fa-times-circle"></i>
    </b-button>
  </div>
</template>

<script>
import {sortByKey} from '@/lib/util';
import {isType} from '@/lib/mytype';
import fieldmix from '@/components/fields/fieldmix';
import {quotemix} from '@/lib/quotemix';
import localForage from 'localforage';

export default {
  data: () => {
    return {
      selection: null,
      list: [],
      selectedValue: null,
      searchText: null,
      subTitle: false,
      clearButton: true,
      retryOnce: false,
      lazySearch: null
    };
  },
  computed: {
    filteredList() {

      let noSearch = !this.searchText || !this.searchText.length;
      let {vals: {filter}} = this.def;
      let filterKey, filterVal;
      if (filter){
        let [fKey, fVal] = Object.entries(filter)[0];
        filterVal = this.itemVal(fVal);
        filterKey = fKey;
      }
      return this.list.filter(item => {
        let include = item && item.id !== '0' &&
            (noSearch || item.text?.toLowerCase().includes(this.searchText?.toLowerCase()));
        if (include && filterKey && item.value !== null){
          include = `${item[filterKey]}` === `${filterVal}`;
        }
        return include;
      });
    },
    apiOpts() {
      return this.def?.vals ?? {};
    }
  },
  methods: {
    clearInput() {
      this.selection = this.selectedValue = null;
    },
    selectItem(v) {
      this.selection = this.list.find(item => item.value === v)/*?? {text:null,value:null,subTitle:null}*/;
      this.def.val = this.selection;
      this.selectedValue = this.selection?.value;
      if (this.def.onSelect) {
        this[this.def.onSelect](this.selection, this, this.def);
      }
    },
    setLastItem(text) {
      this.list.splice(this.list.length - 1, 1, {text, value: null});
    },
    lazyLookup(val) {
      if (!val) {
        return;
      }
      const listCallback = list => {
        if (list[0] && !!list[0].subTitle) {
          this.subTitle = 'subTitle';
        }
        this.list = list ?? [];
      };
      if (this.apiOpts.debounce) {
        this[this.apiOpts.lazy](val, this.apiOpts.debounce, listCallback);
      } else {
        this[this.apiOpts.lazy](val).then(listCallback);
      }
    },
    textUpdate(val) {
      if (this.apiOpts?.lazy) {
        return this.lazyLookup(val);
      }
      this.searchText = val === '' ? null : val;
      let id = this.selection?.value;
      this.delayFn(() => {
        let selectedText = this.list.find(({value}) => value === id)?.text;
        if (selectedText === val) {
          val = '';
        } else {
          this.selectItem(null);
        }
        this.setLastItem(val);
      });
    },
    loadList(list){
      let {def: {vals}} = this;

      if ((Array.isArray(list) && list.length && isType.object(vals) && vals.api)){
        let { map, concat,  sort, text, value, subTitle} = vals;

          list = list.map((item) => {
            try {
              if (map) {
                let mappedEntries = map.filter(itm => isType.string(itm))
                    .map(key => [key, item[key]]);
                let mappedItem = mappedEntries.length ? Object.fromEntries(mappedEntries) : {};
                if (mappedItem.state){
                  item.stateKey = item.state;
                  item.state = this.findState(mappedItem.state).code;
                }

              }
              if (concat){
                concat.forEach(obj => {
                  let [concatKey, {keys, join}] = Object.entries(obj)[0];
                  item[concatKey] = keys.map(k => item[k]).join(join);
                });
              }
              return item;
            } catch (ex) {
              console.warn({item});
              return item;
            }
          });
          if (sort) {
            list = sortByKey(list, sort).map((item) => {
              let itm = {
                text: item[text], value: item[value], ...item
              };
              if (subTitle) {
                this.subTitle = subTitle;
                //itm.subTitle = item[subTitle];
              }
              return itm;
            });
          }
          this.list = list;
        } else if (!this.retryOnce) {
          this.retryOnce = true;
          this.delayFn(() => this.initializeList());
        }

    },
    initializeList() {
      let {def: {vals}} = this;

      if (isType.object(vals) && vals.api) {
        let {api, params, cached} = vals;
        if (cached){
          localForage.getItem(api).then(list => this.loadList(list));
        }else {
          this.oneShield(api, params, {parseList: true}).then(({response: {list}}) => {
            this.loadList(list);
          });
        }
      }
    }
  },
  mounted() {
    if (!this.def.lazy) {
      this.initializeList();
      this.delayFn(() => {
        if (!this.list.length && !this.retryOnce) {
          this.initializeList();
          this.retryOnce = true;
        }
      }, 1500);
    }
    //console.log({customer:this.storeFields('customer')})
  },
  watch: {
    selection(item) {
      this.def.val = item;
      this.updateField({chain: this.def.chain, val: item});
      let map = this.def.options?.mapFields;
      //debugger;
      if (!item) {
        item = {};
      }
      if (item.value === null) {
        item.text = this.searchText;
      }
      if (map) {
        Object.entries(map).forEach(([key, chain]) => {
          this.updateField({chain, val: item[key]});
        });
      }

    }
  },
  mixins: [fieldmix, quotemix],
  name: 'fieldtype_autocomplete',
  props: ['def']
};
</script>

<style scoped>

</style>
