<template>
<div>


  <div class="d-flex mb-3 mt-3 pl-3">
    <div style="width:50%">
      <field-column :defs="storeFields('propertyCoverages').filter(f => f.hasTag('col1'))" />
    </div>
    <div style="width:50%">
      <field-column :defs="storeFields('propertyCoverages').filter(f => f.hasTag('col2'))" />
    </div>
  </div>

  <div class="pb-3 pl-3 w-100" style="min-width:800px;max-width: 1100px" v-if="itemVal('windstorm.windstormCoveredState')">
    <field-column label-w="35%" field-w="65%" :defs="storeFields('windstorm')"/>
  </div>
</div>
</template>

<script>
import {detailsTab, quotemix} from '@/lib/quotemix';

import eventbus from '@/lib/eventbus';
import FieldColumn from '@/components/fields/field-column';
import {clone} from '@/lib/util';
import {isType} from '@/lib/mytype';
import deepEqual from 'deep-equal';
export default {
  data: () => {
    return {
      hasInit: false,
      apiSaveFunction: 'updatePropertyCoverages',
      quoteFields: ['quoteId', 'policyId', 'policyCommercialGLId', 'policyCFId'],
      tabName: 'propertyCoverages'

    };
  },
  components: {FieldColumn },
  computed: {
    floodLimit(){
      return this.itemVal('propertyCoverages.floodLimit');
    },
    additionalParams(){
      let {locationId, cfLocationId, identicalBuildings} = this.buildings[0]?.dataTree ?? {};
      let {propertySubjectId, cfBuildingCoverageId} = identicalBuildings[0];
      return {
        locationId,
        cfLocationId,
        propertySubjectId,
        cfBuildingId: cfBuildingCoverageId,
        bppBlanketId: this.itemVal('propertyCoverages.bppBlanketId')
      };
    },
    dirty(){
      return this.propCovDirty || this.wsDirty || this.itemFromChain('propertyCoverages.floodConstructionType').dirty;
    },
    propCovDirty(){
      let apiEntries = Object.entries(clone(this.apiParams))
          .map(([k, v]) => {
            if (isType.date(v)){
              v = v.toJSON();
            }
            return [k, v];
          });

      return !deepEqual(this.originalVals, Object.fromEntries(apiEntries));
    },
    wsDirty(){
      return this.storeFields('windstorm').some(f => f.dirty);
    }
  },
  methods: {
    save(){
      let promises = [];
      if (this.wsDirty){
        const keyPrefix = k => k.includes('_') ? k.split('_')[0] : k;
        const wsFields = this.storeFields('windstorm');
        let keyVals = wsFields.filter(f => !this.isType.nullOrUndef(f.val) && f._active !== false)
            .map(f => {
              let v = f.val;
              f.setPristine();
              return [keyPrefix(f.key), v];
            });
        let windstormProperties = Object.fromEntries(keyVals);
        windstormProperties.windstormCoveredState = this.findState().code;
        let wsPromise = this.propStore(this.itemVal('quote.quoteId'), {windstormProperties});
        promises.push(wsPromise);
        wsPromise.then(({response}) => {
          if (!response.hasErrors){
            wsFields[0].parent.setPristine();
          }
        });
      }
      if (this.itemFromChain('propertyCoverages.floodConstructionType').dirty){
        let props = Object.fromEntries(
          ['floodConstructionType', 'floodFloorLevel'].map(key => [key, this.itemVal(`propertyCoverages.${key}`)])
        );

        promises.push(this.propStore(this.itemVal('quote.quoteId'), props));
      }
      if (this.propCovDirty) {

        let options = {savingFlag: true, toast: `Saving ${this.titleCased(this.tabName)}`, rehydrate: true};

        promises.push(
          this.oneShield(this.apiSaveFunction, this.apiParams, options)
        );
        if (!this.apiParams.floodCoverageEnabled){
          this.storeFields('propertyCoverages').filter(f => f.key.startsWith('flood')).forEach(f => f.reset(true));
        }
      }

      return promises.length ? Promise.all(promises) : Promise.resolve('unchanged');
    },
    updateVals(){
      if (this.itemVal('propertyCoverages.floodCoverageEnabled') && !this.isAdmin){
        let limitOverride = this.itemFromChain('propertyCoverages.floodLimitOverride');
        let deductOverride = this.itemFromChain('propertyCoverages.floodUWDeduct');
        if (limitOverride.val && limitOverride.val !== this.itemVal('propertyCoverages.floodLimit')){
          this.updateField({chain: 'propertyCoverages.floodLimit', val: limitOverride.val});
        }
        if (deductOverride.val && deductOverride.val !== this.itemVal('propertyCoverages.floodDeduct')){
          this.updateField({chain: 'propertyCoverages.floodDeduct', val: deductOverride.val});
        }
      }
      this.cleanSlate();
      this.delayFn(() => this.hasInit = true);
    }
  },
  mixins: [quotemix, detailsTab],
  mounted() {
    this.updateVals();
    eventbus.$on('quoteRehydrate', this.updateVals);

  },
  watch: {
    floodLimit(){
      if (this.hasInit && this.itemFromChain('propertyCoverages.floodLimit').dirty){

        // reset field to show defaultVal
        this.itemFromChain('propertyCoverages.floodDeduct').reset();
      }
    }
  },
  name: 'property-coverages'
};
</script>
