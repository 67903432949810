<template>
  <div>
    <b-alert v-if="pctSum !== 100" :show="true" variant="danger" class="mt-2">% Share should total 100% (currently {{pctSum}})</b-alert>
    <div class="d-flex m-3">
      <div style="width:50%" >
        <field-column :defs="storeFields('reinsurance', {tag:'col1'})" label-w="60%" field-w="40%"/>
      </div>
      <div style="width:30%">

        <field-column :defs="storeFields('reinsurance', {tag:'reinsurer'})" label-w="30%" field-w="70%"/>
        <div class="mt-4 mr-4 text-center">
          <a v-if="reinsurerCount < 4" class="text-primary" @click="addReinsurer">+ Add another reinsurer</a>
        </div>
        <div class="mt-4 mr-4 text-center">
          <a v-if="reinsurerCount > 1" class="text-primary" @click="removeReinsurer">- Remove last reinsurer</a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {detailsTab, quotemix} from '@/lib/quotemix';
import {mapMutations, mapState} from 'vuex';
import FieldColumn from '@/components/fields/field-column';
import {reinsurance} from '@/lib/fields/reinsurance-data';
import eventbus from '@/lib/eventbus';

export default {
  data: () => {
    return {
      quoteFields: ['quoteId', 'policyId'],
      tabName: 'reinsurance'
    };
  },
  computed: {
    ...mapState('getQuote', ['navigationPath']),
    reinsurerFields(){
      return this.storeFields('reinsurance', {tag: 'reinsurer'});
    },
    reinsurerCount(){
      return this.reinsurerFields.length / 2;
    },
    reinsurerEntity(){
      return this.reinsurerFields[0].parent;
    },
    pctVals(){
      return this.reinsurerFields.filter(f => f.type === 'percent').map(f => f.val);
    },
    pctSum(){
      let sum = 0;
      this.pctVals.forEach(v => sum += Number(v));
      return sum;
    }
  },
  methods: {
    ...mapMutations('getQuote', ['setPristineState']),
    updatePercent(){
      let total = this.reinsurerCount;
      if (total === 1){
        this.updateField({chain: 'reinsurance.reinsurerShare_1', val: '100'});
      }
      else if (total === 2){
        this.updateField({chain: 'reinsurance.reinsurerShare_1', val: '50'});
        this.updateField({chain: 'reinsurance.reinsurerShare_2', val: '50'});
      }
      else if (total === 3){
        this.updateField({chain: 'reinsurance.reinsurerShare_1', val: '34'});
        this.updateField({chain: 'reinsurance.reinsurerShare_2', val: '33'});
        this.updateField({chain: 'reinsurance.reinsurerShare_3', val: '33'});
      }else if (total === 4){
        [1, 2, 3, 4].forEach(n =>
          this.updateField({chain: `reinsurance.reinsurerShare_${n}`, val: '25'})
        );
      }
    },
    addReinsurer(){
      let count = this.reinsurerCount;

      reinsurance.filter(f => f.tags === 'reinsurer').forEach(obj => {
        let clone = this.clone(obj);
        let [k] = clone.key.split('_');
        let i = Number(count) + 1;
        clone.key = k + '_' + i;
        clone.optional = true;
        clone.tags += ',added';
        this.reinsurerEntity.addChild(clone);
      });
      this.updatePercent();
    },
    removeReinsurer(){
      let fields = [...this.reinsurerFields];
      this.reinsurerEntity.removeChild(fields.pop());
      this.reinsurerEntity.removeChild(fields.pop());
      this.updatePercent();
    },
    save() {
      let storedFields = this.storeFields('reinsurance');
      let parent = storedFields[0].parent;
      const props = {reinsuranceProps: parent.dataTree};
      this.setQuoteStoreAny({saving: true});
      eventbus.$emit('progressToast', 'Saving Reinsurance');
      this.propStore(this.itemVal('quote.quoteId'), props).then(({response}) => {
        this.setQuoteStoreAny({saving: false});
        eventbus.$emit('progressToast', false);
        if (!response.hasErrors) {
          parent.setPristine();
          this.cleanSlate();
        }
      });
    }
  },
  name: 'reinsurance',
  components: {FieldColumn},
  mixins: [quotemix, detailsTab],
  watch: {
    pctVals(cur, prev){
      // when only 2 reinsurers, mutations to one percentage updates the other
      if (this.pctSum !== 100 && this.reinsurerCount === 2){
        let chain = 'reinsurance.reinsurerShare_';
        let val = 100;
        if (cur[0] !== prev[0]){
          val -= Number(cur[0]);
          chain += '2';
        }
        else{
          val -= Number(cur[1]);
          chain += '1';
        }
        this.updateField({chain, val});
      }
    }
  }
};

</script>


