<template>
  <b-modal
      id="miscOutdoor" size="xl" :header-class="{'show-close-x': false}"
      title="Miscellaneous Outdoor Property" @hide="cancel(true)"
      :no-close-on-backdrop="true" :no-close-on-esc="true"
      v-model="showModal">
    <div v-if="location">
      <div class="d-flex" v-if="isSave">
        <div class="w-25"></div>
        <div class="flex-fill">
          <progress-bar class="m-3">Saving</progress-bar>
        </div>
        <div class="w-25"></div>
      </div>
      <b-alert v-else-if="error.message" :show="true" variant="danger" >{{error.message}}</b-alert>
      <div v-else class="pl-4">
        <checklist-op v-if="dataVersion < 3" :location="location"/>
        <h3 class="m-0 mt-4" v-if="showCoverages">Coverage Options</h3>
        <div class="d-flex" v-if="showCoverages">
          <div style="max-width:450px">
            <field-column label-w="36%" field-w="64%" :defs="location.children.filter(c => c.hasTag('col1'))"/>
          </div>
          <div class=" pl-4 ">
            <field-column label-w="63%" field-w="37%" :defs="location.children.filter(c => c.hasTag('col2'))"/>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100 modal-buttons">
        <b-button
            :disabled="isSave" :class="{disabled:isSave}"
            variant="info" size="lg" @click="cancel()"
        >Cancel</b-button>
        <b-button
            :class="{disabled:!validFormState || isSave}"
            v-if="!error.message" :disabled="!validFormState || isSave"
            variant="primary" size="lg" @click="save()"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import {quotemix} from '@/lib/quotemix';
import rfdc from 'rfdc';
import deepEqual from 'deep-equal';
import eventbus from '@/lib/eventbus';
import {maxOSInt} from '@/lib/util';
import ProgressBar from '@/components/util/progress-bar';
import FieldColumn from '@/components/fields/field-column';
import ChecklistOp from '@/components/modal/outdoor-prop/checklist-op';
import {isType} from '@/lib/mytype';

const clone = rfdc();

export default {
  components: {ChecklistOp, FieldColumn, ProgressBar},
  data: () => {
    return {
      error: {message: null},
      saveEnabled: true,
      isSave: false,
      showModal: false,
      deletedPropertyIds: [],
      initialParams: null
    };
  },
  computed: {
    ...mapGetters('getQuote', ['dataVersion']),
    showCoverages(){
      return this.dataVersion > 2 ||
        this.location?.outdoorPropList.filter(c => c.val).length > 0;
    },
    totalCov(){
      let plants = this.location?.child('treeShrubBlanket').val ?? 0;
      let outdoor = this.location?.child('outdoorBlanketTotal').val ?? 0;
      return Number(plants) + Number(outdoor);
    },
    validFormState() {
      let loc = this.location;
      if (!loc){
        return false;
      }
      let dataVersion2ListValid = loc.outdoorPropertyList.length > 0 ? loc.outdoorPropertyList.every(op => op._delete === true) || this.totalCov > 0 : true;
      let listValid = this.dataVersion < 3 ? dataVersion2ListValid : true;
      let covValid = (!this.showCoverages) ||
        loc.children.filter(c => c.active && c.group === 'miscOutdoor').every(c => {
          return c.optional || c.isValid;
        });
      return listValid && covValid;
    },
    unsaved1190(){
      let loc = this.location;
      if (!loc){
        return false;
      }
      return !loc.outdoorPropList.filter(c => c.propertyId).length
        && loc.isOPO;

    },
    paramsToSave() {
      let {location} = this;
      if (!location) {
        return undefined;
      }
      const {quoteId, policyId, policyCFId, policyCommercialCFId, jurisdictionCFId} = this.quoteData.quote;
      const locVals = l => {
        let fields = l.children.filter(c => {
          let incl = c.group === 'miscOutdoor';
          if (incl && this.dataVersion >= 3){
            incl = !['outdoorBlanketTotal', 'treeShrubBlanket'].includes(c.key);
          }
          return incl;
        });
        return Object.fromEntries(fields.map(f => [f.key, f.val]));
      };
      return {
        quoteId, policyId, policyCFId, policyCommercialCFId,
        jurisdictionCFId,
        locations: [{
          locationId: location.dataTree.locationId,
          cfLocationId: location.dataTree.cfLocationId,
          ...locVals(location),
          outdoorProperties: location.outdoorPropertyList
        }]
      };
    },
    touchedGroups() {
      let groups = Object.fromEntries(
        this.hasChanges('buildingFields')
          .map(([chain]) => [this.itemFromChain(chain).group, 1])
      );
      return Object.keys(groups).length ? Object.keys(groups) : ['construction'];
    }
  },
  methods: {
    ...mapMutations('getQuote', ['updateField', 'removeDef']),
    checkSubject(i, row) {
      let minAmt = row[0].val === '2002' && row[1].val === '1002' ? 10001 : 0;
      row[2].range = [minAmt, maxOSInt];
    },
    cancel() {
      if (this.isSave) {
        return;
      }
      if (this.unsaved1190){
        let buildings = this.location.buildingsDef.children;
        buildings.splice(buildings.findIndex(b => b.isPseudo), 1);
      }
      if (this.location) {
        this.location.removeUnsaved();
      }
      this.$emit('closed');
      this.showModal = false;
    },
    updateHiddenTypes(){
      if (this.location && this.dataVersion >= 3){
        let plants = this.location.child('treeShrubBlanket').val ?? 0;
        let outdoor = this.location.child('outdoorBlanketTotal').val ?? 0;

        this.location.child('outdoorType2003').val = Boolean(outdoor);
        this.location.child('outdoorType2004').val = Boolean(plants);
      }
    },
    async save() {
      this.updateHiddenTypes();
      if (!this.saveEnabled) {
        return;
      }

      this.isSave = true;
      this.saveEnabled = false;
      const params = this.paramsToSave;
      const doesNeedUpdate = this.dataVersion >= 3 || !deepEqual(params, this.initialParams);
      let loc = this.location;

      if (loc && doesNeedUpdate) {
        const { response } = await this.oneShield('updateOutdoorProperties', params, { toast: 'Updating Outdoor Properties' });
        if (response.hasErrors){
          this.error = response.error;
          this.isSave = false;
          return;
        }

        let props = response.fields.find(f => f.chain === 'locations.outdoorProperties')?.val;
        // if showCoverages is true, we will not have a property in the response
        if (this.showCoverages && Array.isArray(props) && props.length) {
          let foundProps = props.filter((p) => p.locationId === loc.dataTree.locationId);
          if (foundProps.length) {
            loc.treeVals = foundProps;
            this.updateField({chain: loc.chain, foundProps});
          }else{
            let locationId = loc.dataTree.locationId;
            console.warn({missingLocationId: locationId, props, params});
          }
        }
        else if (!this.showCoverages && loc.isOPO){
          // invalidate the location by deleting the pseudo building
          loc.buildingsDef.children[0].treeVals = {_delete: true};
          console.log({deleted_1190_building: loc.buildingsDef.children[0]});
        }
        await this.saveBuildingsByGroup(this.touchedGroups);
        eventbus.$emit('progressToast', false);
      }

      this.$emit('closed');
      this.isSave = false;
      this.saveEnabled = true;
      this.showModal = false;
    }
  },
  mounted() {
    this.isSave = false;
  },
  name: 'outdoor-property',
  props: ['location', 'buildingPanel'],
  mixins: [quotemix],
  watch: {
    totalCov(){
      this.updateHiddenTypes();
    },
    location(l) {
      if (l) {
        if (!this.showModal) {
          this.initialParams = clone(this.paramsToSave);
        }

        this.showModal = true;
        this.isSave = false;
      }
    }
  }
};
</script>
<style lang="scss">
.modal-buttons .disabled{
  pointer-events: none;//ignore confusing hover interaction
}
div.outdoor-type-row{
  margin-bottom:-12px;
  margin-top:-14px;
}
</style>
