import Vue from 'vue';
import Worker from 'worker-loader!../slayer/worker';
import {clone, guid} from '@/lib/util';
import {isType} from '@/lib/mytype';
import {apiMap} from '@/slayer/apimap';
import {datadogLogs} from '@datadog/browser-logs';
let vm;
export default new Vue({
  data: () => {
    return {
      slayer: new Worker,
      promiseBroker: {},
      queue: [],
      ready: false,
      awaitingSession: false,
      sessionId: null,
      apiEnv: 'dev',
      uniq: {},
      pagingBroker: {},
      curQuoteId: null,
      activeQuoteQueue: [],
      OS_TOKEN: null,
      currentGatedReq: null
    };
  },
  computed: {
    osReady(){
      return this.sessionId && this.ready;
    },
    needsGating(){
      return data => {
        if (!this.curQuoteId){
          return false;
        }
        let op = apiMap[data.operation];
        if (!op){
          return false;
        }
        let gate = op?.gate;
        return (gate === true || data[gate] === this.curQuoteId);
      };
    }
  },
  methods: {
    setActiveQuote(id){

      if (this.curQuoteId === id){
        return false;
      }
      if (id){
        this.curQuoteId = id;
        return true;
      }else{
        this.curQuoteId = 'open';
      }
      this.clearGated();
      this.emitDebug();
      if (!id){
        // purge all in-flight calls
        Object.keys(this.promiseBroker).forEach((guid) => {
          delete this.promiseBroker[guid];
        });
        this.currentGatedReq = null;
      }
      return false;
    },
    post(name, data){
      let resolver, promise;
      promise = new Promise(res => resolver = res);

      if (!data.sessionId){
        if (this.sessionId){
          data.sessionId = this.sessionId;
        }else if (!data.operation || data.operation !== 'createSession'){
          data.queued = true;
        }
      }
      data.method = name;
      let tracking = this.trackPost(data, resolver, promise);

      if (tracking.paramString) {
        if (!data.queued) {
          if(this.needsGating(data)){
            console.log({queueingGatedItem: data});
            this.enqueueGatedItem(data);
          }else {
            this.slayer.postMessage(data);
          }
        }
        else  {
          console.log({queued: data, osReady: this.osReady});
          this.queue.push(data);
        }
      }
      return tracking.promise;
    },
    enqueueGatedItem(data){
      this.activeQuoteQueue.push(data);
      if (!this.currentGatedReq){
        this.nextGatedItem();
      }
    },
    nextGatedItem(){
      if (this.activeQuoteQueue.length) {
        let data = this.activeQuoteQueue.splice(0, 1)[0];
        this.currentGatedReq = data.guid;
        this.slayer.postMessage(data);
      }else{
        this.currentGatedReq = null;
      }
    },
    clearGated(){
      while (this.activeQuoteQueue.length){
        let item = this.activeQuoteQueue.splice(0, 1)[0];
        if (item.operation === 'releaseObjectLock'){
          setTimeout(() => {
            this.$nextTick(() => {
              this.post('oneShield', item);//releaseObjectLock should not be hindered
            });
          }, 1);
        }else {
          let promise = this.promiseBroker[item.guid];
          if (!promise) {
            console.warn({missingQueuedItem: {item, promise}});
            return datadogLogs.logger.warn('missing queued item', {item, promise});
          }

          delete this.promiseBroker[item.guid];
          delete this.uniq[item.guid];
        }
      }
    },
    trackPost(data, resolver, promise){
      let paramString;
      let op = data.operation;
      let isFresh = op?.startsWith('update');
      //do not track dup params for paged reqs
      if (data.eachPage || data.operation === 'redButton'){
        paramString = 'paged';
      }
      else if (!isFresh) {
        //make param string to track dups
        paramString = Object.entries(data)
          .filter(([key, val]) =>
            !['OS_TOKEN', 'apiEnv'].includes(key) &&
            !isType.nullOrUndef(val))
          .map(([key, val]) =>
            [key, isType.primitive(val) ? val : '$'].join('')
          ).join('');
        if (paramString === ''){
          paramString = data.operation;
        }
        let uniq2guid = Object.fromEntries(Object.entries(this.uniq).map(([guid, paramString]) => [paramString, guid]));
        let existingGuid = uniq2guid[paramString];
        if (existingGuid && this.promiseBroker[existingGuid]) {
          console.log('return existing promise for redundant call ', {
            paramString,
            existingBrokered: this.promiseBroker[existingGuid]
          });
          data.guid = existingGuid;
          return {
            promise: this.promiseBroker[existingGuid].promise,
            exists: true
          };
        }
      }else{
        paramString = guid();
      }
      data.guid = data.guid || guid();
      if (paramString !== 'paged' && !isFresh) {
        this.uniq[data.guid] = paramString;
      }
      this.promiseBroker[data.guid] = {promise, resolver, paramString};
      if (data.eachPage){
        this.pagingBroker[data.guid] = data.eachPage;
        data.paged = true;
        data.page = 1;
        delete data.eachPage;
      }
      if (data.operation === 'createSession'){
        this.awaitingSession = true;
      }
      if (data.operation === 'setPolicyUnderwritingFlag' || data.operation === 'eligibilityQuestionList'){
        let {activeQuoteQueue, currentGatedReq, curQuoteId} = this;
        console.log({operation: data.operation, activeQuoteQueue, currentGatedReq, curQuoteId});
      }
      if (data.operation === 'getQuoteDetails' && !data.skipGating){
        let lock = this.setActiveQuote(data.id);
        if (lock){
          data.obtainLock = true;
        }
        console.log({gatingRequests: data.id});
      }
      if (!this.awaitingSession && !this.sessionId){
        console.log('hard refresh called from eventBus');
        this.$emit('refreshSession', true);
      }

      return {promise, resolver, paramString};
    },
    handler(data){
      if (data.sessionId && data.operation === 'createSession'){
        this.apiEnv = data.requestPayload.apiEnv;
        this.$nextTick().then(() => {
          this.awaitingSession = false;
          this.sessionId = data.sessionId;
          this.ready = true;
        });
      }

      if (!isType.nullOrUndef(data.response?.hasErrors) && data.response.hasErrors){
        let error = data.response.error;
        if (error?.knownError?.type === 'expiredSession') {
          //this.sessionId = null;
          let {sessionId, ready, OS_TOKEN, apiEnv} = this;
          if (sessionId && ready && OS_TOKEN){
            let retried = data.response.requestPayload.retried;
            if (retried){
              this.$emit('invalidateSession');
              datadogLogs.logger.warn('expired session retry fail', data.response.requestPayload);
            } else {
              let retryData = {
                ...data.response.requestPayload,
                retried: true,
                sessionId, OS_TOKEN, apiEnv
              };
              console.log('retry silently and skip queue', {retryData});
              this.slayer.postMessage(retryData);
              return;
            }
          }
          this.queue.push(data.response.requestPayload);
          this.$emit('refreshSession', true);
          console.log('silently recover from expired session error. queuing last request');
          console.log({lastPayload: data.response.requestPayload});
          return;
        } else {
          this.$emit('oneShieldError', data.response);
        }
      }else if (data.response?.firstResult){
        datadogLogs.logger.info('Retry OneShield Error Succeeded', data.response);
      }
      const resolvePromise = () => {
        if (this.promiseBroker[data.guid]?.resolver) {
          this.promiseBroker[data.guid].resolver(data);

          if (data.guid === this.currentGatedReq){
            this.nextGatedItem();
          }
          let keep = 1000;
          setTimeout(() => {
            this.$nextTick(() => {
              delete this.uniq[data.guid];
              delete this.promiseBroker[data.guid];
            });
          }, keep);//cache for 1 second
        }else{
          console.log({ignored_slayerResponse: this.promiseBroker, data});
        }
      };
      if (data.guid){
        let paged = data.response?.pageInfo;
        if (paged){

          let {isCallback} = paged;
          if (isCallback) {
            this.pagingBroker[data.guid](data);
          }
          else{
            paged = null;
            resolvePromise();
          }
        }
        else {
          resolvePromise();
        }
      }
      else{
        console.warn({missingResolver: data, promiseBroker: this.promiseBroker});
      }
    },
    init(){
      if (vm.slayer){
        vm.slayer.addEventListener('message', (res) => vm.handler(res.data));
        console.log('the slayer is alive');
        vm.ready = true;
        vm.$emit('slayerReady');
      }
    },
    emitDebug(){
      let queue = clone(this.activeQuoteQueue).map(q => {
        delete q.OS_TOKEN;
        return q;
      });
      let debugInfo = {
        gating: this.curQuoteId,
        curReq: this.currentGatedReq,
        queue
      };

      this.$emit('queueUpdate', debugInfo);

    }
  },
  created(){
    vm = this;
    this.init();
  },
  mounted(){

  },
  watch: {
    activeQuoteQueue: {
      deep: true,
      handler(){
        this.emitDebug();
      }
    },
    currentGatedReq: {
      deep: true,
      handler(){
        this.emitDebug();
      }
    },
    osReady: {
      handler(ready) {
        console.log({ready});
        if (!ready || !this.queue.length) {
          return;
        }
        let count = this.queue.length;
        const iterateQueueAsync = () => {
          if (this.queue.length === 0){
            return;
          }
          let data = this.queue.pop();
          data.sessionId = this.sessionId;
          data.OS_TOKEN = this.OS_TOKEN;
          data.apiEnv = this.apiEnv;
          console.log(`(${count - this.queue.length}/${count}) sending queued request`, {[data.operation]: data});
          delete data.queued;
          this.slayer.postMessage(data);
          this.promiseBroker[data.guid]?.promise.then(iterateQueueAsync);
        };
        iterateQueueAsync();
      },
      deep: true
    }
  },
  name: 'eventBus'
});
