import {incumbentCarriers} from '@/lib/fields/field-constants';
import typeDefs from '@/json/types-list.json';
export const customer = [
  {type: 'autocomplete',
    vals: {
      lazy: 'findBrokerCustomer',
      debounce: 300
    },
    onSelect: 'brokerCustomerChosen',
    key: 'brokerCustomer', classList: 'w-100', lbl: 'Account Name',
    autoFocus: true, title: 'Customer' },
  {type: 'data', key: 'accountName'},
  {type: 'data', key: 'customerRegistryId', tags: 'hasExternalSource'},
  {type: 'text', key: 'careOf', lbl: 'C/O', optional: true},
  {type: 'text', key: 'email', lbl: 'Email', placeholder: 'Customer email only. No broker addresses.'},
  {type: 'bool', key: 'usePrimaryLocation', inlineLabel: 'Use Primary Insured Location Address'},
  {
    type: 'geocode', key: 'address', lbl: 'Mailing Address',
    autoFocus: false, anyJurisdiction: true,
    readOnly: {
      $conditional: true,
      test: {chain: 'customer.usePrimaryLocation', eq: true}
    },
    val: {
      $conditional: true,
      test: {chain: 'customer.usePrimaryLocation', eq: true},
      result: {
        $compute: { chain: 'locations.location-1.addressDef' }
      },
      defaultVal: null
    },
    isMailing: true,
    isBilling: true,
    isPrimary: {
      $conditional: true,
      test: {chain: 'customer.usePrimaryLocation', eq: true}
    }
  },
  {key: 'phone', type: 'phone', lbl: 'Phone', optional: true, defaultVal: ''},
  {key: 'newBusiness', type: 'yn', lbl: 'Is this submission new business to your company?'},
  {key: 'incumbentCarrier', type: 'select', lbl: 'Incumbent Carrier', vals: incumbentCarriers},
  {
    key: 'hasPropertyManager', lbl: 'Do you have a Property Manager?',
    title: 'Property Manager', group: 'pm', type: 'select',
    classList: 'w-50', tags: 'hasExternalSource',
    vals: [{key: 'none', lbl: 'None'}, {key: 'onSite', lbl: 'Yes, On-Site'}, {key: 'offSite', lbl: 'Yes, Off-Site'}]
  },
  {
    key: 'pmState', lbl: 'Property Management Company State', type: 'select',
    vals: typeDefs.stateList.map(state => {
      state.lbl = state.code;
      return state;
    }), classList: 'w-25',
    active: {
      $conditional: true,
      chain: 'customer.pmExists',
      eq: true
    }
  },
  {
    key: 'propertyManager', type: 'autocomplete', group: 'pm',
    lbl: 'Property Management Company',
    onSelect: 'checkFSIB',
    options: {
      mapFields: {
        value: 'customer.propertyManagerId',
        text: 'customer.propertyManagerName',
        address: 'customer.propertyManagerDisplayAddress'
      }
    },
    vals: {
      api: 'getPropertyManagerList',
      params: {}, sort: 'name', cached: true,
      filter: {stateKey: 'customer.pmState'},
      map: ['state'],
      concat: [{
        subTitle: {
          keys: ['street1', 'city', 'state'],
          join: ', '
        }
      }, {
        address: {
          keys: ['subTitle', 'zipcode'],
          join: ' '
        }
      }],
      text: 'name', subTitle: 'subTitle', value: 'id'
    },
    active: {
      $conditional: true,
      chain: 'customer.pmExists',
      eq: true
    }
  },
  { key: 'propertyManagerName', type: 'data', tags: 'hasExternalSource' },
  {
    key: 'propertyManagerAddress', type: 'geocode', group: 'pm', lbl: 'Property Management Company Address',
    autoFocus: false, tags: 'hasExternalSource, createNew', anyJurisdiction: 'customer.pmState',

    active: {
      $conditional: true,
      $and: [
        {chain: 'customer.pmExists', eq: true },
        {chain: 'customer.propertyManagerId', eq: null},
        {chain: 'customer.propertyManagerName', ne: null}
      ]
    },
    state: {
      $conditional: true,
      $compute: {chain: 'customer.pmState'}
    }
  },
  {key: 'propertyManagerId', type: 'data', tags: 'hasExternalSource'},
  {
    key: 'propertyManagerDisplayAddress', type: 'text', readOnly: true, lbl: 'Property Management Company Address',
    tags: 'hasExternalSource',
    active: {
      $conditional: true,
      $and: [
        {chain: 'customer.pmExists', eq: true },
        {chain: 'customer.propertyManagerId', notNull: true}
      ],
      optional: true
    }

  },
  { key: 'pmExists', type: 'data',
    val: {
      $conditional: true,
      test: {
        chain: 'customer.hasPropertyManager',
        includedIn: ['onSite', 'offSite']
      },
      defaultVal: false
    }
  }, {type: 'data', key: 'phoneId'}];
