import {stateIdByCode, stopGapVals} from '@/lib/fields/field-constants';

export const baseline = [
  {
    key: 'occLimit', tags: 'col1', lbl: 'Each Occurrence Limit',
    type: 'select', title: 'COVERAGE OPTIONS',
    vals: {
      $conditional: true,
      $switch: [
        {
          $or: [{chain: 'quote.programCode', eq: 'HOA'}, {admin: true}],
          result: [{ key: '1000000', lbl: '$1,000,000' }, { key: '2000000', lbl: '$2,000,000' }]
        }
      ],
      defaultVal: [{ key: '1000000', lbl: '$1,000,000' }]
    },
    classList: 'w-75'
  },
  {
    key: 'aggLimit', tags: 'col1', lbl: 'General Aggregate Limit',
    type: 'select',
    readOnly: true,
    val: {
      $conditional: true,
      $switch: [{
        test: {chain: 'sibling::occLimit', eq: '2000000'},
        result: '4000000'
      }],
      defaultVal: '2000000'
    },
    vals: [{ key: '2000000', lbl: '$2,000,000' }, { key: '4000000', lbl: '$4,000,000' }],
    classList: 'w-75'
  },
  {
    key: 'medLimit', tags: 'col1',
    lbl: 'Medical Expense Limit - Any One Person Limit',
    type: 'select',
    vals: [{key: '5000', lbl: '$5,000'}, {key: '10000', lbl: '$10,000'}], classList: 'w-75'
  },
  {
    key: 'autoLiab', tags: 'col1', type: 'bool',
    lbl: 'Auto-hired, Non-Owned & Garagekeepers Legal Liability'
  },
  {
    key: 'premOper', tags: 'col1', type: 'bool', lbl: 'Premises',
    title: ' LIQUOR LIABILITY EXCLUSION', titleClass: 'titleFixed titleSpan',
    active: {
      $conditional: true,
      chain: 'scope.jurisdiction', ne: stateIdByCode.TX
    }
  },
  {
    key: 'empLiability', tags: 'col2', type: 'yn', optional: true,
    lbl: 'Employee Benefits Liability Coverage',
    title: 'Employee Benefits Liability', titleClass: 'titleFixed titleSpan'
  },
  {
    key: 'eachEmpAmt', tags: 'col2',
    lbl: 'Amount of Insurance - Each Employee', type: 'select', val: '1000000',
    vals: [{key: '1000000', lbl: '$1,000,000'}],
    active: {
      $conditional: true,
      chain: `baseline.empLiability`, eq: true
    }
  },
  {
    key: 'empCt', tags: 'col2',
    lbl: 'Number of Employees', type: 'int',
    active: {
      $conditional: true,
      chain: `baseline.empLiability`, eq: true
    }
  },
  {
    key: 'empAggLimit', tags: 'col2',
    lbl: 'Aggregate Limit', type: 'select', vals: [{key: '1000000', lbl: '$1,000,000'}],
    active: {
      $conditional: true,
      chain: `baseline.empLiability`, eq: true
    }
  },
  {
    key: 'empDeductiblePerClaim', tags: 'col2', lbl: 'Deductible',
    type: 'select', val: 1000,
    vals: [{ key: 1000, lbl: '$1,000'}],
    active: {
      $conditional: true,
      chain: `baseline.empLiability`, eq: true
    }
  },
  {
    key: 'retroDateApplies', tags: 'col2', lbl: 'Retroactive Date Applies', type: 'yn',
    active: {
      $conditional: true,
      chain: `baseline.empLiability`, eq: true
    }
  },
  {
    key: 'retroDate', tags: 'col2',
    lbl: 'Retroactive Date', type: 'date',
    active: {
      $conditional: true,
      chain: `baseline.retroDateApplies`, eq: true
    }
  },
  {
    key: 'hasStopGap', tags: 'col2', type: 'bool',
    title: 'Stop Gap - Employers Liability Coverage',
    lbl: 'Stop Gap - Employers Liability Coverage',
    active: {
      $conditional: true,
      chain: 'scope.jurisdiction', includes: [stateIdByCode.WA, stateIdByCode.OH]
    }
  },
  {
    key: 'stopGapBodilyInjuryByAccidentEachAccident', tags: 'col2', classList: 'w-100',
    type: 'select', lbl: 'Bodily Injury By Accident (Each Accident)', vals: stopGapVals,
    active: {
      $conditional: true,
      chain: 'baseline.hasStopGap', eq: true
    }
  },
  {
    key: 'stopGapBodilyInjuryByDiseaseAggregateLimit',
    tags: 'col2', readOnly: true, type: 'select', vals: stopGapVals,
    lbl: 'Bodily Injury By Disease (Aggregate Limit)',
    active: {
      $conditional: true, chain: 'baseline.hasStopGap', eq: true
    }
  },
  {
    key: 'stopGapBodilyInjuryByDiseaseEachEmployee',
    tags: 'col2', readOnly: true, type: 'select',
    lbl: 'Bodily Injury By Disease (Each Employee)', vals: stopGapVals,
    active: {
      $conditional: true, chain: 'baseline.hasStopGap', eq: true
    }
  }
];
