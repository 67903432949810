<template>
  <div style="min-height:450px">
    <div class="d-flex">
      <div style="width:80%">
        <field-column :defs="exposureDefs" label-w="40%" field-w="60%"/>
      </div>

      <div style="width: 20%" class="invis">{{ notValidCount }}</div>
    </div>

  </div>
</template>

<script>
import {quotemix} from '@/lib/quotemix';
import deepEqual from 'deep-equal';
import FieldColumn from '@/components/fields/field-column';
import eventbus from '@/lib/eventbus';

export default {
  data: () => {
    return {
      fieldsPage: 'locations',
      originalValues: {}
    };
  },
  computed: {
    location(){
      let l = this.locations.find(l => l.selected);
      return l ? l : this.locations.length ? this.locations[0] : {};
    },
    chain(){
      if (this.location) {
        return key => `locations.${this.location.key}.${key}`;
      }
      return 'locations.location-1';
    },
    selectedKeys(){
      let vals = this.itemFromChain(this.chain('liabilityClasses'))?.val;
      let keys =  vals ? Object.entries(vals).filter(([, v]) => v === true).map(([k]) => k) : [];
      return keys;
    },
    exposureDefs(){
      return this.location.children.filter(d => d.hasTag('exposures'));
    },
    rendered(){
      if (this.location) {
        return this.location.children.filter(d => d.type === 'multi' ||
            (this.selectedKeys && this.selectedKeys.includes(d.group)));
      }
      return [];
    },
    notValidCount(){
      let rendered = this.rendered || [];
      //console.log(rendered.filter(d => !d.isValid).map(d=>[d.chain,d.visible]))
      return rendered.filter(d => !d.isValid).length;
    },
    valid(){
      return this.notValidCount === 0 && this.selectedKeys.length > 0;
    },
    dirty(){
      return !deepEqual(this.originalValues, this.liabilityParams);
    },
    exposuresDirty(){
      return this.exposureDefs.filter(f => f.dirty);
    }
  },
  name: 'exposures',
  components: {FieldColumn},
  mixins: [quotemix],
  methods: {

    toggleReqd(keys){
      if (!keys || !keys.includes){
        return;
      }
      this.exposureDefs.forEach(f => {
        let req = keys.includes(f.group);
        this.updateField({chain: f.chain, prop: 'required', val: req});
        this.updateField({chain: f.chain, prop: 'optional', val: !req});
      });
    },
    aggregateSelected(){
      let selected = {};
      this.locations.forEach(l => {
        let selection = l.children.find(cf => cf.key === 'liabilityClasses').val;
        Object.entries(selection).forEach(([key, bool]) => {
          if (bool){
            selected[key] = true;
          }
        });
      });
      
      this.updateField({chain: 'quote.aggLiabilityClasses', val: selected});
      this.storeFields('underwriting').filter(f => f.conditionalProps).forEach(f => this.fieldState(f));
    },
    save(){
      if (this.dirty) {
        const locationAdditionalExposures = Object.fromEntries(this.liabilityParams.locations.map(loc => [`${loc.chain}.unitCtOwnerOccupied`, loc.unitCtOwnerOccupied]));
        let promise = this.oneShield('updateLiabilities', {...this.liabilityParams, propertyBag: {locationAdditionalExposures}}, {savingFlag: true, toast: 'Saving Exposures'});
        promise.then(({response: {fields, propertyBag}}) => {
          this.rehydrate({fields, propertyBag});
          this.originalValues = this.clone(this.liabilityParams);
        });
        return promise;
      }
      return Promise.resolve('unchanged');
    }

  },
  mounted() {
    this.locations.forEach((l, i) => {
      this.updateField({chain: l.chain, props: {selected: i === 0}});
    });
    const initDirty = () => this.originalValues = this.clone(this.liabilityParams);
    eventbus.$on('scoresAdded', initDirty);
    initDirty();
  },
  watch: {
    selectedKeys(keys){
      this.aggregateSelected();
      this.delayFn(() => {
        this.toggleReqd(keys);
      });

    },
    valid(v, old){
      if (v !== old){
        this.$emit('valid', v);
      }
    }
  }
};
</script>

<style scoped lang="scss">
strong{display:block;}
.input-row{

  p{margin:0}
}
</style>
