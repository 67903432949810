<template>
  <b-modal :no-close-on-backdrop="progress" :no-close-on-esc="progress"
    id="simple" :title="title" :footer-class="progress?'d-none':undefined"
    ref="simpleModal" v-model="displayed"
    @cancel="cancel" @ok="ok" @hidden="closed" :ok-only="cancelTxt===false"
    size="md" :cancel-title="cancelTxt||''" :ok-title="okTxt"
    button-size="lg" cancel-variant="info"
    >
    <div class="mr-4 ml-4 mb-4" v-if="progress">
      <progress-bar>{{message}}</progress-bar>
    </div>
    <p v-else>{{ message }}</p>
  </b-modal>
</template>

<script>
import {hook} from '@/lib/modal-service';
import ProgressBar from '@/components/util/progress-bar';
import {datadogLogs} from '@datadog/browser-logs';

export default {
  components: {ProgressBar},
  data: () => {
    return {
      title: null,
      message: null,
      resolver: null,
      okTxt: 'Ok',
      cancelTxt: 'Cancel',
      noCancel: false,
      progress: false,
      displayed: false
    };
  },
  computed: {
    empty(){
      return this.displayed && this.title === null && this.message === null;
    }
  },
  name: 'simple-modal',
  methods: {
    cancel(){
      this.resolver(false);
      this.resolver = null;
    },
    ok(){
      this.resolver(true);
      this.resolver = null;
    },
    closed(){
      if (this.resolver){
        this.cancel();
      }
    },
    hideModal(){

      this.title = this.message =  null;
      this.displayed = false;
    },
    spawnModal(title, msg, btns){
      this.delayFn(() => {
        this.title = title;
        this.message = msg;
        this.progress = btns === null;
        this.displayed = true;
        this.noCancel = false;
        if (btns){
          this.progress = false;
          if (btns.noCancel){
            this.noCancel = true;
          }

          this.okTxt = btns.ok !== undefined ? btns.ok : 'OK';
          this.cancelTxt = btns.cancel !== undefined ? btns.cancel : 'CANCEL';
        }
      });
      return new Promise(res => this.resolver = res);
    }
  },
  mounted() {
    hook(this);
  },
  watch: {
    empty(mt){
      if (mt){
          if (this.empty === true) {//hide the modal after next tick if the empty condition is still there.
            this.displayed = false;
          }

        console.warn({vm: this, empty: true});
        let {progress, title, displayed, okTxt, cancelTxt, resolver, noCancel} = this;
        datadogLogs.logger.warn('User shown a blank modal', {progress, title, displayed, okTxt, cancelTxt, resolver, noCancel});
      }
    }
  }
};
</script>
