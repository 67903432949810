import localForage from 'localforage';
import {isType} from '@/lib/mytype';
import {clone, decodeXml, getChainedVal, maxOSInt, sortByKey} from '@/lib/util';
import {compressBuildings, parseLocationData} from '@/lib/hydration-io';
import {policyCacheVersion, quoteCacheVersion} from '@/lib/fields/field-constants';
import eventbus from '@/lib/eventbus';
import state from '@/store/get-quote/state';
import {popChain} from '@/lib/fields/field-typedefs';
import {reinsurance} from '@/lib/fields/reinsurance-data';
const typeDefs = require('../json/types-list.json');


let _getters, _commit;
export default {
  namespaced: true,
  state,
  getters: {
    policyList: state => {
      let entries = state.policies.filter(p => p.env === state.env)
        .map(p => {
          return [p.id, p];
        });
      let uniq = Object.fromEntries(entries);
      return Object.values(uniq);
    },
    quoteList: state => {
      let entries = state.quotes.filter(q => q.env === state.env)
        .map(q => {
          if (isType.nullOrUndef(q.totalPremium)){//this makes premium sortable when null
            q.totalPremium = 0;//sortability
          }
          return [q.id, q];
        });
      let uniq = Object.fromEntries(entries);
      return Object.values(uniq);
    },
    stepReady: (state) => {
      return step => {
        if (step) {
          let dependantSteps = state.steps.slice(0, step);
          return dependantSteps.every(step => step.valid === true);
        }
        return true;
      };
    },
    whenCondition: () => () => false,
    evalCondition(state, getters) {
      return (conditionalProp) => {
        try {
          let {test, def, vm} = conditionalProp;
          if (!test){
            test = conditionalProp;
          }
          let {
            chain, eq, notNull, nothingSelected, includedIn, notIncludedIn,
            val, has, lt, gt, gl, includes, ne, excludes, itemVal,
            admin, dataVersion, rawVal, active, $and, $or, string, num
          } = test;

          if (Array.isArray($and)){

            let aResult = $and.every(aTest => {
              //console.log({aTest: JSON.stringify(aTest), result: getters.evalCondition({test: aTest, def, vm})});
              return getters.evalCondition({test: aTest, def, vm});
            });
            //  console.log({$and, aResult});
            return aResult;
          }
          if (Array.isArray($or)){
            let oResult = !!$or.find(oTest => {
              //console.log({oTest: JSON.stringify(oTest), result: getters.evalCondition({test: oTest, def, vm})});
              return getters.evalCondition({test: oTest, def, vm});
            });
            //console.log({$or, oResult});
            return oResult;
          }

          let item;
          if (dataVersion){
            return getters.dataVersion >= dataVersion;
          }

          if (isType.bool(gl)){
            return getters.itemVal('quote.isGl') === gl;
          }

          if (isType.bool(admin)){
            return vm.isAdmin === admin;
          }
          let v;

          if (isType.string(chain)) {
            if (chain.includes('::')) {
              let [method, param] = chain.split('::');
              item = def[method] ? def[method](param) : def.parent[method](param);
              if (isType.primitive(item)){
                v = item;
              }
            }else if (chain.includes('||')) {
              let [gttr, param] = chain.split('||');
              item = getters[gttr](param);

              if (isType.primitive(item) || Array.isArray(item)){
                v = item;
              }
            }else {
              item = getters.itemFromChain(chain);
            } if (!item){
              v = getters.itemVal(chain);
            }
          }
          else if (isType.string(itemVal)){
            v = getters.itemVal(itemVal);
          }
          v = v ?? item?.val;

          if (string){
            v = `${v}`;
          }
          if (num){
            v = Number(v);
          }
          if (isType.bool(active)){
            return item?.active === active;
          } else if (lt !== undefined) {
            return isType.snum(v) && Number(v) < lt;
          } else if (gt !== undefined) {
            return isType.snum(v) && Number(v) > gt;
          } else if (isType.bool(notNull)) {
            return notNull !== isType.nullOrUndef(v);
          } else if (eq !== undefined) {
            return (eq === v);
          } else if (ne !== undefined) {
            if (Array.isArray(ne)){
              return ne.filter(test => test === v).length === 0;
            }
            return ne !== v;
          } else if (rawVal !== undefined){//todo: relocate
            return v;
          } else if (includes !== undefined){
            if (Array.isArray(v)){
              return v.some(vItem => includes.includes(vItem));
            }
            return v && includes.includes(v);
          }else if (includedIn !== undefined){
            if (Array.isArray(v)){
              return v.some(vItem => includedIn.includes(vItem));
            }
            return v && includedIn.includes(v);
          }else if (notIncludedIn !== undefined){
            return !v || !notIncludedIn.includes(v);
          } else if (has) {
            if (Array.isArray(has)){
              return has.some(hv => v[hv]);
            }
            if (isType.string(v)){
              return v && v.includes(has);
            }
            return v && v[has];
          }else if (excludes) {
            if (Array.isArray(excludes)){
              return !excludes.some(hv => v[hv]);
            }
            return v && !v[excludes];
          } else if (val !== undefined) {
            v = v[val];
          } else if (nothingSelected !== undefined) {
            return nothingSelected === isType.nothingSelected(v);
          }
          return v ?? true;
        } catch (ex) {
          return false;
        }
      };
    },

    filteredChildren: (state, getters) => {
      return (childKey, oper = null, filter) => {
        if (childKey.includes(':')){
          let [k, op, flt] = childKey.split(':');
          childKey = k;
          oper = op;
          filter = flt;
        }
        let fields = getters.flatFieldList.filter(f => f.key === childKey && !f.selectionProxy);
        if (!oper){
          return fields;
        }
        let result, vals;


        if (oper.includes('chainedVal|')){
          let subChain = oper.replace('chainedVal|', '');
          vals = fields.map(f => getChainedVal(subChain, f.val));
        }
        else {
          vals = fields.map(f => f.val);

        }
          if (filter) {
            if (['min', 'max'].includes(filter)){
              return Math[filter](...vals.map(v => Number(v)));
            }
            let [compareFn, compareVal, castAs, filterFn] = filter.split('|');
            if (castAs) {
              vals = vals.map(v => {
                if (castAs === 'lower') {
                  return v.toLowerCase();
                }
                if (castAs === 'upper') {
                  return v.toUpperCase();
                }
                if (castAs === 'num') {
                  return Number(v);
                }
              });
            }
            if (!filterFn) {
              filterFn = 'every';
            }
            result = vals[filterFn](v => {
              if (compareFn === 'notNull') {
                return !isType.nullOrUndef(v);
              }
              if (compareFn === 'includedIn') {//wip - add more compareFns here
                return compareVal.split(',').includes(v);
              }
            });
            //console.warn({result, fields, childKey, oper, filter, vals, compareFn, compareVal, castAs, filterFn});
            return result;
          }
          else if (oper && ['min', 'max'].includes(oper)){
            return Math[oper](...vals.map(v => Number(v)));
          }
          return vals;


      };
    },

    selectedChildField: (state, getters) => {
      return param => {
        let [key, allValues, swingVal] = param.split(':');
        let v = getters.selectedFields.filter(f => f && f.key === key).map(f => f.val);

        if (Array.isArray(v)) {
          if (allValues){//allValues can be true, min, or max currently, true(or truthy) returns the full array
            if (['min', 'max'].includes(allValues)){
              swingVal = swingVal ? Number(swingVal) : Number(v[0]);
              if (allValues === 'min'){
                return Math.min(swingVal, ...v.map(val => Number(val)));
              }
              if (allValues === 'max'){
                return Math.max(swingVal, ...v.map(val => Number(val)));
              }
            }
            if (allValues === 'includedIn'){
              if (swingVal.includes(',')){
                let list = swingVal.split(',');
                return v.some(item => list.includes(item));
              }
            }

            return v;
          }

          let first = v[0];
          if (isType.nullOrUndef(first) || v.length === 0) {
            return first;
          } else if (v.slice(1).every(val => isType.primitive(val) && val === first)) {
            return first;
          }
          return undefined;
        }
        return null;
      };
    },
    selectedFields: (state) => {
      const selectedChildren = (field) => {
        let fields = [];
        if (!field){
          return fields;
        }
        const buildPrimitives = (k, v) => {
          if (!isType.nullOrUndef(v) && !isType.primitive(v)){

            Object.entries(v).forEach(([key, val]) => {
              fields.push({key: `${k}.${key}`, val});
              buildPrimitives(`${k}.${key}`, val);
            });
          }
        };
        if (field.children){
          fields = field.children.filter(f => !isType.bool(f.selected) || f.selected).flatMap(f => selectedChildren(f));
        }else {
          buildPrimitives(field.key, field.val);
        }
        fields.splice(0, 0, field);
        return fields.flat(2);
      };
      return selectedChildren(state.quoteFields.locations).flat(2);
    },
    flatFieldList: (state) => {

      let pages = Object.entries(state.quoteFields).filter(page => Array.isArray(page) && page.length);
      const allChildren = (field) => {
        let fields = [];
        if (!field){
          return fields;
        }
        if (field._children){
          fields = field._children.flatMap(f => allChildren(f));
        }
        fields.splice(0, 0, field);
        return fields.flat(2);
      };
      let flatFields = pages.flatMap(([, p]) => allChildren(p))
        .flat(5).map(f => [f.chain, f]);

      let obj = Object.fromEntries(flatFields);
      return Object.values(obj);
    },
    flatFieldsObject: (state, getters) => Object.fromEntries(getters.flatFieldList.map(f => [f.chain, f])),
    requiredInPage: (state, getters) => {
      return (group, vm) => getters.storeFields(group)
        .filter(item => {
          if (vm && item.conditionals?.active && !item.conditionals.testedHeadless){
            vm.fieldState(item, true);
          }
          return item.isRequired;
        });
    },
    hasChanges: state => {
      const inGroup = (g, tags) => {
        let list = g?.children ?? _getters.flatFieldList;
        let found = {};
        list.forEach(fld => {
          if (fld.page === 'buildingFields') {
            return;
          }
          const pushFound = f => {
            if (f.dirty) {
              if (!tags || f.hasTag(tags)) {
                found[f.chain] = f;
              }
            }
          };
          pushFound(fld);
          if (fld.children) {
            fld.children.forEach(pushFound);
          }
        });

          return Object.values(found);
      };
      return ({group = null, tags = null, requireTouch = false} = {}) => {
        let dirty = group ?
          inGroup(state.quoteFields[group], tags)
          : inGroup(null, tags);

        if (requireTouch){
          dirty = dirty.filter(f => f.touched);
        }
        return dirty.map(f => [f.chain, f.val, f._originalVal]);
      };
    },
    invalidCount: (state, getters) => {
      return group => {
        return getters.requiredInPage(group).filter(f => {
          if (f.computeVal){
            return isType.nullOrUndef(getters.computedVal(f.computeVal));
          }
          return !f.isValid;
        }).length;
      };
    },
    storeFields: (state, getters) => {
      return (page, filter) => {
        const test = fld => {
          let result = fld.page === page && fld.chain !== page;
          if (filter){
            if (filter.group && fld.group !== filter.group){
              return false;
            }
            if (filter.tag && !fld.hasTag(filter.tag)){
              return false;
            }
          }
          return result;
        };
        return getters.flatFieldList.filter(test);
      };
    },
    quoteData: (state) =>
      Object.fromEntries(
        Object.entries(state.quoteFields).map(([key, entity]) => [key, entity.dataTree])
      ),
    selectedLocation: (state, getters) => {
      return getters.locations[state.locationIndex];
    },
    locations: (state) => {
      return sortByKey(state.quoteFields.locations.children, 'li');
    },
    buildings: () =>  _getters.locations.map(l => l.buildingList).flat(),
    quoteBuildings: (state, getters) => getters.locations
      .filter(l => !l.isOPO).map(l => l.buildingList).flat(),
    itemFromChain: (state, getters) => {
      return chain => {
        if (chain.includes('||')) {
          let [gttr, param] = chain.split('||');
          gttr = gttr.replace('.', '');
          return {val: getters[gttr](param)};
        }
        return getters.flatFieldsObject[chain] ??
          getters.flatFieldList.find(f => f.chain === chain);

      };
    },
    enumValObj: (state, getters) => {
      return (chain) => {
        let def = getters.itemFromChain(chain);
        if (!def){
          return null;
        }
        let val = def.vals.find(v => v.key === def.val);
        if (!val){
          return null;
        }/**/
        val = clone(val);
        delete val.key;
        delete val.lbl;
        if (!val.id){
          val.id = val.enumid || val.code;
        }
        return val;
      };
    },

    itemVal: (state, getters) => {
      return (page, key) => {
        try{
          if (!key){
            let ch = page.split('.');
            key = ch.pop();
            page = ch.join('.');
          }
          let chain = `${page}.${key}`;
          let item = getters.itemFromChain(chain);
          if (isType.nullOrUndef(item)) {

            let head = chain; let tail;

            const tryParentLinks = () => {
              if (isType.string(head) && head.includes('.')){
                let hs = head.split('.');
                tail = `${tail ? tail + '.' : ''}${hs.pop()}`;

                head = hs.join('.');

                if (getters.flatFieldsObject[head]){
                  return getters.flatFieldsObject[head];
                }
                return tryParentLinks();
              }
              return false;
            };
            let pField = tryParentLinks();
            if(isType.nullOrUndef(pField?.val)){
              return null;
            }

            if (pField && pField.val){
              tail = tail.split('.').reverse().join('.');
              if (tail.includes('::')){
                let [t, oper] = tail.split('::');
                item = getChainedVal(t, pField.val);
                if (oper === 'lower'){
                  item = item.toLowerCase();
                }
                if (oper === 'upper'){
                  item = item.toUpperCase();
                }
                if (oper === 'number'){
                  item = Number(item);
                }
              }else {
                item = getChainedVal(tail, pField.val);
              }
            }

            return item;
          }
          return item.val;
        }catch(ex){
          if (key && !key.includes('location')) {
            console.warn({page, key, ex});
          }
        }
        return null;
      };
    },
    computedVal: (state, getters) => {
      return (compute, def) => {
        if (compute.$compute){
          compute = compute.$compute;
        }
        let {chain, prop, notNull, find, eq, ne, vm} = compute;
        if (notNull){
          // noinspection JSVoidFunctionReturnValueUsed
          return compute.notNull.filter(chain => !isType.nullOrUndef(getters.itemVal(chain))).length > 0;
        }
        if (vm && isType.string(vm)){
          if (!vm.includes('.')){
            return def.vm[vm];
          }
          let keyChain = vm.split('.').reverse();
          let item = def.vm;
          while (item && keyChain.length){
            item = item[keyChain.pop()];
          }
          return item;
        }
        let item;
        if (isType.string(chain)){
          if (chain.includes('::')) {
            let [method, param] = chain.split('::');
            item = def[method] ? def[method](param) : def.parent[method](param);

            if (isType.primitive(item)){
              return item;
            }
            else if (!isType.nullOrUndef(item.val)){
              return item.val;
            }
          }
          if (chain.includes('||')) {
            let [gttr, param] = chain.split('||');
            item = getters[gttr](param);
            if (isType.primitive(item)){
              return item;
            }
          }
          else{
            item = getters.itemFromChain(chain);
          }
        }
        if (!item){
          return null;
        }

        let {val} = item;

        if (find && Array.isArray(val)){
          let [findProp, findEq] = Object.entries(find)[0];
          if (isType.object(findEq) && findEq.$compute){
            findEq = getters.computedVal(findEq.$compute, def);
          }
          item = val.find(itm => itm[findProp] === findEq);
          if (item) {
            if (isType.primitive(item) || prop === 'self'){
              return item;
            }
          }else{
            item = {};
          }
        }
        if (!prop){
          prop = 'val';
        }
        else if (prop.includes('.')){//todo: normalize compare fn for all paths
          let propChain = prop.split('.').reverse();
          while (item && propChain.length){
            item = item[propChain.pop()];
          }
          if (item && eq !== undefined){
            return item === eq;
          }else if (ne !== undefined){
            return item !== ne;
          }

          return item;
        }
        return item[prop];
      };
    },
    findState: (state, getters) => {
      return (s = null) => {

        s = s ? `${s}` : getters.itemVal('scope.jurisdiction');
        //console.log({findState: s});
        return typeDefs.stateList.find(item =>
          `${item.key}` === s || item.code === s || item.value === s
        );
      };
    },
    dataVersion: (state, getters) => {

      let effective = getters.itemVal('policy.effective');
      if (isType.date(effective)) {// version 1 is not possible now
        return new Date(2022, 8, 18) <= effective ? 4
          : new Date(2022, 7, 21) <= effective ? 3
            : 2;
      }
      return 0;//we do not have a quote loaded
    }
  },
  mutations: {
    initStep: (state, {page}) => {
      let pathIndex = state.activePath.findIndex(p => p === page);
      if (pathIndex < 0) {
        state.activePath.push(page);
      }

    },
    spliceGroup(state, {items = [], group = 'locations', i = 0, count = 999}){
      if (!Array.isArray(items)){
        items = [items];
      }
      count = Math.min(count, state.quoteFields[group].length);
      state.quoteFields[group].splice(i, count, ...items);
    },
    removeDef(state, chain){
      let f = _getters.flatFieldsObject[chain];
      if (!f || !f.parent){
        return console.warn({removeDef_noParentFound: chain});
      }
      f.parent.removeChild(f.key);
    },
    setNavPath: (state, page) => {
      if (page) {
        state.navigationPath.push(page);
      }else{
        state.navigationPath.splice(0, state.navigationPath.length);
      }
    },
    addFieldDefs: (state, {page, fields}) => {
      let pathIndex = state.activePath.findIndex(p => p === page);
      if (pathIndex < 0) {
        state.activePath.push(page);
      }
      fields.forEach(f => state.quoteFields[page].addChild(f));
    },

    //user input - per keystroke or anything
    updateField: (state, {chain, key, val, prop, props}) => {
      let item = _getters.itemFromChain(chain);
      if (!item){
        if (!chain){
          console.warn({updateField: 'no chain'});
          return null;
        }
        let parentChain = popChain(chain);
        item = _getters.flatFieldsObject[parentChain];
        let key = chain.split('.').pop();
        if (item.child(key)){//stale refs case (e.g., when deleting address)
          item = item.child(key);
        }
        else {
          if (item && item.isEntity) {
            if (!props) {
              props = {};
            }
            let child = {key, val, ...props};
            if (prop) {
              delete child.val;
              child[prop] = prop;
            }
            item = item.addChild(child);
            return console.warn({[`updateField_Child_${key}`]: item, chain});
          }
          console.warn({updateField: {key, chain}, flatObj: _getters.flatFieldsObject});
          return;
        }
      }
      if (prop){
        if (prop === 'vals' && Array.isArray(item['vals'])){
          if (item.resetVals){
            item.vals.splice(0, item.vals.length, ...val);
          }
          else{
            let insertDDitem = (v) => {
              let spliceIndex = item.vals.findIndex(vals => vals.key == v.key);
              let deleteCount = spliceIndex > -1 ? 1 : 0;
              if (!deleteCount) {
                item.vals.splice(Math.max(0, spliceIndex), deleteCount, v);
              }
            };
            if (Array.isArray(val)) {
              val.forEach(insertDDitem);
            } else {
              insertDDitem(val);
            }
          }
        }else {
          item[prop] = val;
        }
      }
      else if(props){
        Object.entries(props).forEach(([k, v]) => {
          item[k] = v;
        });
      }
      else {
        //todo: make this not so ugly
        if (isType.string(chain) && isType.string(val) && chain.includes('jurisdictionId') && val.includes('_')){
          val = val.split('_').pop();
        }
        item.val = val;
      }
      let {quoteFields} = state;
      let notEmpty = {};
      Object.keys(quoteFields).forEach(page => {
        let p = quoteFields[page];
        if (p.length){
          notEmpty[page] = p;
        }
      });

      //localStorage.removeItem('quoteState');//, '');//JSON.stringify({activePath, data:notEmpty}));
    },
    setValid: (state, {stepIndex, valid}) => {
      state.steps[stepIndex].valid = !!valid;
    },
    setAny(state, obj) {

      Object.keys(obj).forEach(k => state[k] = obj[k]);
    },
    setQuoteStoreAny: (state, any) => _commit('setAny', any),
    setChained: (state, {chain, val}) => {

      let spl = chain.split('.').reverse();
      let o = state;
      while (spl.length > 1){
        let k = spl.pop();
        if (o[k] === undefined){
          o[k] = {};
        }
        o = o[k];
      }
      if (Array.isArray(val)){
        o[spl.pop()].splice(0, 9999, val);
        return;
      }
      o[spl.pop()] = val;

    },

    resetSteps: (state, hard) => {
      state.isRating = false;
      state.saving = false;
      state.activePath.splice(0, state.activePath.length);

      Object.values(state.quoteFields).forEach(entityField => {

        if(entityField){
          entityField.reset(hard);
        }
      });
    },
    setPristineState: (state) => {
      Object.values(state.quoteFields).forEach(entity => entity.setPristine());
    },
    setQuoteProps: (state, props) => {
      Object.entries(state.quotePropertyBagMap).forEach(([key, chain]) => {
        //console.log(`propertyBag: key:${key}, chain:${chain}`, props);
        let val = props[key];
         if (val !== undefined) {
          let field = _getters.itemFromChain(chain);
          let visible = _getters.whenCondition(field);
          let props = {val, visible};
          _commit('updateField', {chain, props});
        }

        // console.log({propBagFieldUpdated: _getters.itemFromChain(chain), val});
      });

      let {windstormProperties, lockedFields = {}, reinsuranceProps, sewerDischargeValues, locationAdditionalExposures } = props;
      state.lockedFields = lockedFields;
      _getters.flatFieldList.filter(f => f.lockable).forEach(({chain}) => {
        let locked = Boolean(lockedFields[chain]);
        _commit('updateField', {chain, props: {locked}});
      });

      if (windstormProperties){

        let wsFields = state.quoteFields.windstorm.children;
        let {windstormCoveredState} = windstormProperties;
        if (!windstormCoveredState){
          return;
        }
        let wsField = attr => wsFields.find(f => f &&
          f.hasTag(windstormCoveredState) && (f.apiKey ?? f.key) === attr);
        Object.entries(windstormProperties).forEach(([key, val]) => {
          let chain = wsField(key)?.chain;
          if (chain) {
            _commit('updateField', {chain, val});
          }//do not update computed prop windstormCoveredState
        });
      }
      if (reinsuranceProps){
        let reinsuranceEntity = state.quoteFields.reinsurance;
        let extras = [];
        Object.entries(reinsuranceProps)
          .filter(([key]) =>
            key.includes('_') && Number(key.split('').pop()) > 1
          )
          .forEach(([key]) => {

            let [k, exIndex] = key.split('_');
            exIndex = (Number(exIndex) - 1) * 2;
            if (k === 'reinsurer'){
              exIndex--;
            }

            let child = clone(reinsurance.find(f => f.key === `${k}_1`));
            child.key = key;
            child.optional = true;
            child.tags += ',added';
            extras[exIndex] = child;

          });
        extras.forEach(child => reinsuranceEntity.addChild(child));
        reinsuranceEntity.treeVals = reinsuranceProps;
      }
      if (sewerDischargeValues){

        Object.entries(sewerDischargeValues).forEach(([chain, val]) => {
          let sewerField = _getters.itemFromChain(chain);

          if (sewerField) {
            if (!sewerField.hasTag('hasExternalSource')){
              sewerField.tagList.push('hasExternalSource');
            }
            sewerField._val = val;
          }
        });
      }
      if (locationAdditionalExposures) {
        Object.entries(locationAdditionalExposures).forEach(([chain, val]) => {
          let field = _getters.itemFromChain(chain);
          if (field) {
            field._val = val;
          }
        });
      }
    },
    rehydrate: (state, {fields, page, propertyBag, force}) => {
      if (!eventbus.curQuoteId && !force){
        return console.log('Ignoring quote rehydration');
      }
      if (!fields || !fields.length){
        return console.warn('att rehydration without valid fields');
      }
      eventbus.$emit('quoteRehydrateStart', fields);

      let steps = Object.keys(state.pages);
      if (page && steps.indexOf(page) > -1){
        steps = steps.slice(0, steps.indexOf(page));
      }
      state.activePath = steps;

      fields = fields
        .map(f => {
          if (f.type === 'date' && isType.snum(f.val)) {
            f.val = new Date(Number(f.val));
          }
          return f;
        });

      let lists = fields.filter(f => f.type === 'list');
      const list = chain => {
        try {
          let li = lists.find(f => f.chain === chain);
          if (li){
            if (Array.isArray(li.val)){
              return li.val;
            }
            if (isType.object(li.val)){
              return [li.val];
            }
          }
          return [];
        }catch(ex){
          return [];
        }
      };
      let remIncomplete = list('info.incomplete') || [];
      state.remIncomplete.splice(0, state.remIncomplete.length, ...remIncomplete);
      state.quoteFields.locations.clearChildren();

      let buildings = [];

      let allBuildings = compressBuildings(list('locations.buildings'), list('buildings.cov'));
      allBuildings = sortByKey(
        allBuildings.map(b => {
          b.num = Number(b.num);
          return b;
        }), 'num');

      let locationLists = Object.fromEntries([
        'locations.locationMeta', 'locations.propertyCoverages', 'locations.addresses', 'locations.outdoorProperties',
        'locations.liabilityCoverages', 'locations.liabilities'
      ].map(key => [key.split('.')[1], list(key)]));

      locationLists.allBuildings = allBuildings;
      locationLists.buildings = buildings;
      parseLocationData(locationLists, state.quoteFields, (obj) => _commit('updateField', obj));

      fields.filter(f => f.type !== 'list' || f.hydrateList).forEach(f => {
        let item = _getters.itemFromChain(f.chain);
        if (item) {
          if (item.type === 'date' && !isType.nullOrUndef(f.val)){

            let d = new Date(f.val);
            //todo: sync with Ben on this behavior
            f.val = d && d.getFullYear() > 1970 ? d : null;
          }

          // SHRUG (loads val as null otherwise)
          if (f.chain === 'baseline.occLimit') {
            _commit('updateField', {chain: f.chain, prop: 'val', val: f.val});
          }

          ['val', 'vals'].forEach(v => {
            if (!isType.nullOrUndef(f[v]) && f[v] !== item[v]) {
              let chain = f.chain;
              let prop = v;
              let val = f[v];
              if (prop === 'vals' && item.hasTag('allowUnlisted')) {
                console.log('skip unlisted val in ' + chain);
              } else {
                if (v === 'val' && ['text', 'txt', 'email'].includes(item.type)) {
                  val = decodeXml(val);
                }
                if (item.type === 'select' && val === '0'){
                  //console.warn({f})
                  val = null;
                }
                _commit('updateField', {chain, prop, val});
              }
            }
          });
        }
      });
      if (isType.array(_getters.itemVal('quote.scoredLocations'))){
        let storeMappedScores = _getters.locations.map(l => l.scores);
        console.log({storeMappedScores});

      }
      _commit('setPristineState');
      if (!propertyBag) {
        if (_getters.itemVal('quote.sewerDischargeExcludedBuildings')){
          // buildings are built from scratch, so we must take the cached data field and do a partial propStore rehydrate
          // since we can't make building fields sticky
          propertyBag = {sewerDischargeValues: _getters.itemVal('quote.sewerDischargeExcludedBuildings')};
        }
        if (_getters.itemVal('quote.locationAdditionalExposures')) {
          propertyBag = propertyBag || {};
          propertyBag.locationAdditionalExposures = _getters.itemVal('quote.locationAdditionalExposures');
        }
      }
      if (propertyBag){
        _commit('setQuoteProps', propertyBag);
      }
      eventbus.$emit('quoteRehydrate', fields);
    },
    removeBType: (state, {li, bi}) => {
      let bldg = state.quoteFields.buildings.find(b => b.li === li && b.bi === bi);

      if (bldg.dataTree.externalId){
        bldg.treeVals = {_delete: true};
      }else {
        _commit('removeDef', bldg.chain);
      }
    },
    addToListCache: (state, {list, listName}) => {
      let redundant = [];
      let now = new Date().valueOf();

      let newItems = list.map(item => {
        let existingInd = state[listName].findIndex(sItem => sItem.id === item.id && sItem.env === state.env);
        if(existingInd > -1){
          redundant.push(existingInd);
        }
        item.env = state.env;
        item.added = now;
        return item;
      });

      redundant.sort().reverse().forEach(delIndex => {
        state[listName].splice(delIndex, 1);
      });
      state[listName].push(...newItems);

    },
    addQuotes(state, {list}){
      _commit('addToListCache', {list, listName: 'quotes'});
      let quoteList = state.quotes.filter(q => q.status !== 'Bound');
      localForage.setItem('quoteList', {version: quoteCacheVersion, list: quoteList });

    },
    addPolicies(state, {list}){
      _commit('addToListCache', {list, listName: 'policies'});

      localForage.setItem('policyList', {version: policyCacheVersion, list: state.policies});

    }
  },
  actions: {
    getQuoteDetail: ({commit, getters}, {id, oneShield, page, filter}) => {
      if (!id){
        id = getters.itemFromChain('quote.quoteId').val;
      }
      let op = 'getQuoteDetails';
      return new Promise(res => {
        oneShield(op, {id}).then(result => {
          let resultFields = result?.response?.fields;
          let propertyBag = result?.response?.propertyBag;
          if (Array.isArray(resultFields) && !result.hasErrors) {
            if (filter){
              resultFields = resultFields.filter(f => !f?.chain?.includes(filter));
            }
            commit('rehydrate', {fields: resultFields, page, propertyBag});
          }
          res(result);
        });
      });
    },
    initStore: ({state, commit, getters}, {vm}) => {
      _commit = commit;
      _getters = getters;
      Object.values(state.quoteFields).forEach(entity => {
        entity.runtime = {
          vm, getters
        };
      });
      const initList = (storageName, stateItem, ver) => {
        localForage.getItem(storageName).then(offline => {
          if (!offline || offline.version !== ver){
            return localForage.removeItem(storageName);
          }
          if (offline){
            state[stateItem].push(...offline.list);
          }
        });
      };
      initList('quoteList', 'quotes', quoteCacheVersion);
      initList('policyList', 'policies', policyCacheVersion);
      return Promise.resolve(true);
    },
    save: ({dispatch, commit}, save) => {
      commit('setAny', {issued: save});
      //call api .then blank slate it
      dispatch('init');
    }
  }
};
