import {mapGetters, mapMutations, mapState} from 'vuex';
import {isType} from '@/lib/mytype';
import {lookupError} from '@/lib/error';
import eventbus from '@/lib/eventbus';


export default {
  computed: {
    ...mapGetters('getQuote', ['computedVal', 'itemVal', 'itemFromChain']),
    ...mapState('getQuote', ['saving', 'lockedFields']),
    overrideFieldLock(){
      return this.def.fieldLockOverride;
    },
    lockable(){
      return isType.bool(this.def.locked) && this.isAdmin &&
        !['unlisted', 'auto', null, undefined, false].includes(this.def.lockable);
    },
    locked(){
      let def = this.def.dataField ?? this.def;
      if (def.lockable === 'unlisted'){
        return !this.isAdmin && def.isUnlistedVal;
      }

      return def.lockable === 'auto' ||
        (!isType.nullOrUndef(def.lockable) && Boolean(def.locked || this.lockedFields[def.chain]));
    },
    proxyVal(){
      if (this.def.proxyFor){
        return this.def.val;
      }
      return null;
    },
    baseVal(){
      if (this.def.proxyFor){
        return this.def.dataField.val;
      }
      return null;
    },
    multitip(){
      let {state, chains, vals, selectionVals, type} = this.def;

      let hasTip =  state && state === 'multi' ? 'Multiple Values' : null;

      if (hasTip){
        let uniq = {};
        selectionVals.forEach((v, i) => {
          if (uniq[v]){
            uniq[v].push(chains[i]);
          }else{
            uniq[v] = [chains[i]];
          }
        });
        if (Object.keys(uniq).length === 1){
          return false;
        }

        return Object.keys(uniq).map(v => {
          let lbl = v === 'null' ? 'Not set' :
            type === 'select' ?
              vals.find(kv => `${kv.key}` === `${v}`) : v;

          if (type === 'select' && this.isType.object(lbl)){
            lbl = lbl.lbl;
          }
          let count = uniq[v].length;
          if (lbl === 'null'){
            lbl = 'Not set';
          }
          return {lbl, count};

        });
      }
      return false;
    },
    fieldValid(){
      if (this.multitip){
        return null;
      }
      let valid = !this.def.isRequired ? null : this.def.isValid;
      let {touched, blurred} = this.def;
      if (!blurred){
        return undefined;
      }
      if (valid !== false){
        return valid;
      }if (!touched || !blurred){
        return undefined;
      }
      return false;
    },
    autofocus(){
      return this.def.autoFocus ?? undefined;
    },

    disable(){
      return (this.saving || this.quoteLocked && !this.overrideFieldLock) || this.disabled || this.def.disabled;
    },
    validClass(){
      return {
        valid: this.fieldValid === true,
        invalid: this.fieldValid === false,
        neutral: isType.nullOrUndef(this.fieldValid)
      };
    }
  },
  props: ['def', 'disabled'],
  methods: {
    ...mapMutations('getQuote', ['updateField', 'setAny']),
    touch(focus = true){
      this.def.touched = true;
      this.def.blurred = !focus;
    },
    skipNonNumberInput(event) {
      if (!/\d/.test(event.key)) {
        return event.preventDefault();
      }
    },
    constrainInput(){
      let v = this.def.val;
      if (v === ''){
        this.def.val = null;
      }
      if (this.def.isNumeric && v.includes('.')) {
        this.def.val = v.split('.')[0];
      }
    },
    toggleLock(isRevert){
      if (!this.lockable){
        return;
      }
      let def = this.def.dataField ?? this.def;
      let proxy;
      if (isType.string(def.lockable)){
        proxy = this.itemFromChain(def.lockable);
      }
      let {locked} = def;
      locked = !locked;

      //update in mem and propertyBag
      let {lockedFields} = this;

      this.updateField({chain: def.chain, val: locked, prop: 'locked'});
      lockedFields[def.chain] = locked;
      if (proxy){
        this.updateField({chain: proxy.chain, val: locked, prop: 'locked'});
        lockedFields[proxy.chain] = locked;
      }

      this.setAny({lockedFields});
      if (isRevert === true){
        return;
      }
      this.propStore(this.itemVal('quote.quoteId'), {lockedFields}).then(({response}) => {
        if (response.hasErrors){
          response.errorId = 'fieldlock';
          response.error = lookupError(response);
          eventbus.$emit('apiError', response);
          this.toggleLock(true);
        }
        else {
          console.log(`${(proxy ?? def).chain} -> ${locked ? '' : 'un'}locked`);
        }
      });
    }
  },
  watch: {
    proxyVal(val){
      let chain = this.def.dataField?.chain;
      this.updateField({chain, val});
    }
  }
};

