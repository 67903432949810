<template>
  <div class="text-center bg-white mb-4 download-proposal">
    <div class="mr-5 ml-5 p-3" v-if="!proposal && !errorMessage">
      <progress-bar>Generating Quote Proposal</progress-bar>
    </div>
    <div v-else-if="!proposal && errorMessage">Error Generating Proposal. <a @click="getProposal(true)">Retry</a></div>
    <b-button v-else
      size="lg" class="w-100 mb-0 mt-4 dl-btn" variant="primary" @focus="showtooltip=false"
      @click="downloadProposal">Download the proposal
    </b-button>
    <div v-if="lastGen" id="dlproposal">Proposal valid as of {{lastGen.toLocaleString()}}
      <div v-if="showtooltip===true" class="newproposal-popover">
        <div class="text-white text-center">
          <i class="fas fa-times-circle text-white float-right" @click="showtooltip=false"></i>
          An updated proposal has been generated.<br>
          Please download and review the latest version.
        </div>
        <div class="tooltipTail"></div>
      </div>
    </div>

  </div>
</template>

<script>
import {UploadedFileDef} from '@/lib/fields/field-typedefs';
import {quotemix} from '@/lib/quotemix';
import {parseDate} from '@/slayer/util';
import ProgressBar from '@/components/util/progress-bar';
import eventbus from '@/lib/eventbus';
import {mapMutations} from 'vuex';
import {datadogLogs} from '@datadog/browser-logs';

export default {
  components: {ProgressBar},
  data: () => {
    return {
      showtooltip: true,
      proposal: null,
      errorMessage: null,
      generateTime: null,
      hasRetried: false
    };
  },
  name: 'download-proposal-button',
  computed: {
    lastGen(){
      let gen = this.proposal?.dataTree && parseDate(this.proposal.dataTree.created, true);
      return gen ? new Date(gen) : null;
    },
    proposalId() {
      return this.itemVal('quote', 'proposalId') ?? '';
    },
    quoteId() {
      return this.itemVal('quote', 'quoteId') ?? '';
    },
    quoteNumber() {
      return this.itemVal('quote', 'quoteNumber') ?? '';
    },
    lastRated(){
      let lastRated = this.itemVal('quote.lastRating.ratedAt');
      if (lastRated){
        return Date.parse(lastRated);
      }
      return null;
    },
    recentlyRated(){
      return this.lastRated && (this.lastRated + 10000 > Date.now());
    }
  },
  methods: {
    ...mapMutations('getQuote', ['setQuoteProps']),
    getProposal(force) {

      if (!force && (this.proposalId || this.generateTime)) {
        if (this.generateTime){
          this.delayFn(() => this.getDocList(), 1500);
        }
        return;
      }
      this.proposal = null;
      this.errorMessage = null;
      this.oneShield('generateProposal', {quoteId: this.quoteId}, {toast: 'Creating New Proposal'}).then(({response}) => {
        if (response.error){
          this.errorMessage = response.error.message;
          if (this.hasRetried){
            if (response.error.hideBanner){
              response.error.hideBanner = false;
              console.log('2nd generation attempt failure. giving up.');
              eventbus.$emit('oneShieldError', response);
            }
            return;
          }else {
            this.hasRetried = true;
            console.log('retry create proposal by checking doc list again. Hide error until 2nd failure');
          }
        }else{
          this.generateTime = Date.now();
        }
        this.getDocList();
      });
    },
    downloadProposal() {
      let params = {quoteId: this.quoteId, documentId: this.quoteData.quote.proposalId};
      let opts = {toast: `Downloading Proposal`};
      this.oneShield('getDocument', params, opts).then(({response}) => {
        if (response.error){
          this.errorMessage = response.error.message;
          console.log(this.errorMessage);
          return;
        }
        let {fields} = response;
        if (!fields) {
          return;
        }
        let doc = fields[0].val[0];
        if (doc.fileName) {
          let def = {chain: 'quote.proposal', ...doc};
          let fileDef = UploadedFileDef.create(def);
          fileDef.download();
        }
      });
    },

    getDocList() {
      if (!this.isMounted || !this.quoteId){
        console.log('component no longer mounted (download proposal)');
        return;
      }
      let lastRated = this.lastRated;

      if (!lastRated){
        this.errorMessage = 'No Rating Info';
        return datadogLogs.logger.warn(`Document list fetched for quote (${this.quoteNumber}) with no rating info`, {quoteId: this.quoteId, quoteNumber: this.quoteNumber});
      }
      this.oneShield('getDocumentList', {quoteId: this.quoteId}, {toast: 'Loading Quote Documents', parseList: true}).then(({response}) => {
        if (response.error){
          this.errorMessage = response.error.message;
          return this.getProposal();
        }

        let isStale = (doc) => {
          let parsed = parseDate(doc.created, true);
          console.log({
            proposal: doc,
            time: parsed.toLocaleString(),
            lastRated: new Date(lastRated).toLocaleString(),
            stale: parsed.valueOf() < lastRated,
            parsedValueOf: parsed.valueOf(),
            lastRatedValueOf: lastRated
          });
          return parsed.valueOf() < lastRated;
        };

        let {list} = response;
        list = JSON.parse(JSON.stringify(list));//in-mem object being mutated elsewhere, so making copy of orig
        console.log({docList: list, lastRated});

        if (!list) {
          return;
        }
        if (!Array.isArray(list)) {
          list = [list];
        }
        let proposals = list.filter(file => file.status !== 'Not Found' && file.fileName.includes('Quote Proposal'));
        let proposal = proposals.find(p => !isStale(p));

        if (proposal) {
          this.updateField({chain: 'quote.proposalId', val: proposal.documentId});
          this.proposal = UploadedFileDef.create(proposal);
        }else{
          console.log('Stale or empty proposals found. Regenerating.');
          this.getProposal(true);
        }
      });
    }
  },
  mounted() {
    this.showtooltip = true;
    this.errorMessage = null;
    this.updateField({chain: 'quote.proposalId', val: null});
    if (this.recentlyRated){//this is our 99% case
      console.log('bypass doclist call since rating occurred within 10sec');
      return this.getProposal(true);
    }
    this.delayFn(() => {
      //ensure fresh quote props from store
      this.propStore(this.quoteId).then(result => {
        this.setQuoteProps(result.response);
        this.getDocList();
      });
    }, 2000);
  },
  mixins: [quotemix]
};
</script>

<style lang="scss">
body {
  position: relative !important;
}
div.download-proposal {
  position: relative;
  margin-top: 20px;

  button {
    width: 285px !important;
    display: inline-block;
  }
}

#dlproposal {
  position: relative;
  margin-bottom: 40px;
}

.newproposal-popover {
  position: absolute;
  top: 24px;
  left: calc(50% - 16px);
  min-width: 348px;
  background-color: #333333;
  padding: 8px 12px 2px;

  i {
    cursor:pointer;
  }

  .tooltipTail {
    position: absolute;
    top: -5px;
    height: 12px;
    width: 12px;
    background-color: #333333;
    transform: rotate(45deg);
  }
}
</style>
