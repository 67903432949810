<template>
<div class="get-quote pb-4">
  <div class="head-bg" style="top:-100px !important;border-bottom-left-radius:200px"></div>
  <div class="d-flex mt-4" style="position:relative;height:88px">
    <logo class="mr-4"/>
    <align-middle><h1>BROKER PORTAL || Policies</h1></align-middle>
  </div>

  <div class="mt-5 pt-4 d-flex overlap" v-if="error">
    <div class="flex-fill"></div>
    <div>
      <h1 class="text-center">Error Loading Policy</h1>
      <div class="quote-panel mt-4 p-5" style="width:600px">
        The portal encountered an error loading policy information. Contact support: 877.546.7155 or service@lioinsurance.com (M-F, 8:30AM - 8:00PM ET).
        <div class="mt-4 text-center">
          <router-link to="/">
          <b-button size="lg" variant="primary">MAIN MENU</b-button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="flex-fill"></div>
  </div>
  <view-policy class="overlap" :missingPolicy="missingPolicy" v-else/>
</div>
</template>

<script>
import AlignMiddle from '@/components/util/align-middle';
import Logo from '@/components/svgs/logo';
import ViewPolicy from '@/components/view-policy';
import {mapMutations} from 'vuex';
import {parseShortDate, formatOsDate} from '../lib/util';

export default {
  data: () => {
    return {
      missingPolicy: false,
      error: null
    };
  },
  name: 'Policy',
  components: {ViewPolicy, Logo, AlignMiddle},
  methods: {
    ...mapMutations('getQuote', ['rehydrate', 'addFieldDefs', 'resetSteps'])
  },
  beforeDestroy() {
    this.resetSteps();
  },
  mounted() {
    this.resetSteps();
    document.title = 'Policy - LIO Insurance';
    let {policyId, transDate}  = this.$route.params;
    transDate = formatOsDate(parseShortDate(transDate));
    this.oneShield('getPolicyDetails', {policyId, transDate}, {toast: 'Loading Policy'}).then(result => {
      if (result.response.hasErrors){
        return this.error = true;
      }
      let fields = result.response.fields;
      this.rehydrate({fields, force: true});
    });
  }
};
</script>

<style lang="scss">
.overlap{
  position:relative;
  h1{
    text-transform: uppercase;
  }
}
.get-quote{
  .head-bg{
    z-index: 0;
  }/*
  .overlap{
    position:relative;
    z-index: 2;
    * {
      position: relative;
      z-index: 2;
    }
  }*/
}
</style>
