<template>
  <b-input-group
    :disabled="disable" :state="fieldValid" style="position: relative"
    :variant="multitip?'light':''"
    >

    <b-input
        v-if="!def.val || isType.primitive(def.val)"
        :class="{transparent:multitip}"
      :style="{background: multitip ? lioTheme.lightGray : 'white'}"
      @keypress="skipNonNumberInput" :chain="def.chain"
      :id="def.guid" @focus="touch(true)" @blur="touch(false)"
      :state="fieldValid" v-model="def.val" type="number" @change="constrainInput"
      :step="1" :autofocus="autofocus"
      :placeholder="def.placeholder" :disabled="disable" :title="!fieldValid ? 'Enter a valid number' : null"
      :min="def.min" :max="def.max" v-b-tooltip.hover="def.isValid ? '' : def.validationTip"/>
      <b-input-group-append>
        <b-button>%</b-button>
      </b-input-group-append>
  </b-input-group>
</template>

<script>
import fieldmix from '@/components/fields/fieldmix';

export default {

  mixins: [fieldmix],
  name: 'fieldtype_percent'
};
</script>

<style scoped lang="scss">
  @import "../../assets/scss/variables";

  input.transparent{
    color:transparent;
    &:active,&:focus{
      color:#333;
    }
  }
</style>
