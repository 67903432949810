import {cancellationReasons, incumbentCarriers, originOfDuplicate, constructionMaterials} from '@/lib/fields/field-constants';

export const cancellation = [
  {key: 'osReason', type: 'data'},
  {key: 'reason', lbl: 'Reason', type: 'select', width: '279px', vals: cancellationReasons},
  {
    key: 'description', lbl: 'Description', type: 'text', classList: 'subfield',
    active: {
      optional: true,
      $conditional: true,
      $or: [
        {
          chain: 'cancellation.reason',
          includedIn: [
            'PastEffectiveDate', 'ClassIneligible', 'Coverage', 'Pricing',
            'LossHistory', 'Loyalty', 'LeadTimeInsufficient', 'Capacity'
          ]
        }
      ]
    }
  },
  {
    key: 'carrier', lbl: 'Carrier', type: 'select', width: '279px', classList: 'subfield',
    vals: incumbentCarriers,
    active: {
      optional: true,
      $conditional: true,
      $or: [
        {chain: 'cancellation.reason', includedIn: ['Coverage', 'Pricing', 'Loyalty']}
      ]
    }
  },
  {
    key: 'premium', lbl: 'Premium', type: 'currency', width: '150px', classList: 'subfield',
    active: {
      optional: true,
      $conditional: true,
      $or: [
        {chain: 'cancellation.reason', includedIn: ['Coverage', 'Pricing', 'LossHistory', 'Loyalty']}
      ]
    }
  },
  {
    key: 'duplicateSubmissionOrigin', lbl: 'Submitted From', type: 'select', width: '279px', classList: 'subfield',
    vals: originOfDuplicate,
    active: {
      optional: true,
      $conditional: true,
      test: {chain: 'cancellation.reason', eq: 'Duplicate'}
    }
  },
  {
    key: 'constructionMaterial', lbl: 'Construction', type: 'select', width: '279px', classList: 'subfield',
    vals: constructionMaterials,
    active: {
      optional: true,
      $conditional: true,
      test: {chain: 'cancellation.reason', eq: 'Capacity'}
    }
  }
];
