const dataFields = [
  'quoteNumber', 'quoteId', 'submission',
  'customerId', 'customerEligibilityId', 'locked', 'policyId',
  'jurisdictionId', 'totalPremium', 'status', 'underwritingStatus',
  'priorPolicyId', 'underwritingId', 'policyCFId',
  'policyCommercialCFId', 'jurisdictionCFId',
  'jurisdictionGLId', 'policyGLId', 'policyCommercialGLId',
  'aggLiabilityClasses', 'blanketCoverages', 'programCode',
  'scoredLocations', 'underwritingTriggers', 'totals', 'modified', 'created',
  'policyNeedsUnderwritingInformation', 'answeredEligibility', 'underwritingReason',
  'isGl', 'proposalId', 'policyNumber', 'wildfire', 'propertyManagerId',
  'emptyAddressId', 'remainingFields', 'files', 'ratingJobId', 'quoteKey', 'quoteInfo',
  'lastRating', 'floodOverrides', 'sewerDischargeExcludedBuildings', 'other', 'locationAdditionalExposures'
].map(key => {
  let f = {key, type: 'data'};
  if (['ratingJobId', 'quoteKey', 'quoteInfo', 'lastRating', 'sewerDischargeExcludedBuildings', 'locationAdditionalExposures'].includes(key)){
    f.tags = 'hasExternalSource';
  }
  if (key === 'created'){
    f.type = 'date';
  }
  return f;
});

const conditionalFields = [
  {
    key: 'lockedForReinsurance',
    type: 'data',
    val: {
      $conditional: true,
      $switch: [{
        test: {chain: 'quote.totals.propertyTotalInsuredValue', gt: '50000000'},
        result: true
      }],
      defaultVal: false
    }
  },
  {
    key: 'quoteLocked',
    type: 'data',
    val: {
      $conditional: true,
      $switch: [{
        test: {chain: 'sibling::lockedForReinsurance', eq: true},
        result: true
      }],
      defaultVal: false
    }
  },
  { key: 'policyTranType', type: 'select'}//make a select to bind id/label pair
];

export const quote = [...dataFields, ...conditionalFields];
