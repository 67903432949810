<template>
  <div class="top-nav">

    <b-navbar :class="{invis:debug}"
      type="dark" variant="dark"
      style="background-color: transparent !important"
      v-if="isAuthenticated"
    >
      <session-timer/>
      <b-navbar-nav variant="primary" class="ml-auto">
        <b-nav-item-dropdown variant="primary" right>
          <template #button-content>
            <b-avatar
              :text="getInitials"
              :style="{backgroundColor: lioTheme.gray,border:`solid 1px ${isAdmin ? lioTheme.navy : lioTheme.gray}`}"
            ></b-avatar>
          </template>
          <b-dropdown-item v-if="oktaUser && oktaUser.email">{{ oktaUser.email }}</b-dropdown-item>
          <b-dropdown-item @click="storeLogout" class="link-primary">Log Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
    <debugger/>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import SessionTimer from '@/components/session-timer';
import Debugger from '@/components/util/debugger';
import {portalEvents} from '@/lib/mix';

export default {
  name: 'nav-bar',
  components: {Debugger, SessionTimer},
  methods: {
    ...mapActions(['logout']),
    async storeLogout(){
      let contextId = this.$route.params.quoteId;
      if (contextId){
        await this.oneShield('releaseObjectLock', {contextId});
      }
      this.clickStreamEvent(portalEvents.Logout, 'FromNavMenu');
      return this.logout(this.$auth);
    }
  },
  computed: {
    ...mapState(['debug', 'isAuthenticated', 'oktaUser']),
    getInitials() {
      let name = this.oktaUser ? this.oktaUser.name : null;
      if (!name){
        return 'LU';
      }
      let initials = name.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || '') + (initials.pop() || '')
      ).toUpperCase();
      return initials;
    },
    isAdmin() {
      return this.oktaUser?.partnerId === 0;
    }
  }
};
</script>

<style scoped lang="scss">
.top-nav {
  position: absolute;
  top:0; right:0;
  z-index: 2000;
  .dropdown-item:hover {
    background-color: transparent !important;
  }

  a:hover {
    background-color: transparent !important;
  }
}
</style>
