import {apiMap} from '@/slayer/apimap';
const supportInfo = `877.546.7155 or service@lioinsurance.com (M-F, 8:30AM - 8:00PM EST)`;
const knownErrorTypes = {
  quoteLock: {
    message: 'This quote is currently being accessed by another user. Please try again later.',
    test: {
      includes: 'object is locked',
      errorId: '17351'
    },
    hideBanner: true
  },
  expiredSession: {
    message: '',
    test: {
      includes: 'expired session',
      errorId: '10251'
    }
  },
  quoteNotBindable: {
      message: `Quote is not ready to Bind; Contact support: ${supportInfo}`,
    test: {
      errorId: 'cantbindquote'
    }
  },
  bindPolicyMoratorium: {
    message: 'Due to an active binding moratorium, we are unable to bind this account.',
    test: {
      includes: 'Bind Policy - A Moratorium is in effect'
    }
  },
  oAuthError: {
    message: `An unexpected error occurred. Please try again or contact support: ${supportInfo}`,
    test: {
      includes: 'OAuth'
    }
  },
  objectLockError: {
    message: `The portal encountered an error while locking the field.`,
    toast: true,
    hideBanner: true,
    test: {
      errorId: 'fieldlock'
    }
  },
  findRatingJobError: {
    message: `The portal encountered an error while looking up the rating job.`,
    skipErrorLog: true,
    hideBanner: true,
    test: {
      includes: 'ASYNC_JOB_STATUS'
    }
  },
  generateProposalFirstTry: {
    message: 'The portal encountered an error while generating the quote proposal.',
    hideBanner: true,
    test: {
      includes: 'Generate Proposal'
    }
  },
  nomatchingAddress: {
    message: 'The input address could not be verified.',
    hideBanner: true,
    skipErrorLog: true,
    test: {
      includes: 'no matching address'
    }
  }
};
export const lookupError = response => {
  if (!response.hasErrors){
    return null;
  }
  let {operation} = response.requestPayload ?? {};
  let {verb} = apiMap[operation] ?? {};
  let osErrorText = response.errorText;
  let errorId = response.errorId;
  let knownError;
  Object.entries(knownErrorTypes).forEach(([type, typeObj]) => {
    let {test} = typeObj;
    if ((test?.includes && osErrorText?.includes(test?.includes)) ||
      (errorId && test?.errorId === errorId)){
      knownError = {
        type,
        ...typeObj
      };
    }
  });

  let message;
  if (knownError){
    if (verb){
      message = `The portal encountered an error while ${apiMap[operation].verb}. ${knownError.message}`;
    } else {
      message = knownError.message;
    }
  } else if (verb){
    message = `The portal encountered an error while ${apiMap[operation].verb}. Please Contact support: ${supportInfo}.`;
  } else {
    message = `The portal encountered an error. Contact support: ${supportInfo}.`;
  }
  return {
    operation,
    osErrorText,
    errorId,
    message,
    knownError
  };
};
