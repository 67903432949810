<template>
  <div>
    <h2 class="title text-center m-5">{{ render.title }}</h2>
    <div class="d-flex">
      <div class="flex-fill"></div>
      <div class="quote-panel" style="width:777px;min-height:222px;position: relative">
        <p class="text-center" v-for="(text,i) in render.content" :key="text+i"><strong>{{text}}</strong></p>
        <ul v-if="canceledQuoteDetails" style="display: inline-block; margin-left: 300px; margin-bottom: 30px;">
          <li v-for="(reasons, index) in canceledQuoteDetails" :key="index">{{reasons}}</li>
        </ul>
        <p v-if="render.revive || render.releaseCustomer" class="text-center">
          <b-button size="md" v-if="render.revive" @click="oneShield('reviveQuote',{quoteId}).finally(fetchQuoteDetail)">
            <i class="fas fa-magic mr-1" style="color:inherit"></i>
            RE-ACTIVATE
          </b-button>
          <b-button class="ml-3"
              size="md" @click="updateCustomerBroker"
              v-if="render.releaseCustomer && unbound !== true">
            <span v-if="unbound === null">REMOVE QUOTE LOCK</span>
            <span v-if="unbound === false">REMOVING QUOTE LOCK...</span>
          </b-button>
          <span v-else-if="unbound === true" class="d-inline-block ml-3">
            Quote lock removed from customer
          </span>
        </p>

        <div style="text-align: center;" class="mt-5 mb-3">
          <router-link to="/quotes" :style="{color:lioTheme.coral}">
            <i class="fas fa-arrow-left" :style="{color:lioTheme.coral}"></i>
            GO TO QUOTE LIST</router-link><br><br>
          <router-link :style="{color:lioTheme.coral}" to="/">MAIN MENU</router-link>
        </div>

      </div>
      <div class="flex-fill"></div>
    </div>
  </div>
</template>

<script>

import {quotemix} from '@/lib/quotemix';
import {mapState} from 'vuex';
import {isType} from '@/lib/mytype';

export default {
  data: () => {
    return {
      unbound: null
    };
  },
  computed: {
    ...mapState('getQuote', ['error']),

    canceledQuoteDetails(){
      let cancelReason = this.itemVal('cancellation.osReason');
      if (cancelReason) {
        cancelReason = cancelReason.split(';');
      }
      return cancelReason;
    },

    render(){
      let typeKey = this.status;
      let uw = this.itemVal('quote', 'underwritingReason');
      let reasons = uw ? Array.isArray(uw) ? uw.map(reason => reason.description) : [uw.description] : [];
      let filterReasons = Object.fromEntries(reasons.map(r => [r, r]));
      reasons = Object.keys(filterReasons);
      let error = this.error ? this.error.userMessage : this.errorText;
      if (this.error?.knownError?.type === 'quoteLock'){
        typeKey = 'cantLock';
      }
      error = [error];
      let boundCustomer = isType.string(this.itemVal('customer.customerRegistryId')) && this.isAdmin;
      let allContent = {
        bound: {
          title: 'QUOTE ALREADY BOUND',
          content: ['This quote has been bound to a policy and cannot be modified.']
        },
        uw: {
          title: 'QUOTE IN UNDERWRITING',
          content: [
            'Quote has been referred to underwriting for review.',
            ...reasons.map((r, i) => `${i === 0 ? 'Reason: ' : ''} - ${r}`),
            'A LIO Underwriter will be in contact to discuss your quote within 24 business hours.'
          ]
        },
        rej: {
          title: 'QUOTE REJECTED',
          content: ['Quote has been rejected by underwriting.'],
          releaseCustomer: boundCustomer
        },
        dec: {
          title: 'QUOTE DECLINED',
          content: ['Your quote has been declined.',
            ...reasons.map((r, i) => `${i === 0 ? 'Reason: ' : ''} - ${r}`)
          ],
          releaseCustomer: boundCustomer
        },
        exp: {
          title: 'QUOTE EXPIRED',
          content: ['This quote has expired.'],
          revive: this.isAdmin,
          releaseCustomer: boundCustomer
        },
        canc: {
          title: 'QUOTE CANCELED',
          content: ['This quote was canceled.'],
          revive: this.isAdmin,
          releaseCustomer: boundCustomer
        },
        err: {
          title: 'ERROR RETRIEVING QUOTE',
          content: error
        },
        cantLock: {
          title: 'QUOTE NOT AVAILABLE',
          content: [`This quote is currently being accessed by another user. Please try again later.`]
        }
      };
      return allContent[typeKey];
    }
  },
  methods: {
    async updateCustomerBroker (){
      this.unbound = false;
      let params = {
        customerRegistryId: this.itemVal('customer', 'customerRegistryId'),
        quoteId: null
      };
      return new Promise(res => {
        this.lioApi('updateCustomerBroker', null, params).then(({response}) => {
          if (!response.hasErrors){
            this.unbound = true;
          }else{
            this.unbound = 'error';
            this.fetchQuoteDetail();
          }
          res(response);
        });
      });

    }
  },
  mixins: [quotemix],
  name: 'not-editable',
  props: ['status', 'quoteId', 'errorText'],
  mounted() {
    this.updateField({chain: 'quote.totalPremium', val: null});
    let {params} = (this.$route);
    if (params.step === 'bind-quote'){//bind quote hides info header. go back to details page to enable this view
      let rt = this.$route;
      rt.params.step = 'details';
      this.$router.push(rt);
    }
  }
};
</script>

<style scoped>

</style>
