import typeDefs from '@/json/types-list.json';
import {
  brokerDeductibles,
  ilMineCounties, kyMineCounties,
  perilsDeductibles,
  roofTypes, bldgClassTypes,
  stateIdByCode, wvMineCounties, hoaDeductibles, windHailDeductible, hoaBldgClassTypes
} from '@/lib/fields/field-constants';
import {isType} from '@/lib/mytype';
const listFromObj = o => Object.entries(o).map(([key, lbl]) => (isType.string(lbl) ? {key, lbl} : {key, ...lbl}));

let classVals = listFromObj(bldgClassTypes);
let hoaClassVals = listFromObj(hoaBldgClassTypes);

const incrConstCostList = typeDefs.incrConstCost.filter(item => item.key !== 300000);
const demolitionLimitList = typeDefs.demolitionLimit.filter(item => item.key !== 300000);
export const buildingData = [
  'num', 'name', 'externalId', '_isPseudo', '_delete',
  'addressId', 'locationId', 'cfLocationId',
  'identicalBuildings'
].map(key => ({key, type: 'data'}));
let thisYear = new Date().getFullYear();
let minus15 = thisYear - 15;
let yearBuiltCondition = {group: 'details', active: {$conditional: true, test: {chain: 'sibling::yearBuilt', lt: minus15}}};
export const buildingFormFields = [// construction
  {
    key: 'bClass', type: 'select', lbl: 'Building Class', tags: 'col0,allowUnlisted', group: 'construction', classList: 'w-75', sortKey: 'sortKey',
    vals: {
      $conditional: true,
      $switch: [{
        test: { chain: 'quote.programCode', eq: 'HOA' },
        result: hoaClassVals
      }],
      defaultVal: classVals
    }
  },
  { key: 'constructionType', type: 'select', lbl: 'Construction Type', defaultVal: 1, group: 'construction',
    vals: typeDefs.constructionTypes, classList: 'w-75', tags: 'impactsRateTier,col0' },
  { key: 'totalBldgSqFt', type: 'int', lbl: 'Building Sq Footage (livable)', tags: 'col0',  group: 'construction', range: [0, 999999999], classList: 'w-50'},
  { key: 'identicalCt', defaultVal: 1, type: 'int', classList: 'w-50 mr-3',  group: 'construction', lbl: 'How many identical buildings are there of this building type?', range: [1, 999], constrainRange: true, tags: 'impactsRateTier,col0'},
  {
    key: 'wsLossMitigation', lbl: 'Windstorm loss mitigation', group: 'construction', type: 'yn-radio',
    defaultVal: false,
    tags: 'col0',
    active: {
      $conditional: true,
      keepVal: true,
      $and: [
        {chain: 'sibling::wsHailExclusion', ne: true},
        {
          $or: [
            {
              $and: [
                {chain: 'scope.jurisdiction', eq: stateIdByCode.FL},
                {chain: 'sibling::bClass', includedIn: ['10456', '10556', '10356', '10156', '10256', '10056']}
              ]
            },
            {
              $and: [
                {chain: 'scope.jurisdiction', eq: stateIdByCode.SC},
                {chain: 'selectedChildField||scores.bgIITerritory.value', includedIn: ['Beach', 'Seacoast']}
              ]
            },
            {
              chain: 'scope.jurisdiction', includedIn: [stateIdByCode.NY, stateIdByCode.AL]
            }
          ]
        }
      ]
    }
  },
  { key: 'wsHailExclusion', group: 'construction', type: 'data'},
  { key: 'roofType', lbl: 'Roof Type', classList: 'w-100', group: 'construction', type: 'select', vals: listFromObj(roofTypes), tags: 'col1' },
  { key: 'hasSprink', lbl: 'Automatic Sprinkler System', defaultVal: false, classList: 'w-50', type: 'yn', group: 'construction', tags: 'col1'},
  { key: 'floorCt', type: 'int', lbl: 'Number of Stories', classList: 'w-50', range: [1, 200], group: 'construction', tags: 'impactsRateTier,col1'},
  { key: 'yearBuilt', type: 'int', group: 'construction', lbl: 'Year Built', noformat: true, range: [1750, thisYear], classList: 'w-50', tags: 'impactsRateTier,col1'},

  // coverages
  { key: 'buildingLimit', lbl: 'Building Limit', classList: 'w-75', type: 'currency', group: 'coverages', tags: 'impactsRateTier' },
  // { key: 'bizIncCvgLimit', lbl: 'Business Income Coverage Limit', classList: 'w-75', type: 'currency', group: 'coverages' },

  {
    key: 'perilsDeduct',
    lbl: 'All Perils Deductible',
    type: 'select',
    classList: 'w-75',
    group: 'coverages',
    tags: 'impactsRating,allowUnlisted',
    defaultVal: 5000,
    vals: {
      $conditional: true,
      $switch: [{
        test: {admin: true},
        result: perilsDeductibles
      },
        {
        test: {chain: 'quote.programCode', eq: 'HOA'},
        result: hoaDeductibles
      }
      ],
      defaultVal: brokerDeductibles
    },
    lockable: true
  },
  { key: 'protectionClass', lbl: 'Protection Class', classList: 'w-100', defaultVal: 1, group: 'coverages', type: 'text', readOnly: true, optional: true},

  //additional coverages
  { key: 'coinsurPct', type: 'select', lbl: 'Coinsurance Percentage', title: 'Building Coverages',
    vals: typeDefs.coinsurPct, group: 'addlCov', tags: 'impactsRating', defaultVal: 7
  },
  { key: 'agreedVal', type: 'yn', lbl: 'Agreed Value', group: 'addlCov', tags: 'impactsRating', defaultVal: true},
  {
    key: 'valuation', type: 'select', lbl: 'Valuation', group: 'addlCov', vals: typeDefs.valuation, tags: 'impactsRating',
    labelTipHtml: true, labelTipClass: 'list-tip', defaultVal: 1,
    labelTip: 'We have a new feature for valuation other than Replacement Cost:<ul><li>ERC can go straight through to bind. Upon binding, you\'ll need to upload your valuation</li><li>GRC will now be referred to Underwriting. We will review valuation. At binding, you\'ll need to upload your valuation</li></ul>'
  },
  { key: 'inflationGuard', type: 'select', lbl: 'Inflation Guard', group: 'addlCov',
    vals: typeDefs.inflationGuard, tags: 'impactsRating', defaultVal: 4},
  {
    key: 'multipleDeductible',
    lbl: 'Multiple Deductible',
    type: 'select',
    group: 'addlCov',
    vals: perilsDeductibles,
    tags: 'impactsRating',
    canReset: true,
    active: {
      optional: true,
      $conditional: true,
      test: {admin: true}
    }
  },
  {
    key: 'multipleDeductibleCause', lbl: 'Cause of Loss', type: 'select',
    group: 'addlCov', vals: typeDefs.multiCausesOfLoss, tags: 'impactsRating',
    active: {
      $conditional: true,
      test: {
        chain: 'sibling::multipleDeductible',
        nothingSelected: false
      }
    }
  },
  {
    key: 'multipleDeductibleDescribeOther',
    lbl: 'Description',
    type: 'text',
    group: 'addlCov',
    tags: 'impactsRating',
    active: {
      $conditional: true,
      $or: [
        {chain: 'sibling::multipleDeductibleCause', eq: 8},
        {chain: 'sibling::multipleDeductibleCause', eq: '8'}
      ]
    }
  },
  {
    key: 'windHailDeductible',
    lbl: 'Wind/Hail Deductible',
    type: 'select',
    classList: 'w-75',
    group: 'addlCov',
    tags: 'allowunlisted',
    active: {
      optional: true,
      $conditional: true,
      test: {admin: true}
    },
    vals: windHailDeductible,
    readOnly: true,
    optional: true
  },
  {
    key: 'windHailDeductibleMinimum',
    lbl: 'Wind/Hail Deductible Minimum',
    type: 'select',
    classList: 'w-75',
    group: 'addlCov',
    tags: 'allowunlisted',
    active: {
      optional: true,
      $conditional: true,
      test: {admin: true}
    },
    vals: windHailDeductible,
    readOnly: true,
    optional: true
  },
  {
    key: 'windHailDeductibleOverride',
    lbl: 'Wind/Hail Deductible Override',
    type: 'select',
    classList: 'w-75',
    group: 'addlCov',
    tags: 'impactsRating',
    canReset: true,
    active: {
      optional: true,
      $conditional: true,
      test: {admin: true}
    },
    vals: windHailDeductible
  },
  { key: 'ordinanceCoverage', lbl: 'Select higher Ordinance or Law coverage limits', title: 'Ordinance or Law Coverage', group: 'addlCov', type: 'bool', tags: 'impactsRating'  },
  { key: 'demolitionLimit', lbl: 'Demolition Limit (B)', type: 'select', group: 'addlCov', vals: demolitionLimitList, tags: 'impactsRating',
    active: { $conditional: true, test: {chain: 'sibling::ordinanceCoverage', eq: true}, keepVal: true}
  },
  { key: 'incrConstCost', lbl: 'Increased Cost of Construction (C)', type: 'select', group: 'addlCov', vals: incrConstCostList,
    active: { $conditional: true,  test: {chain: 'sibling::ordinanceCoverage', eq: true}, keepVal: true }
  },
  {
    key: 'sinkholeCoverage', lbl: 'Sinkhole Loss', title: 'Sinkhole Loss Coverage', group: 'addlCov', type: 'bool',
    active: {
      $conditional: true,
      test: {chain: 'scope.jurisdiction', eq: stateIdByCode.FL}
    }
  },
  {
    key: 'prevSinkholeLoss', lbl: 'Has the insured previously incurred a sinkhole loss?', group: 'addlCov', type: 'yn',
    active: { $conditional: true, test: {chain: 'sibling::sinkholeCoverage', eq: true}, keepVal: true }
  },
  { key: 'mineSubsidence', lbl: 'Mine Subsidence', type: 'bool',
    group: 'addlCov', title: 'Mine Subsidence', tags: 'minesub', //todo: investigate minesub tag
    defaultVal: false,
    active: {
      $conditional: true,
      keepVal: true,
      $or: [
        {chain: 'scope.jurisdiction', eq: stateIdByCode.IN},
        {
          $and: [
              {chain: 'scope.jurisdiction', eq: stateIdByCode.IL},
              {chain: 'selectedChildField||scores.county:all', includedIn: ilMineCounties}
            ]
        },
        {
          $and: [
            {chain: 'scope.jurisdiction', eq: stateIdByCode.KY},
            {chain: 'selectedChildField||scores.county:all', includedIn: kyMineCounties}
          ]
        },
        {
          $and: [
            {chain: 'scope.jurisdiction', eq: stateIdByCode.WV},
            {chain: 'selectedChildField||scores.county:all', includedIn: wvMineCounties}
          ]
        }
      ]
    }
  },
  {
    key: 'mineSubsidenceBldLimit', lbl: 'Building Limit', type: 'currency', group: 'addlCov', tags: 'minesub',
    active: {
      $conditional: true,
      test: {chain: 'sibling::mineSubsidence', eq: true},
      keepVal: true
    },
    _max: {
      $conditional: true,
      $switch: [{
        test: {chain: 'scope.jurisdiction', eq: stateIdByCode.IL},
        result: {
          $compute: { chain: 'selectedChildField||buildingLimit:min:750000' }
        }
      }, {
        test: {chain: 'scope.jurisdiction', eq: stateIdByCode.IN},
        result: {
          $compute: { chain: 'selectedChildField||buildingLimit:min:500000' }
        }
      }, {
        test: {chain: 'scope.jurisdiction', eq: stateIdByCode.KY},
        result: {
          $compute: { chain: 'selectedChildField||buildingLimit:min:300000' }
        }
      }, {
        test: {chain: 'scope.jurisdiction', eq: stateIdByCode.WV},
        result: {
          $compute: { chain: 'selectedChildField||buildingLimit:min:200000' }
        }
      }],
      defaultVal: 200000
    }
  },
  { key: 'mineSubsidenceLivingExp', lbl: 'Additional Living Expense (Limit $15,000)', type: 'bool', group: 'addlCov', tags: 'minesub',
    active: {
      $conditional: true,
      $and: [
        {chain: 'sibling::mineSubsidence', eq: true},
        {chain: 'scope.jurisdiction', includedIn: [stateIdByCode.IN]}
      ],
      keepVal: true}
  },
  { key: 'mineSubsidenceDeductible', lbl: 'Deductible', type: 'currency', group: 'addlCov', readOnly: true, tags: 'minesub',
    active: {
      $conditional: true,
      test: {chain: 'sibling::mineSubsidence', eq: true},
      keepVal: true, optional: true
    }
  },
  // { key: 'directDmg', type: 'bool', lbl: 'Direct Damage', group: 'utilSvc'},
  // { key: 'directDmgLimit', type: 'currency', lbl: 'Direct Damage Limit', group: 'utilSvc',
  //   min: 25001, active: { $conditional: true, test: {chain: 'sibling::directDmg', eq: true}, keepVal: true}},
  // { key: 'utilTimeEl', type: 'bool', lbl: 'Utility Service Time Element', group: 'utilSvc'},
  // { key: 'utilTimeElLimit', lbl: 'Time Element Limit', type: 'currency', min: 25001, group: 'utilSvc',
  //   active: { $conditional: true, test: {chain: 'sibling::utilTimeEl', eq: true}, keepVal: true}},
  { key: 'sewerDischarge', type: 'bool', lbl: 'Discharge from Sewer, Drain, or Pump', group: 'utilSvc',
    defaultVal: {
      $conditional: true,
      $switch: [{
        $and: [{dataVersion: 3}, {chain: 'quote.programCode', eq: 'COA'}],
        result: true
      }],
      defaultVal: null
    }
  },
  { key: 'dischargeLmtProp', type: 'currency', lbl: 'Discharge Limit - Property Damage', group: 'utilSvc',
    active: {
      $conditional: true,
      chain: 'sibling::sewerDischarge', eq: true
    },
    watch: 'defaultVal',
    defaultVal: {
      $conditional: true,
      $switch: [
        {
          $and: [
            {dataVersion: 3},
            {chain: 'quote.programCode', eq: 'COA'},
            {chain: 'sibling::buildingLimit', ne: null}
          ],
          result: {
            $compute: {chain: 'sibling::buildingLimit' }
          }
        }
      ],
      defaultVal: null
    },
    readOnly: {
      $conditional: true,
      $switch: [
        {
          $and: [
            {dataVersion: 3},
            {chain: 'quote.programCode', eq: 'COA'},
            {admin: false}
          ],
          result: true
        }
      ],
      defaultVal: false
    }
  },
  {
    key: 'dischargeLmtBizInt', type: 'currency', lbl: 'Discharge Limit - Business Interruption', group: 'utilSvc',
    active: {
      $conditional: true,
      chain: 'sibling::sewerDischarge', eq: true
    },
    watch: 'defaultVal',
    defaultVal: {
      $conditional: true,
      $switch: [
        {
          $and: [{dataVersion: 3}, {chain: 'quote.programCode', eq: 'COA'}],
          result: {
            $compute: {chain: 'sibling::buildingLimit' }
          }
        }
      ],
      defaultVal: null
    },
    readOnly: {
      $conditional: true,
      $switch: [
        {
          $and: [
            {dataVersion: 3},
            {chain: 'quote.programCode', eq: 'COA'},
            {admin: false}
          ],
          result: true
        }
      ],
      defaultVal: false
    }
  },
  {
    key: 'annAggLim', type: 'yn', lbl: 'Annual Aggregate Limit Applies', group: 'utilSvc',
    active: { $conditional: true, chain: 'sibling::sewerDischarge', eq: true, keepVal: true},
    readOnly: true,
    defaultVal: true
  },
  { key: 'elecUpdated', lbl: 'Electrical Updated (Year)', type: 'int', ...yearBuiltCondition, noformat: true, range: [1940, thisYear] },
  { key: 'alumWiring', type: 'yn', lbl: 'Aluminum Wiring', ...yearBuiltCondition},
  { key: 'plumbingUpdated', type: 'int', lbl: 'Plumbing Last Updated (Year)', ...yearBuiltCondition, noformat: true, range: [1800, thisYear]},
  { key: 'polyPiping', type: 'yn', lbl: 'Polybutylene Piping', ...yearBuiltCondition},
  { key: 'roofUpdated', type: 'int', lbl: 'Roof Last Updated', ...yearBuiltCondition, noformat: true, range: [1750, thisYear]}
].map(f => {
  f.selectionVals = [];
  f.state = 'clean';
  f.chains = [];
  f.optional = !isType.nullOrUndef(f.optional) ? f.optional : ['addlCov', 'utilSvc'].includes(f.group) && !f.active;

  if (['int', 'currency'].includes(f.type)){
    f.placeholder = '';
    f.width = '75%';
  }
  return f;
});

export const buildingFields = [
  ...buildingData,
  ...buildingFormFields
];

export const pseudoBuildingFields = [
  ...buildingData,
  ...buildingFormFields.filter(f => ['buildingLimit', 'bizPersonalProp'].includes(f.key))
];
