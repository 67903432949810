<template>
<div>
  <div class="d-flex mb-3">
    <div style="width:50%">
      <field-column :defs="defRange(c1Keys)" />
    </div>
    <div style="width:50%">
      <field-column :defs="defRange(c2Keys)" label-w="44%" field-w="56%"/>
    </div>
  </div>

</div>
</template>

<script>

import {detailsTab, quotemix} from '@/lib/quotemix';
import FieldColumn from '@/components/fields/field-column';
import { perilsDeductibles } from '@/lib/fields/field-constants';
export default {
  data: () => {
    return {
      apiSaveFunction: 'updateBaselineCoverages',
      quoteFields: ['quoteId', 'policyId', 'policyCommercialGLId', 'policyCFId', 'policyGLId'],
      tabName: 'baseline'
    };
  },
  components: {FieldColumn },
  computed: {
    stopGapVal(){
      return this.itemVal('baseline.stopGapBodilyInjuryByAccidentEachAccident');
    },
    bDefs(){
      return this.storeFields('baseline');
    },
    dirty(){
      let {apiParams, originalVals} = this;
      let dirty = false;
      Object.entries(apiParams).forEach(([k, v]) => {
        if (originalVals[k] !== v){
          dirty = true;
        }
      });
      return dirty;
    },
    defRange(){
      return keys => keys.map(k => {
        let def = this.bDefs.find(d => d.key === k);
        if (k === 'waterDamageDeductible'){
          let max = Math.max(...this.buildings.map(b => Number(b.child('perilsDeduct').val) || 0));
          def.vals = perilsDeductibles.filter(({key}) => key >= max);
        }
        return def;
      });
    },
    c1Keys(){
      return this.bDefs.filter(f => f.hasTag('col1')).map(f => f.key);
    },
    c2Keys(){
      return this.bDefs.filter(f => f.hasTag('col2')).map(f => f.key);
    }
  },
  methods: {
    alignGaps(val){

      ['AggregateLimit', 'EachEmployee'].forEach(s =>
        this.updateField({chain: `baseline.stopGapBodilyInjuryByDisease${s}`, val})
      );
    }
  },
  mixins: [quotemix, detailsTab],
  name: 'baselines',
  mounted() {
    this.alignGaps(this.stopGapVal);
  },
  watch: {
    stopGapVal(sgv){
      this.alignGaps(sgv);
    }
  }

};
</script>
