<template>
    <div class="d-flex pb-3">
      <div style="width:550px" class="mr-4">
        <field-column :defs="defs.slice(0,6)" label-w="60%" field-w="40%"/>
      </div>

      <div style="width:550px" class="pl-3">
        <field-column :defs="defs.slice(6,9)" label-w="60%" field-w="40%"  />
      </div>
    </div>
</template>

<script>
import {detailsTab, quotemix} from '@/lib/quotemix';
import {mapMutations, mapState} from 'vuex';
import eventbus from '@/lib/eventbus';
import FieldColumn from '@/components/fields/field-column';

export default {
  data: () => {
    return {
      quoteFields: ['quoteId', 'policyId', 'priorPolicyId']
    };
  },
  computed: {
    ...mapState('getQuote', ['navigationPath']),
    defs(){
      return this.storeFields('policy');
    },
    priorExpiry(){
      return this.itemVal('policy.priorExpiry');
    },
    effective(){
      return this.itemVal('policy.effective');
    },
    readOnlyExpiry(){
      return this.quoteData.policy?.expiry;
    },
    hasExisting(){
      return this.itemVal('policy.hasExisting');
    }

  },
  methods: {
    ...mapMutations('getQuote', ['setPristineState']),
    async updatePolicy(){
      if (this.dirty) {
        if (this.newQuote){
          eventbus.$emit('progressToast', 'Updating Policy Details');
        }
        // TODO: There are some no-op instances we could filter out (e.g. all null)
        let result = this.save();
        result.finally(() => {
          if (this.newQuote){
            eventbus.$emit('progressToast', false);
          }
        });
        return result;
      }
    }
  },
  name: 'policy-detail',
  components: {FieldColumn},
  mixins: [quotemix, detailsTab],
  watch: {
    effective: {
      handler(eff){
        if (!eff){
          return;
        }
        this.delayFn(() => {
          let props = {
            val: new Date(eff.getFullYear() + 1, eff.getMonth(), eff.getDate()),
            valid: true
          };
          this.updateField({chain: 'policy.expiry', props});
          //this.updateField({chain: 'policy.priorExpiry', prop: 'max', val: eff});
        }, 1);
      }
    },
    priorExpiry: {
      handler(val){
        if (val){
          let props = {min: val, valid: true, val};
          this.updateField({chain: 'policy.effective', props});
        }
      },
      deep: true
    }
  },

  mounted() {
    let lastRoute = this.navigationPath.length && this.navigationPath[this.navigationPath.length - 1];

    if (lastRoute === 'locations') {
      this.defs.forEach(({chain}) => this.updateField({chain, val: null}));
      this.updateField({chain: 'policy.hasExisting', props: {computeVal: undefined, val: null}});
    }
    let expiry = this.priorExpiry;
    let minEffective = new Date(Math.max(Date.now(), expiry?.valueOf() ?? 0));
    this.updateField({chain: 'policy.effective', props: {min: minEffective}});
    this.updateField({chain: 'policy.priorExpiry', props: {min: new Date()}});
    this.cleanSlate();
    this.setPristineState();

  },
  props: ['newQuote']
};
</script>

<style scoped lang="scss">
.input-row{
  height:52px;
}
</style>
