<template>
<div>
  <h3 class="p-2">Locations</h3>
  <ul class="mb-1" :class="{'location-single-select':!readOnly}" v-if="locations.length">
    <li v-for="(l,i) in locations" :key="`loc${i}`" class="mb-1">

        <component :is="itemTag" class="d-block p-1 pl-2 pr-3" :class="{active:i===selectedIndex,'pl-4':readOnly}" @click="select(l,$event)">
          <b-radio name="selectedLocation" v-model="curLoc" :value="l.chain" v-if="!readOnly">
            <strong>{{ l.name }}</strong>
          </b-radio>
          <strong v-else>{{ l.name }}
            <b-icon-info-circle
                v-if="itemVal('propertyCoverages.floodCoverageEnabled')"
              v-b-tooltip.top.hover.v-dark="{
              title:$parent.$parent.locationTip(l)
            }" class="ml-1"/>
          </strong>
          <span class="d-block" :class="{'ml-4':!readOnly}" style="text-transform: uppercase">{{ l.address }}</span>
        </component>

    </li>
  </ul>
  <div class="d-none">
    <field-column :defs="locations.flatMap(l=>l.children.filter(c=>c.conditionals))"/>
  </div>
</div>
</template>

<script>
import {mapMutations} from 'vuex';
import {quotemix} from '@/lib/quotemix';
import eventbus from '@/lib/eventbus';
import FieldColumn from '@/components/fields/field-column';
export default {
  components: {FieldColumn},
  data: () => {
    return {
      curLoc: 'locations.location-1',
      selectedIndex: 0
    };
  },
  props: ['readOnly'],
  computed: {
    itemTag(){
      return this.readOnly ? 'div' : 'a';
    }
  },
  methods: {
    ...mapMutations('getQuote', ['updateField']),
    select(loc, event){
      if (event && this.readOnly){
        return;
      }
      try {
        this.curLoc = loc.chain;
      }catch(ex){
        console.warn(ex);
      }
      this.selectedIndex = loc.li;
      this.locations.forEach(l => {
        l.selected = l.li === this.selectedIndex;
        this.updateField({chain: l.chain, prop: 'selected', val: l.li === loc.li});

      });
    }
  },
  mounted() {
    //console.warn({locations: this.locations});
    this.select(this.locations[0]);
    this.delayFn(() => eventbus.$emit('locationSelector', this));
  },
  mixins: [quotemix],
  name: 'location-selector'
};
</script>

<style scoped lang="scss">
.location-single-select{
  li{
    *{
      color:#777;
    }
  }
  a {
    cursor: pointer;
    &:hover {
      strong {
        text-decoration: underline
      }
    }
  }
  a.active{
    *{
      color:#111;
    }

    background-color: white;
    box-shadow: inset 0 0 1px #888;
    border-radius: 4px;
  }
}
</style>
