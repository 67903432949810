<template>
  <v-combobox
      :items="filteredItems.map(v=>{return {text:v.lbl,value:v.key}})"  multiple chips
      :menu-props="{maxHeight:600, value:showMenu}"
      @click="cancelHide(true)" @focus="cancelHide(true)" readonly
      v-model="displaySelection" autofocus :id="def.guid"  :chain="def.chain"
      v-b-tooltip.hover="def.isValid ? '' : def.validationTip" :class="{'is-invalid':!def.isValid}"
  >
    <template v-slot:item="{ item }">
      <v-list-item @mousedown="cancelHide" class="multi-item">
        <b-checkbox
          class="mb-2" :checked="checked(item.value)" :disabled="locked(item.value)" :class="{saving}"
          @change="selectItem(item.value, ...arguments)">{{item.text}}</b-checkbox>
      </v-list-item>
    </template>

  </v-combobox>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import {isType} from '@/lib/mytype';
import eventbus from '@/lib/eventbus';
import fieldmix from '@/components/fields/fieldmix';

export default {
  data: () => {
    return {
      dontHide: false,
      showMenu: true,
      items: [],
      lockedItems: {}
    };
  },
  name: 'multi-combo',
  computed: {
    ...mapState('getQuote', ['saving']),
    ...mapGetters('getQuote', ['itemVal', 'itemFromChain', 'evalCondition']),
    filteredItems(){
      if (this.def.runtimeFilter){
        let vm = this;
        let def = this.def;
        return this.def.vals.filter(({test}) =>  !test || this.evalCondition({test, def, vm}));
      }
      return this.def.vals;
    },
    checked(){
      return key => this.selection[key];
    },
    cols(){
      return this.def.cols || 2;
    },
    locked(){

      return key => this.quoteLocked || ((this.selection[key] && this.selectedCount === 1 && !this.def.allowEmpty));
    },
    displaySelection(){
      let sel = this.def.val;
      if (!isType.object(sel)){
        return [];
      }
      let selected = Object.keys(sel).filter(key => sel[key]);
      let lbls = selected.map(key => {
        return this.filteredItems.find(d => d.key == key)?.lbl;

      }).filter(v => !!v);
      if (lbls.length > 2){
        lbls = [lbls[0], lbls[1], '+' + (lbls.length - 2)];
      }
      return lbls;
    },
    selection(){
      return this.def.val;
    },
    selectedCount(){
      return Object.values(this.selection).filter(v => v).length;
    }
  },
  methods: {
    ...mapMutations('getQuote', ['updateField']),
    delayHide(){
      this.delayFn(() => {
        if (!this.dontHide) {
          this.showMenu = false;
        }
      }, 10);
    },
    cancelHide(show){
      this.dontHide = true;
      if (show){
        this.showMenu = true;
      }
      this.delayFn(() => this.dontHide = false, 200);
    },
    toggle(){
      this.showMenu = !this.showMenu;
    },
    selectItem(key, v){
      this.def.val[key] = v;
    }
  },
  mounted(){
    eventbus.$on('appMouseup', this.delayHide);

    this.filteredItems.forEach(item => {
      if (item.brokerLock && this.selection[item.key]){
        this.lockedItems[item.key] = true;//only lock if value is there when rendered
      }
    });

  },
  mixins: [fieldmix],
  props: ['def']
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";
.v-select--is-multi.is-invalid{
  border:solid 1px $danger;
  border-radius: 3.5px;
  //box-shadow:0 0 10px red;
}
.cantChange{
  pointer-events: none;
}
.multi-item{

  .saving{
    opacity:.7;
    pointer-events: none;
    *{
      opacity:.7;
      pointer-events: none;
    }
  }
  .custom-control-label{
    color: #183340 !important;
  }
}

</style>
