<template>
<b-modal id="cancelQuote" :title="`Cancel Quote ${quoteNum}`" v-model="show">
  <div class="d-flex" v-if="canceling">
    <div class="w-25"></div>
    <div class="w-50"><progress-bar>Canceling</progress-bar></div>
    <div class="w-25"></div>
  </div>
  <div v-else>
  <field-column :defs="storeFields('cancellation')" label-w="40%" field-w="60%"/>
  </div>
  <template  #modal-footer>
    <div class="text-center">
      <b-button size="lg" variant="info" @click="show=false">
        DO NOT CANCEL
      </b-button>
      <b-button size="lg" variant="primary" @click="cancel" :disabled="!itemVal('cancellation.reason')">
        CANCEL QUOTE
      </b-button>
    </div>
  </template>
</b-modal>
</template>

<script>
import ProgressBar from '@/components/util/progress-bar';
import FieldColumn from '@/components/fields/field-column';
import {quotemix} from '@/lib/quotemix';
import {mapGetters} from 'vuex';
import {isType} from '@/lib/mytype';
import {titleCased} from '@/lib/util';

export default {
  components: {FieldColumn, ProgressBar},
  data: () => {
    return {
      canceling: false,
      show: false
    };
  },
  computed: {
    ...mapGetters('getQuote', ['itemVal', 'itemFromChain']),
    cancellationEntity(){
      return this.itemFromChain('cancellation');
    },
    valueTree(){
      let nonNull = Object.entries(this.cancellationEntity.dataTree)
          .filter(([, v]) => !isType.nullOrUndef(v));
      return Object.fromEntries(nonNull);
    },
    serializedReason(){
      let list = Object.keys(this.valueTree).map(key => {
        let fld = this.cancellationEntity.child(key);
        let {lbl, val} = fld;
        return `${lbl}: ${titleCased(val)}`;
      });
      return list.join(';\n');
    }
  },
  methods: {
    cancel(){
      let {id} = this;
      this.canceling = true;
      this.propStore(this.itemVal('quote.quoteId'), this.valueTree);
      this.oneShield('cancelQuote', {
        quoteId: id,
        reason: this.serializedReason
      }).then(() => {
        this.canceling = false;
        this.show = false;
        this.reason = null;
        this.$emit('canceled');
      });
    }
  },
  mixins: [quotemix],
  name: 'cancel-quote',
  props: ['id', 'quoteNum'],

  watch: {
    show(shown){
      if (!shown){
        this.cancellationEntity.reset(true);
      }
    },
    quoteNum: {
      handler(num) {
        this.show = !!num;
      },
      deep: true
    }
  }
};
</script>

<style scoped>

</style>
