<template>

  <div class="quote-details vh-100 mh-100 flex-shrink-1">
    <b-button-toolbar>

        <b-button v-for="(text,key) in renderedTabs" :key="key"
            @click="switchTab(key)"
            :class="{
                active:tab=== key,
                disabled: saving,
                'd-none':(gl && ['propertyCoverages', 'buildings','blankets'].includes(key))
              }" class="nav-button" variant="light">
          {{ text }}
          <b-badge
              v-b-tooltip.top.hover="`${missingOrInvalid(key)} ${missingOrInvalid(key)===1?'field is': 'fields are'} incomplete or invalid.`"
              v-if="missingOrInvalid(key) > 0"
              :variant="tabRefs[key] && tabRefs[key].dirty ? 'warning' : missingOrInvalid(key) > 0 ? tab===key ? 'danger' : 'dark' : 'light'"
              pill>{{ missingOrInvalid(key) }}
          </b-badge>
        </b-button>

    </b-button-toolbar>

    <div class="d-flex justify-content-center">
      <select-location
          :resetFlag="true"
          :selectionMode="['buildings'].includes(tab)"
          :editProperty="true" :new-quote="false"
          :read-only="!['buildings','exposures'].includes(tab)"
      />
      <div class="quote-panel pb-4 ml-3 mt-1 mr-2 flex-fill" style="height:auto;min-height:350px;">
        <component :is="$route.params.tab || 'policy'" @setComponentRef="setRef"/>
      </div>
    </div>
    <div class="d-none" v-if="saveInitialPropCovs">
      <property-coverages @setComponentRef="savePropCovs"/>
    </div>
    <div class="text-center mt-5">

      <b-button
          size="lg" class="quote-nav-button mr-4" variant="secondary"
          @click="saveTab(tab)" :disabled="saving || !dirty[tab]">
        <div class="tipwrap" v-if="dirty[tab]" v-b-tooltip.top="'Save changes made to this tab.'"></div>
        Save
      </b-button>
        <b-button
            size="lg" class="quote-nav-button mr-4" variant="secondary"
            @click="rate(true)" :disabled="saving || !isReadyToConfirm">
            <div class="tipwrap" v-if="anyMissingOrRequired" v-b-tooltip.top="'Complete all required fields and save any changes before rating.'"></div>
          <span v-if="hasRated">Re-rate</span><span v-else>Preview rate</span>
        </b-button>

        <b-button class="quote-nav-button" size="lg" variant="primary" :disabled="cantBind" @click="bindQuote(`/quote/${quoteId}/bind-quote`, dirty[tab])">
          <div class="tipwrap" v-if="anyMissingOrRequired" v-b-tooltip.top="'Complete all required fields and save any changes before proceeding.'"></div>
          Review and finalize
        </b-button>
      <div class="exit-link">
        <a @click="$parent.exit">Exit to the main menu</a>
      </div>

    </div>

    <eligibility v-if="ensureNoRedirect && isType.string(itemVal('quote.status')) && itemVal('quote.answeredEligibility') !== true"/>

  </div>

</template>

<script>
import {quotemix} from '@/lib/quotemix';
import Policy from '@/components/quote-steps/tabs/policy-detail';
import exposures from '@/components/quote-steps/tabs/exposures';
import Baseline from '@/components/quote-steps/tabs/baselines';
import Underwriting from '@/components/quote-steps/tabs/underwriting';
import Reinsurance from '@/components/quote-steps/tabs/reinsurance';

import ValignMid from '@/components/util/valign-mid';
import SelectLocation from '@/components/quote-steps/shared/select-location';
import {mapGetters, mapState} from 'vuex';

import Buildings from '@/components/quote-steps/tabs/building-accordian';
import Blankets from '@/components/quote-steps/tabs/blankets';
import {progressModal, simpleModal} from '@/lib/modal-service';
import ProgressBar from '@/components/util/progress-bar';
import CovSummary from '@/components/quote-steps/shared/cov-summary';
import Eligibility from '@/components/modal/eligibility';

import PropertyCoverages from '@/components/quote-steps/tabs/property-coverages';
import eventbus from '@/lib/eventbus';
import {isType} from '@/lib/mytype';


export default {
  components: {
    Reinsurance,
    PropertyCoverages,
    Eligibility,
    CovSummary,
    ProgressBar, Blankets, Buildings, SelectLocation, ValignMid, Underwriting, Baseline, Policy, exposures
  },
  data: () => {
    const orderedTabs = [
      {key: 'policy', lbl: 'Policy'},
      {key: 'propertyCoverages', lbl: 'Property Coverages'},
      {key: 'buildings', lbl: 'Building Coverages'},
      // missing Outdoor Property
      {key: 'blankets', lbl: 'Blanket Coverages'},
      {key: 'baseline', lbl: 'Liability'},
      {key: 'exposures', lbl: 'Exposures'},
      {key: 'underwriting', lbl: 'Underwriting'},
      {key: 'reinsurance', lbl: 'Reinsurance'}
    ];
    let tabs = {};
    for(let elem of orderedTabs){
      tabs[elem.key] = elem.lbl;
    }
    let tabRefs = Object.fromEntries(Object.keys(tabs).map(tab => ([tab, null])));
    return {
      fieldsLoaded: false,
      tabRefs,
      policy: {},
      tabs,
      prevTab: 'Policy',
      quote: '00',
      fields: null,
      ensureNoRedirect: false,
      addedOutdoorProp: false
    };
  },
  computed: {
    ...mapState('getQuote', ['isRating', 'saving']),
    ...mapGetters('getQuote', ['invalidCount', 'locations', 'buildings',
      'quoteBuildings', 'whenCondition', 'hasChanges', 'selectedGroupedObj']),
    lockedForReinsurance(){
      return this.itemVal('quote.lockedForReinsurance') === true;
    },
    computedTabs(){
      let entries = Object .entries(this.tabs);
      return this.lockedForReinsurance && this.isAdmin ? this.tabs : Object.fromEntries(entries.filter(([key]) => key !== 'reinsurance'));
    },
    renderedTabs(){
      if (this.dataVersion < 2){
        let entries = Object.entries(this.computedTabs);
        return Object.fromEntries(entries.filter(([key]) => key !== 'propertyCoverages'));
      }
      return this.computedTabs;
    },
    filteredTabs(){
      let entries = Object.entries(this.computedTabs);
      return Object.keys(entries.filter(t => !this.gl || !['propertyCoverages', 'buildings', 'blankets'].includes(t)));
    },
    cantBind(){
      let {saving, isReadyToConfirm, qStatus} = this;
      let inUnderwriting = qStatus === 'Referred To Underwriting';
      return inUnderwriting || saving || !isReadyToConfirm;
    },
    hasRated(){
      let prem = this.itemVal('quote.totalPremium');
      return this.isType.snum(prem) && Number(prem) > 0;
    },
    anyMissingOrRequired(){
      return Object.keys(this.filteredTabs).filter(tab => this.missingOrInvalid(tab)).length > 0;
    },
    theMissing(){
      return Object.keys(this.filteredTabs).filter(tab => this.missingOrInvalid(tab));
    },
    tab() {
      return this.$route.params.tab || 'policy';
    },
    quoteId() {
      return this.itemVal('quote', 'quoteId') ?? '';
    },
    isGl() {
      return this.itemVal('quote', 'isGl') ?? false;
    },
    qStatus() {
      return this.itemVal('quote', 'status') ?? '';
    },
    dirty() {
      let entries = Object.entries(this.tabRefs).filter(([, vm]) => vm);
      return Object.fromEntries(entries.map(([tab, vm]) => [tab, vm.dirty]));
    },
    isReadyToConfirm() {
      const glTabs = ['policy', 'exposures', 'baseline', 'underwriting'];
      const packageAdditionalTabs = ['propertyCoverages', 'buildings', 'blankets'];
      let requiredTabs = this.lockedForReinsurance && !this.isAdmin ? [...glTabs, 'reinsurance'] : glTabs;
      if (!this.isGl) {
        requiredTabs = requiredTabs.concat(packageAdditionalTabs);
      }
      const isMissingFields = requiredTabs.some(this.missingOrInvalid);
      return !isMissingFields;
    },
    ratingTierDirty(){
      const dirty = this.addedOutdoorProp || (this.isReadyToConfirm && [
        ...this.hasChanges({tags: 'impactsRateTier', requireTouch: true}),
        ...this.locations.filter(l => isType.nullOrUndef(l?.dataTree?.rateTier))
      ].length > 0);
      return dirty;
    },
    missingOrInvalid() {
      return tab => this.rawMissing(tab).length;
    },
    rawMissing() {
      const missingFieldCt = (parent, group) => {
        if (parent.isPseudo || !parent.children) {

          return [];
        }
        return parent.children.filter(c => !group || c.group === group || c.hasTag(group)).map(cf => {

          if (cf.conditionalProps) {
            this.fieldState(cf, true);
          }
          return cf;
        }).filter(cf => cf.isRequired && !cf.isValid);
      };

      return tab => {
        if (tab === 'buildings') {
          let incompleteLocations = this.locations.filter(l => !l.isOPO && l.buildingList.length < 1);

          let bf = this.quoteBuildings.flatMap(b => missingFieldCt(b));
          return [...incompleteLocations, ...bf];
        }
        if (tab === 'exposures') {
          return this.locations.flatMap(location => missingFieldCt(location, 'exposures'));
        }

        let raw = this.requiredInPage(tab, this).filter(f => {
          this.fieldState(f, true);
          return !f.isValid;
        });
        if (tab === 'propertyCoverages' && this.wsLossMitigationState){
          this.fieldState(this.itemFromChain('windstorm.windstormCoveredState'), true);
          let ws = this.requiredInPage('windstorm', this).filter(f => {
            this.fieldState(f, true);
            return !f.isValid;
          });
          return [...raw, ...ws];
        }
        return raw;
      };
    },
    saveInitialPropCovs(){
      return !this.isGl && this.itemVal('quote.programCode') === 'COA' &&
          this.itemVal('propertyCoverages.iceDammingOSVal') === null;
    }
  },
  methods: {
    async switchTab(tab){
      if (this.saving){
        return;
      }
      let route = {
        params: {
          ...this.$route.params,
          tab
        }
      };
      if (this.dirty[this.tab]){
        this.saveTab().then((result) => {
          if (result?.response?.hasErrors){
            return console.warn(`prevent tab navigation to ${this.tab} do to errors`);
          }
          this.$router.push(route);
        });
      }else{
        this.$router.push(route);
      }
    },
    mainMenu() {
      if (this.dirty[this.tab]) {
        simpleModal('SAVE BEFORE EXIT?',
          'You have unsaved changes. Save before exit?',
          {ok: 'SAVE', cancel: `DON'T SAVE`})
          .then(ok => {
            if (ok) {
              this.saveTab();
            }
            this.$parent.exit();
          });
      } else {
        this.$parent.exit();
      }
    },
    async saveTab(tab, { includeOtherFlag } = {}){
      if (!tab){
        tab = this.tab;
      }
      let updateRateTier = false;
      if (this.ratingTierDirty){
        updateRateTier = true;
      }
      await this.tabRefs[tab].save();
      if (updateRateTier) {
        await this.updateRateTier({ includeOtherFlag: true });
      }
      return;
    },
    async bindQuote(path, isDirty){
      if (isDirty){
        progressModal('Saving Changes');
        await this.saveTab();
      }
      this.confirm().then((result) => {
        progressModal(false);
        if (result && !result['error']) {

          this.$router.push({path});
        }
      });
    },
    async rate(closeModalOnComplete) {
      if (this.itemVal('propertyCoverages.floodCoverageEnabled')){
        let storyCtVals = this.flatFieldList.filter(f => f.key === 'floorCt').map(f => Number(f.val));
        this.propStore(this.itemVal('quote.quoteId'), {floodNumberOfStories: Math.max(...storyCtVals)});
      }
      if (this.dirty[this.tab]) {
        await this.saveTab(undefined, { includeOtherFlag: true });
      } else if(this.ratingTierDirty){
        await this.updateRateTier({ includeOtherFlag: true });
      }
        return new Promise(res => {
          this.rateQuote();
          let waiting = true;
          eventbus.$on('ratingFinish', ({status, error}) => {
            if (!waiting){
              return;//prevent multiple rating listeners
            }
            waiting = false;
            console.log({rateQuote: {status, error}});
            let resolveRatingStatus = () => {
              if (closeModalOnComplete === true){
                progressModal(false);
              }
              res({status, error});
            };
            resolveRatingStatus();
          });
        });
    },
    async confirm() {
      const { buildingDetailsUpdated } = this.quoteData.underwriting;

      if (this.isGl) {
        // Allow rate to save and commit
        return this.rate();
      }

      if (this.dirty[this.tab]) {
        await this.saveTab();
      }
      let status = this.quoteData.quote.status;
      if (!buildingDetailsUpdated) {
        const {quoteId, policyId, underwritingId} = this.quoteData.quote;
        progressModal('Finalizing Quote Details');
        const {response} = await this.oneShield('updateUnderwritingDetails', {
          quoteId, policyId, underwritingId, buildingDetailsUpdated: true
        });
        status = response.fields.find(f => f.key === 'status')?.val;
        this.updateField({chain: 'quote.status', val: status});
      }
      const allowedIncompleteIds = ['16224856', '16250156', '16250256', '16259156'];
      const incompleteFields = this.quoteData.remIncomplete ?? {};
      const hasOnlyAllowedIncomplete = Object.keys(incompleteFields).every(id => allowedIncompleteIds.includes(id));
      if (status === 'MarkedForIssue and Complete' || status === 'Completed' || (this.isReadyToConfirm && hasOnlyAllowedIncomplete)) {
        return await this.rate();
      }
      simpleModal('Unable to Rate Quote', `Quote status ${status}`, {noCancel: true});


      return false;
    },
    setRef({vm}) {
      this.tabRefs[this.tab] = vm;
    },
    savePropCovs({vm}){
      vm.save();
    }
  },
  mixins: [quotemix],
  name: 'quote-details',
  mounted() {
    if (this.qStatus === 'Rated') {
      this.setPolicyNeedsUnderwritingFlag(true);
    }
    this.delayFn(() => this.ensureNoRedirect = true);
    eventbus.$on('savedOutdoorProperty', () => {
      if (this.cantBind){
        this.addedOutdoorProp = true;
      }else{
        this.updateRateTier({ includeOtherFlag: true });
      }
    });
    this.storeFields('quote').forEach(f => this.fieldState(f));
  },
  watch: {
    qStatus(status) {
      if (status === 'Rated') {
        this.setPolicyNeedsUnderwritingFlag(true);
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/get-quote";
@import "../../assets/scss/variables";
@import "../../assets/scss/modals";
@import "../../assets/scss/main";


.quote-details {

  .btn-toolbar {
    justify-content: center;
    margin: 0 0 10px 0;

    .btn {
      border-radius: 0;
      &:last-child  {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:first-child {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }
    }
  }

  .quote-panel {
    height: auto;
    min-height: 300px;
  }

  .btn {
    border: solid 1px $gray;
    margin-right: -1px;
    background-color: rgb(244, 244, 244);
  }

  .badge {
    box-shadow: 0 .5px 1px #999;
  }

  .active.btn {
    font-weight: 900;
    box-shadow: none !important;
    background-color: white !important;
  }

  ul.nav {
    border-bottom: solid 1px $gray;

    .nav-link {
      padding: 0;
      margin: 3px 24px 10px 24px;
      border-bottom: solid 2px transparent;

      &.active {
        font-weight: 900;
        border-bottom-color: $navy;
      }
    }
  }
}

</style>
