<template>
  <div class="mb-1 ml-1" style="height:inherit">
  <div class="locations mh-100" style="height:100%">
    <perfect-scrollbar :style="{height:h? px(h) : 'inherit','overflow-x':'hidden'}"  :options="{suppressScrollX:true}">
      <div v-if="locs.length">
        <a style="float:right" @click="rescoreLocations=true" v-if="isAdmin" class="mt-2 mr-1 pr-1">Hazards</a>
        <rescore-locations  @scoresClosed="rescoreLocations = false" :show-modal="rescoreLocations" v-if="isAdmin"/>
        <building-selector
            :selection-mode="selectionMode" :new-quote="newQuote"
            :building-mode="buildingMode" :resetSelection="resetOnce"
            v-if="selectionMode" :edit-outdoor-prop="!!editProperty"/>
        <location-selector v-else :locs="locs" :read-only="readOnly"/>
      </div>

    </perfect-scrollbar>
  </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

import LocationSelector from '@/components/quote-steps/shared/location-selector';
import BuildingSelector from '@/components/quote-steps/shared/building-selector';
import {quotemix} from '@/lib/quotemix';
import RescoreLocations from '@/components/modal/rescore-locations';

export default {
  data: () => {
    return {
      resetOnce: null,
      rescoreLocations: false
    };
  },
  components: {RescoreLocations, BuildingSelector, LocationSelector},
  mixins: [quotemix],
  computed: {
    ...mapState('getQuote', {
      locs: state => state.quoteFields.locations?.children
    }),
    locationTip() {
      return l => {

        let grade = l.dataTree.scores?.communityCRSGrade.value ?? 'Unknown';
        try {
          let fo = this.itemVal('quote.floodOverrides');

          if (Array.isArray(fo)) {
            let override = fo.find(({locationId}) => locationId === l.child('locationId').val);
            grade = override?.crsGrade.value ?? grade;

          }
          return `CRS Grade: ${grade}`;
        } catch (ex) {
          console.warn(ex, grade);
          return `CRS Grade: ${grade ?? 'Unknown'}`;
        }
      };
    }
  },
  props: ['selectionMode', 'buildingMode', 'h', 'readOnly', 'editProperty', 'newQuote', 'resetFlag'],
  name: 'select-location',
  methods: {
    scoresClosed(){

    }
  },
  mounted() {
    this.resetOnce = this.resetFlag;
    console.log({selectionMode: this.selectionMode});
  },
  watch: {
    selectionMode(flag){
      //console.log({selectionMode: this.selectionMode});
      if (flag){
        // this prop will be set on building-selection just long enough to hit the
        // mount cycle when it loads the first time
        this.delayFn(() => this.resetOnce = null, 1);
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../../assets/scss/variables";

.locations {
  position:relative;
  border:solid 1px #ccc;
  padding:4px 4px 8px 4px;
  margin:4px 0 0 3px;
  width:350px;
  height: inherit;


  background-color: $gray-light;
  .ps{
    padding-right:6px;
    .ps__rail-y{
      right:-4px !important;
    }
  }
  .tree{
    width:100%;
    .loc-div{
      position:relative;
      padding-left:22px;
      a.collapser{
        position:absolute;
        left:2.5px;
        top:6px;
        font-size: 20px;
        z-index: 2;
      }
    }
    .tree-node{
      .custom-control-label{
        display:block;
      }
      .badge-gray{
        background-color: #999;
        color:#fff;
      }
      padding:4px 7px;
      border:solid 1px transparent;
      border-radius: 4px;
      margin:3px 0 3px 0;
      .location-lbl{
        margin-bottom:-25px;
        display: block;
        margin-top:15px;
        position:relative;
        top:-16px
      }
      &.selected{
        background-color: white;
        border-color: $gray;
        .badge.badge-gray {
          background-color: #111;
        }
      }
      &.partial{
        border-color:$gray;
      }
      i.type-icon{
        width:22px;
        margin-right:-10px;
        text-align: center;
        padding:0;
        margin-top:5px
      }
      strong.ident-ct{
        text-align: center;
        width:20px;
        background-color: $light;
        border:solid 1px $gray;
        border-radius: 30%;
      }
    }
  }

  h3{
    color:$navy !important;
  }

}
</style>
