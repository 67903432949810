<template>
  <div class="quote-scope">
    <div class="d-flex">
      <div class="flex-fill"></div>
      <div class="quote-panel p-4 pr-5 pl-5" style="width:550px">

        <field-column :defs="storeFields('scope')" :hide-labels="true" />
      </div>
      <div class="flex-fill"></div>
    </div>
    <div class="text-center mt-5" style="position: relative">
      <b-button size="lg" class="quote-nav-button" :disabled="!canAdvance" variant="primary" @click="$parent.inMemComponent = 'eligibility'">Continue</b-button>
      <div class="exit-link">
        <a @click="$parent.exit">Exit to the main menu</a>
      </div>
    </div>
  </div>
</template>

<script>
import {quotemix} from '@/lib/quotemix';
import {OSProductCodes, stateMap} from '@/lib/fields/field-constants';
import {mapMutations, mapState} from 'vuex';
import eventbus from '@/lib/eventbus';
import {portalEvents} from '@/lib/mix';
import {decodeXml, sortByKey} from '@/lib/util';
import FieldColumn from '@/components/fields/field-column';

export default {
  components: {FieldColumn},
  data: () => {
    return {
      stateMap,
      glCode: '1656',
      loading: false,
      defs: [],
      retry: 0
    };
  },
  computed: {
    ...mapState(['oktaUser']),
    jurisdictionId(){
      return this.itemVal('scope.jurisdiction');
    },
    productId(){
      return this.itemVal('scope.product');
    },
    agencyId(){
      const agency = this.itemVal('scope.agency');
      const [agencyId] = agency?.split('|') ?? [];
      return agencyId;
    },
    programId(){
      return this.itemVal('scope.program');
    },
    agencyCode(){
      const agency = this.itemVal('scope.agency');
      const [, agencyCode] = agency?.split('|') ?? [];
      return agencyCode;
    },
    agentId(){
      return this.itemVal('scope.agent');
    },
    hasUser(){
      return !!this.oktaUser;
    },
    canAdvance(){
      return ['jurisdiction', 'product', 'program'].every(
        key => !this.isType.nullOrUndef(this.itemVal(`scope.${key}`))
      );
    }
  },
  created(){

    eventbus.$on('enterKey', () => {
      if (this.isMounted) {
        console.log('enterkey.scope', this);
        this.$parent.inMemComponent = 'eligibility';
      }
    });
  },
  mounted(){
    //this.resetSteps(true);
    this.clickStreamEvent(portalEvents.QuoteScope);

    if (this.hasUser) {
      this.loadFirstField();

    }
  },
  methods: {
    ...mapMutations('getQuote', ['resetSteps']),

    addVals({response}, sortBy) {
      let {hasErrors, fields} = response;
      if (hasErrors || !fields) {
        console.warn({response});
        return;
      }
      let {vals, chain} = fields[0];
      if (!vals?.length){
        vals = [{key: null, lbl: 'No options available'}];
      }
      if (sortBy){
        vals = sortByKey(vals, sortBy);
      }
      this.updateField({chain, props: {vals: null}});
      this.updateField({chain, props: {vals}});
    },
    loadAgencyList(){
      this.oneShield('agencyList').then(({response}) => {
        if (!response.hasErrors) {
          let agencies = response.fields[0]?.val;
          if (!Array.isArray(agencies)) {
            agencies = [agencies];
          }
          let agencyList = [];
          if(!agencies.length){
            agencyList = [{key: null, lbl: 'No options available'}];
          } else {
            agencyList = agencies.map(a => ({
              partnerId: a.agencyCode,
              key: `${a.agencyId}|${a.agencyCode}`,
              lbl: decodeXml(a.agencyName)
            }));
          }
          this.updateField({chain: 'scope.agency', props: {vals: agencyList}});
        }else if(this.retry < 3){
          this.retry++;
          console.log('retrying ' + this.retry);
          this.delayFn(this.loadAgencyList, 1100); // defeat the redundant eventbus timer
        }
      });
    },
    loadFirstField(){
      if (this.isAdmin) {
        console.log('loading agencies');
        this.loadAgencyList();

      } else {
        this.oneShield('jurisdictionList').then((result) => this.addVals(result, 'lbl'));
      }
    },
    resetLists(keys){
      keys.forEach(key => {
        let props = {val: null, vals: []};
        this.updateField({chain: `scope.${key}`, props});
      });
    }
  },
  mixins: [quotemix],
  name: 'quote-scope',
  watch: {
    sessionId(sessionId, prev){
      if (!prev && sessionId){
        console.log({loadFirstFieldNewSession: [sessionId, prev]});
        this.loadFirstField();
      }
    },
    hasUser(exists) {
      if (exists) {
        this.loadFirstField();
      }
    },
    agencyId(id) {
      if (!id || id === 'na' || !this.isAdmin) {
        return ;
      }
      this.resetLists(['agent', 'jurisdiction', 'product', 'program']);
      this.oneShield('agentList', { agencyId: id }).then(({response}) => {
        let users = response.fields[0]?.val;
        if (!Array.isArray(users)){
          users = [users];
        }
        let userList = [];
        if(!users.length){
          userList = [{key: null, lbl: 'No options available'}];
        } else {
          userList = users.map(u => ({
          key: u.id,
          lbl: [u.firstName, u.lastName, '-', u.username].join(' ').trim(),
          username: u.username
        }));
        }
        this.updateField({chain: 'scope.agent', props: {vals: userList}});
      });
    },
    agentId(id) {
      if (!id) {
        return;
      }
      this.resetLists(['jurisdiction', 'product', 'program']);
      const agencyCode = this.agencyCode;
      const agentUsername = this.itemFromChain('scope.agent')?.vals.find(a => a.key === id)?.username;
      this.oneShield('jurisdictionList', {agentUsername, agencyCode}).then((result) => this.addVals(result, 'lbl'));
    },
    jurisdictionId: {
      handler(jurisdiction, old) {
        if (old === jurisdiction){
          return;
        }
        if (jurisdiction) {
          this.resetLists(['product', 'program']);
          this.oneShield('productList', {jurisdiction}).then(this.addVals);
        }
      },
      deep: true
    },
    productId(product) {
      if (!product){
        return;
      }
      this.resetLists(['program']);
      this.oneShield('packageLinesList', {jurisdiction: this.jurisdictionId, product})
        .then(vals => {
          let gl = vals.response.fields[0].vals.find(item => item.lbl.toLowerCase().includes('general'));
          if (gl){
            this.glCode = gl.key;
          }
          if (product == OSProductCodes.COA) {
            vals.response.fields[0].vals = vals.response.fields[0].vals.filter(i => i.lbl.toLowerCase().includes('general'));
          }
          this.addVals(vals);
        });
    }
  }
};//upload, native, new (quote types)
</script>

<style lang="scss">
@import "../../assets/scss/get-quote";
.quote-scope {
  .b-dropdown .btn {
    text-align: left;
  }
  .dropdown-menu{
    margin-top:-2px;
    width:100%;
  }
  .input-row{
    height:77px;
  }
}
</style>
