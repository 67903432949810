import { render, staticRenderFns } from "./exposures.vue?vue&type=template&id=137f6a15&scoped=true&"
import script from "./exposures.vue?vue&type=script&lang=js&"
export * from "./exposures.vue?vue&type=script&lang=js&"
import style0 from "./exposures.vue?vue&type=style&index=0&id=137f6a15&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "137f6a15",
  null
  
)

export default component.exports